export default [
   {
      path: '/smart_marketing',
      name:'smartMarketing',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/smtech/smartMarketing/list')
      },
   },
   {
      path: '/a_key_marketing',
      name:'aKeyMarketing',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/smtech/smartMarketing/aKeyMarketing')
      },
   },
   {
      path: '/material_center',
      name:'materialCenter',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/smtech/materialCenter/list')
      },
   },
   {
      path: '/timing_messages',
      name:'timingMessages',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/smtech/marketingTask/timingMessages')
      },
   },
   {
      path: '/system_task',
      name:'systemTask',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/smtech/marketingTask/systemTask')
      },
   },
]
