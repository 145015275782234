export default [
    {
        path: '/channel_manage/channel_list',
        name: 'channelList',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/business/channelManage/channelList')
        },
    }, {
        path: '/channel_manage/channel_add',
        name: 'channelAdd',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/business/channelManage/channelAdd')
        },
    },{
        path: '/channel_manage/channel_ach',
        name: 'channelAch',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/business/channelManage/channelAch')
        },
    },
]