export default [
   {
      path: '/atrial_state',
      name: 'atrial_state',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/PMS/atrialState/index')
      },
      redirect:"/atrial_state/calendar",
      children:[{
         path: '/atrial_state/calendar',
         name: 'atrial_state/calendar',
         meta: { token: true },
         components: {
            atrialState: () => import('@/views/PMS/atrialState/calendar')
         }
      },{
         path: '/atrial_state/dayCalendar',
         name: 'atrial_state/dayCalendar',
         meta: { token: true },
         components: {
            atrialState: () => import('@/views/PMS/atrialState/dayCalendar')
         }
      },]
   },

]