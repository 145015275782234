import store from '@/store/index'
import { system } from '@/api/interface/system'
import axios from "@/api/request"

/*
 * 获取数据字典
 * dictVal *隐藏值 支持array和string类型
 * isSimple 简单模式
 * filterItem 需要过滤的字典内容
 * 格式为{字典编码}@{任意字符(当然取名能与当前页面业务相符是最好的)}
 * {'order-source@reserve':["SSM","APP"]}这个代表需要过滤掉order-source这个字典中的"SSM","APP"这两个字典值
*/
export function getDict(dictVal, isSimple = true, filterItem) {
  if (typeof dictVal !== 'string' && dictVal.constructor !== Array) {
    throw new Error(`参数类型错误：dictVal->${dictVal}`)
  }
  if (typeof isSimple !== 'boolean') {
    throw new Error(`参数类型错误：isSimple->${isSimple}`)
  }
  // 从缓存中获取字典列表
  let dictValArr = []
  let dictData = JSON.parse(sessionStorage.getItem('dictData')) || {}
  // 隐藏值为字符串类型
  // typeof dictVal === 'string' && dictVal && (dictVal in dictData) || dictValArr.push(dictVal)
  if (typeof dictVal === 'string' && dictVal && !(dictVal in dictData)) dictValArr.push(dictVal)
  // 隐藏值为数组类型
  if (dictVal.constructor === Array && dictVal.length !== 0) {
    // 如果缓存中已存在该字典 则从数组中移除（移除后该字典不在参与接口的请求）
    dictValArr = dictVal
    for (let i = dictValArr.length - 1; i >= 0; i --) {
      if (isSimple) dictValArr[i] in dictData && dictValArr.splice(i, 1)
      else (dictValArr[i] + '-list') in dictData && dictValArr.splice(i, 1)
    }
  }
  // 发起请求并保存在缓存中
  return new Promise( ( resolve, reject ) => {
    if (dictValArr.length > 0) {
      const url = system.subDict
      const param = { numbers: dictValArr, isSimple }
      axios.post(url, param, 'json', { voidUdata: true }).then(res => {
          if (res.success){
            let data = res.records
            if (Object.keys(data).length === 0) resolve(true)
            for (let key in data) {
              // 如果有字典值需要过滤，哪这个if代码逻辑是专门处理这种过滤情况的
              for(let item in filterItem){
                if(key === item.split('@')[0]){
                  if(isSimple){
                    dictData[item] = Object.keys(data[key]).reduce(function(r, e) {
                      if (!filterItem[item].includes(e)){
                        r[e] = data[key][e];
                      }
                      return r;
                    }, {});
                  } else {
                    dictData[item + '-list'] = data[key].map(value => {
                      if(!filterItem[item].find(v => v === value.dictKey)){
                        return { label: value.dictVal, value: value.dictKey }
                      }
                      // 去掉null的元素
                    }).filter(v => v);
                  }
                }
              }
              if (isSimple) {
                dictData[key] = data[key];
              }else {
                dictData[key + '-list'] = data[key].map(item => {
                  return { label: item.dictVal, value: item.dictKey }
                })
              }
            }
            store.dispatch('setDictData', dictData)
            sessionStorage.setItem('dictData', JSON.stringify(dictData))
          }
          resolve(true)
        })
    } else {
      resolve(true)
      store.dispatch('setDictData', dictData)
    }
  })
}

/*
 * 导出文件
 * @data 后台返回的数据流
 * @fileName 导出的文件名
*/
export function exportFile(data, fileName) {
  const link = document.createElement('a')
  let blob = new Blob([data], {type: 'application/vnd.ms-excel'})
  link.style.display = 'none'
  link.href = URL.createObjectURL(blob)
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/*
 * 上传图片格式校验
 * @file *文件流
 * @megabyte 限制大小 单位兆
*/
export function beforeUpload(file, megabyte = 5) {
  const isFormat = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif'
  const isMegabyte = file.size / 1024 / 1024 < megabyte;
  let error
  if (!isFormat) error = '上传图片只能是JPG/PNG/GIF格式!'
  if (!isMegabyte) error = `上传图片大小不能超过 ${megabyte}MB!`
  return error
}

/*
 * 生成随机数
 * length *指定长度
*/
export function randomNum(length) {
  let ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  ALPHABET += 'abcdefghijklmnopqrstuvwxyz';
  ALPHABET += '0123456789-_';
  let str = '';
  for (let i = 0; i < length; ++i) {
    let rand = Math.floor(Math.random() * ALPHABET.length);
    str += ALPHABET.substring(rand, rand + 1);
  }
  return str;
}

/*
* 查询字符串中子串出现的下标
* str 字符串
* substr 子串
* */
export function search_substr_pos(str, substr) {
  let _search_pos = str.indexOf(substr), _arr_positions = [];
  while (_search_pos > -1) {
    _arr_positions.push(_search_pos);
    _search_pos = str.indexOf(substr, _search_pos + 1);
  }
  return _arr_positions;
}

/*
* 查找数组返回(重复值/重复相应下标组)
* arr 查询的数组对象
* type: value重复值/重复相应下标组
* return 数组
* */
export function getDuplicate(arr,type = 'index'){
  let duplicate_val = [] , duplicate_index = []
  arr.forEach((item,index)=>{
    arr.forEach((items,indexs)=>{
      if(index !== indexs){
        if(item === items){
          if(!duplicate_val.includes(item)) duplicate_val.push(item)
        }
      }
    })
  })
  duplicate_val.forEach(item=>{
    duplicate_index.push(search_substr_pos(arr,item).join(','))
  })
  if(type === 'index') return duplicate_index  // 获取重复值相应下标
  else if(type === 'value') return duplicate_val
}

/*
 * 判断数组中是否存在重复数据
 * true   存在重复数据
 * false  不存在重复数据（该js数组中只要存在一条重复数据  就会返回true）
 */
export function isRepeat(arr) {
  if(!arr || arr.length < 1){
    return false;
  }
  let hash = {};
  for (let i in arr) {
    if (hash[arr[i]]) {
      return true;
    }
    hash[arr[i]] = true;
  }
  return false;
}

/*
* 查询数组日期中是否有连续日
* daysArr ["2022-02-26","2022-02-27","2022-03-01", "2022-03-04","2022-03-05""]
* return ["2022-02-26,2022-02-27","2022-03-04,2022-03-04"]
* */
export function getDaySequence(daysArr){
  daysArr = Array.from(new Set(daysArr)) // 去重
  let _days = daysArr.sort().map((d, i) => {
    let dt = new Date(d)
    dt.setDate(dt.getDate() + 4 - i) // 处理为相同日期
    return +dt
  })
  let returnDays = []
  let getDaySequenceData = getDuplicate(_days)
  if(getDaySequenceData.length != 0){
    getDaySequenceData.forEach(item=>{
      let str = []
      item.split(',').forEach(itemIndex=>{
        str.push(daysArr[itemIndex])
      })
      str = str.join(',')
      returnDays.push(str)
    })
  }
  return returnDays
}

/* 自定义数据类 */
export class CustomData { }
/* 自定义对象类 */
export class CustomObject extends CustomData{
  constructor(obj) {
    super()
    this.obj = obj
  }
  /* 移除一维或二维对象中值为空的属性 */
  filterKey(){
    let newObj = {}
    for (let k1 in this.obj) {
      for (let k2 in this.obj[k1]) {
        if (this.obj[k1][k2] !== '') {
          newObj[k1] === undefined && (newObj[k1] = {})
          newObj[k1][k2] = this.obj[k1][k2]
        }
      }
    }
    return newObj
  }
}
/* 自定义数组类 */
export class CustomArray extends CustomData{
  constructor(arr) {
    super()
    this.arr = arr
  }
  /*
   * 修改数组中对象的字段名称
   * @newKey *新字段名称
   * @oldKey *旧字段名称
  */
  changeKey(newKey, oldKey) {
    for (const item of this.arr) for (const key in item) key === oldKey && (item[newKey] = !item[key], delete item[key])
    return this.arr
  }
  /*
   * 数组/数组对象去重
  */
  unique() {
    const map = new Map()
    return this.arr.filter(item => !map.has(JSON.stringify(item)) && map.set(JSON.stringify(item), 1))
  }
}

/*
cron表达式
cron表达式在线生成工具  https://cron.qqe2.com/

秒  分钟	  小时  日  月  星期  年
*   *     *    *   *  ?     *
* state  one timeSlot
* time  one = yyyy-MM-dd HH:mm:ss   ||  timeSlot = [ yyyy-MM-dd , yyyy-MM-dd , HH:mm:ss ]
* */
export function getCron(state,time,){
  if(state === 'one'){
    return `${getSubStr(time,17)} ${getSubStr(time,14)} ${getSubStr(time,11)} ${getSubStr(time,8)} ${getSubStr(time,5)} ? ${time.substr(0,4)}`
  }else if(state === 'timeSlot'){
    let y = ''
    let M = ''
    let d = ''
    let data0 = time[0]
    let data1 = time[1]
    let data3 = time[2]
    if(getSubStr(data0,0,4) === getSubStr(data1,0,4)) y = getSubStr(data0,0,4)
    else y = `${getSubStr(data0,0,4)}-${getSubStr(data1,0,4)}`

    if(getSubStr(data0,5) === getSubStr(data1,5)) M = getSubStr(data0,5)
    else M = `${getSubStr(data0,5)}-${getSubStr(data1,5)}`

    if(getSubStr(data0,8) === getSubStr(data1,8)) d = getSubStr(data0,8)
    else d = `${getSubStr(data0,8)}-${getSubStr(data1,8)}`

    return `${getSubStr(data3,7)} ${getSubStr(data3,3)} ${getSubStr(data3,0)} ${d} ${M} ? ${y}`
  }else if(state === 'unTimes'){
    // let year = new Date().getFullYear()
    // 29 29 16 * * ? * 按天
    return `${getSubStr(time,7)} ${getSubStr(time,3)} ${getSubStr(time,0)} * * ? *`
  }
}
export function getSubStr(str,start,count = 2){
  let strData = str.substr(start,count)
  return  strData.length === 2 && strData.indexOf('0') === 0 ? strData.slice(1,2) : strData
}

/*
* 处理 计算精度丢失问题
* passive 被加减数
* active 加减数
* Symbol +加-减*乘/除
* */
export function accuracyCalculation(passive,active,Symbol = '+'){
  let passiveNumber = String(passive)
  let activeNumber = String(active)
  let passivCount = ''  // 被加减数 小数点几位数
  let activeCount = ''  // 加减数 小数点几位数
  let Multiple = 1      // 倍数
  let MultipleActive = 1
  if(passiveNumber.indexOf('.') !== -1 || activeNumber.indexOf('.') !== -1){
    if(passiveNumber.indexOf('.') !== -1) passivCount = passiveNumber.length - (passiveNumber.indexOf('.')+1)
    if(activeNumber.indexOf('.') !== -1) activeCount = activeNumber.length - (activeNumber.indexOf('.')+1)
    if(Symbol === '+' || Symbol === '-'){
      let count = passivCount > activeCount ? passivCount : activeCount
      for (let i = 0; i < count ; i++) Multiple = Multiple * 10
    }else{
      for (let i = 0; i < passivCount ; i++) Multiple = Multiple * 10
      for (let i = 0; i < activeCount ; i++) MultipleActive = MultipleActive * 10
    }
  }
  let result = ''
  if(Symbol === '+') result = passive * Multiple + active * Multiple
  else if(Symbol === '-') result = passive * Multiple - active * Multiple
  else if(Symbol === '*') result = (passive * Multiple) * (active * MultipleActive)
  else if(Symbol === '/') result = (passive * Multiple) / (active * MultipleActive)

  return  Symbol === '/' ? result : result / Multiple / MultipleActive
}

/*
* 处理cascader级数据
* */
export function setbuildCascader(res){
  let buildData
  if( res.length > 0){
    buildData =  res.map(item => {
      let children = []
      if(item.hasOwnProperty('buildUnits')){
        if (item.buildUnits.length > 0) children = item.buildUnits.map(items => { return {value: items.id, label: items.unitName,beginFloor:items.beginFloor,endFloor:items.endFloor} })
      }
      return {value: item.id, label: item.buildName, children}
    })
  }
  return buildData
}

/* 表单正则验证类 */
class VerificationRule {
  constructor() {
    this.reg = ''
    this.reg_1 = ''
  }
  /* 手机号验证 */
  mobile(value) {
    this.reg = /^1[3-9][0-9]{9}$/
    this.reg_1 = /0\d{2,3}-\d{7,8}/
    return !this.reg.test(value) && !this.reg_1.test(value)
  }
  /* 身份证验证 */
  certificateNo(value) {
    this.reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    return !this.reg.test(value)
  }
  /* 邮箱验证 */
  email(value) {
    this.reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    return !this.reg.test(value)
  }
  /* 整数 */
  integer(value) {
    this.reg = /^[0-9]+$/;
    return !this.reg.test(value)
  }
  decimal(value) {
    this.reg = /^(([1-9][0-9]{0,14})|(([0]\.[0-9][1-9]|[0]\.[1-9][0-9]|[0]\.[1-9]|[0]\.[1-9][1-9]|[1-9][0-9]{0,14}\.\d{1,2})))/;
    return !this.reg.test(value)
  }
  /*
   * 字符串最小及最大长度验证
   * @fromTo *最小-最大长度 例：[1,5]
  */
  scopeLength(value, fromTo) {
    this.reg = eval( '/^[\\u4e00-\\u9fa5]{'+ fromTo[0] +','+ fromTo[1] +'}$/');
    return !this.reg.test(value)
  }
  /*
   * 字符串最小长度验证
   * @fromTo *最小长度
  */
  minLength(value, fromTo) {
    this.reg = eval('/^[\\u4e00-\\u9fa5A-Za-z0-9]{'+ fromTo[0] +',}$/')
    return !this.reg.test(value)
  }
}
export const verificationRule = new VerificationRule()

/* 日期处理类  */
export function DateFactory(date = new Date()){
  this.date = new Date(date)
  this.year = this.date.getFullYear()
  this.nowDayOfWeek = this.date.getDay() - 1;
  this.month = this.date.getMonth()
  this.day = this.date.getDate()
  this.weekDay = this.date.getDay()
  this.hours = this.date.getHours()
  this.minutes = this.date.getMinutes()
  this.seconds = this.date.getSeconds()
  this.time = this.date.getTime()
}
Object.assign(DateFactory.prototype, {
  /*
   * Date()对象格式化转换
   * @falg 是否显示时分秒 值true=显示 false=不显示
   * return yyyy-MM-dd HH:mm:ss
  */
  dateFormat(falg = true) {
    let MM = this.month + 1 < 10 ? "0"+(this.month + 1) : this.month + 1
    let dd = this.day < 10 ? "0" + this.day : this.day
    let HH = this.hours < 10 ? "0" + this.hours : this.hours
    let mm = this.minutes < 10 ? "0" + this.minutes : this.minutes
    let ss = this.seconds < 10 ? "0" + this.seconds : this.seconds
    let formatDate = this.year + "-" + MM + "-" + dd
    this.constructor()
    return falg ? formatDate + " "+ HH + ":" + mm + ":" + ss : formatDate
  },
  /*
   * 时间戳格式化转换
   * @timestamp *时间戳
   * return yyyy-MM-dd HH:mm:ss
  */
  timestampFormat(timestamp, flag) {
    let date = new Date(parseInt(timestamp) * 1000);
    this.constructor(date)
    return this.dateFormat(flag)
  },
  /*
   * yy-MM-dd HH:mm:ss格式化转换
   * @time *日期 格式=yy-MM-dd HH:mm:ss
   * return 时间戳
  */
  changeTimestamp(date) {
    this.constructor(date.replace(/-/g,'/'))
    let timeStamp = this.time.toString().substr(0, 10)
    this.constructor()
    return timeStamp
  },
  /*
   * 获取距离当前时间某天数的日期
   * @days 天数 值：0=今天;1=明天;-1=昨天...
   * @falg 是否显示时分秒
   * return 格式：yyyy-MM-dd HH:mm:ss
  */
  getDistanceToday(days = 0, falg = true) {
    this.date.setDate(this.date.getDate() + days)
    this.constructor(this.date)
    return this.dateFormat(falg)
  },

  StartTime(oitem){
    let myDate = new Date(oitem);
    let year =  (myDate.getFullYear()).toString().padStart(2, '');
    let month = (myDate.getMonth() + 1).toString().padStart(2, '');
    let date = myDate.getDate().toString().padStart(2, '');
    let hours = myDate.getHours().toString().padStart(2, '');
    let minutes = myDate.getMinutes().toString().padStart(2, '');
    let seconds = myDate.getSeconds().toString().padStart(2, '');

    if(isNaN(year)){	//如果后后台中没有时间戳数据则返回“无”
      return "无"
    }else{
      return  year + '-' + month + '-' + date + ' ' + hours + ':' + minutes +':' + seconds
    }
  },

  /*
   * 获取距离指定时间某天数的日期
   * @date 指定日期 格式:yyyy-MM-dd HH:mm:ss
   * @method +或-
   * @days 天数 类型:int
   * @flag 是否只留年月日
   * return 格式：yyyy-MM-dd HH:mm:ss
  */
  getDistanceAppoint(date, method = '+', days, flag) {
    let timestamp = Date.parse(date.replace(/-/g, '/'));
    if(method === '+') timestamp = timestamp / 1000 + 24 * 60 * 60 * days;
    if(method === '-') timestamp = timestamp / 1000 - 24 * 60 * 60 * days;
    return this.timestampFormat(timestamp, flag);
  },
  /*
   * 获取日期加上天数后的新日期
   * @date 指定日期 格式:yyyy-MM-dd HH:mm:ss
   * @days 天数 类型:int
   * return 格式：yyyy-MM-dd HH:mm:ss
  */
  addDays(date,days){
    var nd = new Date(date);
    nd = nd.valueOf();
    nd = nd + days * 24 * 60 * 60 * 1000;
    nd = new Date(nd);
    //alert(nd.getFullYear() + "年" + (nd.getMonth() + 1) + "月" + nd.getDate() + "日");
    var y = nd.getFullYear();
    var m = nd.getMonth()+1;
    var d = nd.getDate();
    if(m <= 9) m = "0"+m;
    if(d <= 9) d = "0"+d; 
    var cdate = y+"-"+m+"-"+d;
    return cdate;
  },
  /*
   * 获取上周/本周的开始(周一)/结束(周日)日期
   * @type 周类型 值：the=本周;last=上周;next=下周
   * @fromDate 开始日期或结束日期 值：start=周一;end=周日
   * @falg 是否显示时分秒
  */
  getWeekDate(type = 'the', fromDate = 'start', falg = true) {
    let num = 0
    if (type === 'last') num = fromDate === 'start' ? 7 : 1
    if (type === 'the') num = fromDate === 'start' ? 0 : -(6 - this.nowDayOfWeek)
    if (type === 'next') num = fromDate === 'start' ? -7 : -13
    let date
    if (type === 'the' && fromDate === 'end') date = new Date(this.year, this.month, this.day - num);
    else date = new Date(this.year, this.month, this.day - this.nowDayOfWeek - num);
    this.constructor(date)
    return this.dateFormat(falg)
  },
  /*
   * 获取本月的开始/结束日期
   * @fromDate 开始或结束日期
  */
  getMonthDate(fromDate = 'start', falg = true) {
    let date
    if (fromDate === 'start') date = new Date(this.year, this.month, 1)
    if (fromDate === 'end') date = new Date(this.year, this.month, this.getMonthDays(this.month));
    this.constructor(date)
    return this.dateFormat(falg);
  },
  /*
   * 获得某月的天数
   * @month *当前月份
  */
  getMonthDays(month) {
    let monthStartDate = new Date(this.year, month, 1);
    let monthEndDate = new Date(this.year, month + 1, 1);
    let days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
    return days;
  },
  /*
   * 获取本周日期列表
   * return 日期数组
  */
  getWeekList() {
    let arr = []
    this.date = new Date()
    if(this.weekDay !== 0){
      for (let i = 1; i < 8; i++) {
        this.date = new Date(this.time + (i - this.weekDay) * 24 * 60 * 60 * 1000)
        this.constructor(this.date)
        arr.push(this.dateFormat(false))
      }
    }else{
      for (let i = 6; i >= 0; i--) {
        this.date = new Date(this.time - (i + this.weekDay) * 24 * 60 * 60 * 1000)
        this.constructor(this.date)
        arr.push(this.dateFormat(false))
      }
    }
    
    return arr
  },
  /*
   * 比较两个时间大小
   * @startTime *起始时间 格式:标准时间/yyyy-MM-dd HH:mm:ss
   * @endTime *结束时间
   * return true/false
  */
  compareTime(startTime, endTime) {
    return new Date(startTime).getTime() > new Date(endTime).getTime()
  },
  /*
   * 获取指定区间的全部日期
   * @startDate *起始日期 格式：yyyy-MM-dd
   * @endDate *结束日期
   * return []日期列表
  */
  getDateRange(startDate, endDate) {
    let dateRange = [];
    let i = 0;
    while(startDate <= endDate){
      dateRange[i] = startDate;
      let nextDate = new Date(startDate).getTime() + ( 24 * 60 * 60 * 1000);
      this.constructor(nextDate)
      startDate = this.dateFormat(false)
      i++;
    }
    return dateRange;
  },
  /*
   * 获取两个日期距离天数
   * @startDate *起始日期
   * @endDate *截止日期
   * bool *精确时分秒
   * return 天数
  */
  getDistanceDays(startDate, endDate,bool) {
    let days = new Date(endDate).getTime() - new Date(startDate).getTime()
    if(bool) return days
    return parseInt(days / (1000 * 59 * 60 * 24));
  }
})
export const dateFactory = new DateFactory()

export default {
  getDict,
  exportFile,
  getCron,
  verificationRule,
  beforeUpload,
  dateFactory,
  CustomArray,
  CustomObject,
  randomNum,
  search_substr_pos,
  getDuplicate,
  isRepeat,
  getDaySequence,
}
