export default [
   {
      path: '/issuing_center',
      name: 'issuingCenter',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/business/issuingCenter/issuingCenter')
      }
   },
]
