export default [
   {
      path: '/marketing_products',
      name:'marketingProducts',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/smtech/marketing/marketingProducts/list')
      },
   },{
      path: '/marketing_group',
      name:'marketingGroup',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/smtech/marketing/marketingGroup/marketingGroup')
      },
   },  {
      path: '/marketing_products/add',
      name:'addProducts',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/smtech/marketing/marketingProducts/add')
      },
   },
   {
      path: '/promotional_effectiveness',
      name:'promotionalEffectiveness',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/smtech/marketing/promotionalEffectiveness/promotionalEffectiveness')
      },
   },
   {
      path: '/Marketing/add',
      name:'/Marketing/add',
      meta: { token: true },
   }, {
      path: '/marketing_members',
      name:'marketingMembers',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/smtech/marketing/marketingMembers/list')
      },
   }, {
      path: '/customer_relationship',
      name:'customerRelationship',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/smtech/marketing/customerRelationship/list')
      },
   }, {
      path: '/distribution_set',
      name:'distributionSet',
      meta: { token: true },
      components: {
         sidebar:() => import("@/views/smtech/marketing/distributionSet/distributionSet")
      },
   }
]
