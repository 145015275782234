export default [
  {
    path: '/group/index',
    name: 'group_index',
    meta: { token: true },
    components: {
       sidebar:() => import('@/views/GroupManagement/group_index')
    }
  },
  {
    path: '/group/roomFeelingCenter',
    name: 'roomFeelingCenter',
    meta: { token: true },
    components: {
       sidebar:() => import('@/views/GroupManagement/roomFeelingCenter')
    }
  },
  {
    path: '/group/roomOperationReport',
    name: 'roomOperationReport',
    meta: { token: true },
    components: {
       sidebar:() => import('@/views/GroupManagement/roomOperationReport')
    }
  },
  {
    path: '/group/roomOperationReport/storeOperatingReport',
    name: 'storeOperatingReport',
    meta: { token: true },
    components: {
       sidebar:() => import('@/views/GroupManagement/storeOperatingReport')
    }
  },
  {
    path: '/group/couponsOperatingReport',
    name: 'couponsOperatingReport',
    meta: { token: true },
    components: {
       sidebar:() => import('@/views/GroupManagement/couponsOperatingReport')
    }
  },
  {
    path: '/group/couponsOperatingReport/couponsDetails',
    name: 'couponsDetails',
    meta: { token: true },
    components: {
       sidebar:() => import('@/views/GroupManagement/couponsDetails')
    }
  },
  {
    path: '/group/groupStatistics',
    name: 'groupStatistics',
    meta: { token: true },
    components: {
      sidebar:() => import('@/views/GroupManagement/groupStatistics')
    }
  },

]