const Print = function (dom, options) {
   if (!(this instanceof Print)) return new Print(dom, options);
   this.options = this.extend({
      'noPrint': '.no-print'
   }, options);
   if ((typeof dom) === "string") {
      this.dom = document.querySelector(dom);
   } else {
      this.isDOM(dom)
      this.dom = this.isDOM(dom) ? dom : dom.$el;
      console.log(dom, 'dom')
   }
   this.init();
};
Print.prototype = {
   /**
    * 初始化
    */
   init: function () {
      var content = this.getStyle() + this.getHtml();
      this.writeIframe(content);
   },
   /**
    * 配置属性扩展
    * @param {Object} obj
    * @param {Object} obj2
    */
   extend: function (obj, obj2) {
      for (var k in obj2) {
         obj[k] = obj2[k];
      }
      return obj;
   },
   /**
    复制原网页所有的样式
    */
   getStyle: function () {
      var str = "",
         styles = document.querySelectorAll('style,link');
      for (var i = 0; i < styles.length; i++) {
         str += styles[i].outerHTML;
      }
      str += "<style>" + (this.options.noPrint ? this.options.noPrint : '.no-print') + "{display:none;}</style>";
      // 去除height：100%样式，解决分页下，样式混乱问题
      str += "<style>html,body,div{height: auto!important;}</style>";
      return str;
   },
   // 表单赋值
   getHtml: function () {
      var inputs = document.querySelectorAll('input');
      var textareas = document.querySelectorAll('textarea');
      var selects = document.querySelectorAll('select');
      for (var k = 0; k < inputs.length; k++) {
         if (inputs[k].type == "checkbox" || inputs[k].type == "radio") {
            if (inputs[k].checked == true) {
               inputs[k].setAttribute('checked', "checked")
            } else {
               inputs[k].removeAttribute('checked')
            }
         } else if (inputs[k].type == "text") {
            inputs[k].setAttribute('value', inputs[k].value)
         } else {
            inputs[k].setAttribute('value', inputs[k].value)
         }
      }
      for (var k2 = 0; k2 < textareas.length; k2++) {
         if (textareas[k2].type == 'textarea') {
            textareas[k2].innerHTML = textareas[k2].value
         }
      }
      for (var k3 = 0; k3 < selects.length; k3++) {
         if (selects[k3].type == 'select-one') {
            var child = selects[k3].children;
            for (var i in child) {
               if (child[i].tagName == 'OPTION') {
                  if (child[i].selected == true) {
                     child[i].setAttribute('selected', "selected")
                  } else {
                     child[i].removeAttribute('selected')
                  }
               }
            }
         }
      }
      return this.dom.outerHTML;
   },
   /**
    创建iframe
    */
   writeIframe: function (content) {
      var w, doc, iframe = document.createElement('iframe'),
         f = document.body.appendChild(iframe);
      iframe.id = "myIframe";
      //iframe.style = "position:absolute;width:0;height:0;top:-10px;left:-10px;";
      iframe.setAttribute('style', 'position:absolute;width:0;height:0;top:-10px;left:-10px;');
      w = f.contentWindow || f.contentDocument;
      doc = f.contentDocument || f.contentWindow.document;
      doc.open();
      doc.write(content);
      doc.close();
      this.toPrint(w);
      setTimeout(function () {
         document.body.removeChild(iframe)
      }, 100)
   },
   /**
    打印
    */
   toPrint: function (frameWindow) {
      try {
         setTimeout(function () {
            frameWindow.focus();
            try {
               if (!frameWindow.document.execCommand('print', false, null)) {
                  frameWindow.print();
               }
            } catch (e) {
               frameWindow.print();
            }
            frameWindow.close();
         }, 10);
      } catch (err) {
         console.log('err', err);
      }
   },
   isDOM: (typeof HTMLElement === 'object') ?
      function (obj) {
         return obj instanceof HTMLElement;
      } : function (obj) {
         return obj && typeof obj === 'object' && obj.nodeType === 1 && typeof obj.nodeName === 'string';
      }
};
const MyPlugin = {}
MyPlugin.install = function (Vue, options) {
   // 4. 添加实例方法
   Vue.prototype.$print = Print
}
export default MyPlugin