import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// 路由工程(动态)化
let routerList = [];
(r => r.keys().forEach(item => routerList.push(...r(item).default)))(require.context('./', true, /\.rou\.js/));

export default new VueRouter({
  mode: 'history',
  routes: [
     {
        path: '/',
        name: 'defaultsRouter',
        redirect: { name: 'login' },
     }, {
        path: '/login',
        name: 'login',
        meta: { token: false },
        component: () => import('@/views/login/login'),
     }, {
        path: '/forget_pwd',
        name: 'forget_pwd',
        meta: { token: false },
        component: () => import('@/views/login/forgetPwd'),
     }, {
        path: '/home',
        name: 'template',
        meta: { token: false },
        component: () => import('@/views/Template'),
        children: [
           ...routerList
        ]
     }
  ]
})
