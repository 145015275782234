export default [
   {
      path: '/consumer_item_settings',
      name: 'consumerItemSet',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/PMS/accommodationSetting/consumerItemSet/consumerItemSet')
      }
   }, {
      path: '/make_a_note_setting',
      name: 'makeANoteSetting',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/PMS/accommodationSetting/makeANoteSetting/makeANoteSetting')
      }
   }, {
      path: '/house_type',
      name: 'houseType',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/PMS/accommodationSetting/houseTypeSet/houseType')
      }
   }, {
      path: '/collection_setting',
      name: 'collectionSetting',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/PMS/accommodationSetting/collectionSetting/collectionSetting')
      }
   },
]
