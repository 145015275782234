export default [
    {
        path: '/material_list',
        name: 'materialList',
        meta: { token: true },
        components: {
            sidebar: () => import('@/views/business/creativeCenter/materialLibrary/list')
        },
    }, {
        path: '/material_list/create',
        name: 'create',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/business/creativeCenter/materialLibrary/create')
        },
    }, {
        path: '/material_list/detail',
        name: 'detail',
        meta: { token: true },
        components: {
            sidebar: () => import('@/views/business/creativeCenter/materialLibrary/detail')
        },
    }, {
        path: '/material_list/putContent',
        name: 'create',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/business/creativeCenter/materialLibrary/putContent')
        },
    },
]
