<template>
  <el-date-picker
    :align="align"
    :size="size"
    class="m-right-10 status-time"
    v-model="dateValue"
    type="daterange"
    :picker-options="pickerOptions"
    :start-placeholder="$t('msg.start_date')"
    :range-separator="$t('msg.to')"
    :default-time="['00:00:00', '23:59:59']"
    :end-placeholder="$t('msg.end_date')"
    :value-format="format"
    @change="date_packer"
    :disabled="disabled"
    :clearable="false"
  ></el-date-picker>
</template>
<script>
  import { dateFactory } from '@/common/js/common'
  export default {
    props: {
      datePacker: {
        type: Array,
      },
      format: {
        type: String,
        default: 'yyyy-MM-dd',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: '',
      },
      align: {
        type: String,
        default: 'center',
      },
      pickerOptions: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        langCn: true,
        dateValue: [dateFactory.getDistanceToday(-7, false), dateFactory.getDistanceToday(0, false)], // 日期选择
        // pickerOptions: {
        //   // shortcuts: []
        // }
      }
    },
    mounted() {
      let shortcuts = [
        {
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          },
        },
        {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          },
        },
        {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          },
        },
      ]
      this.pickerOptions && (this.pickerOptions.shortcuts = shortcuts)
    },
    methods: {
      date_packer(val) {
        this.$emit('setDatePacker', val)
      },
    },
    watch: {
      datePacker(e) {
        if (e === '') {
          this.dateValue = ''
        } else {
          this.dateValue = e
        }
      },
    },
  }
</script>
