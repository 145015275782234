export default [
   {
      path: '/personal_label',
      name: 'personalLabel',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/data/memberTag/personalLabel/personalLabel')
      }
   }, {
      path: '/tag_group',
      name: 'tagGroup',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/data/memberTag/tagGroup/tagGroup')
      }
   }, {
      path: '/add_group',
      name: 'addGroup',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/data/memberTag/tagGroup/addGroup')
      }
   }, {
      path: '/tag_library',
      name: 'tagLibrary',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/data/memberTag/tagLibrary/tagLibrary')
      }
   }, {
      path: '/add_auto_tag',
      name: 'addAutoTag',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/data/memberTag/tagLibrary/addAutoTag')
      }
   }
]