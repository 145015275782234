export default [
   {
      path: '/eBooking_survey',
      name: 'survey',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/eBooking/survey/survey')
      },
   },
   {
      path: '/ebooking_room_type_manage',
      name: 'roomTypeManage',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/PMS/roomManage/roomTypeManage')
      }
   },
   {
      path: '/add_ebookingroom_type_manage',
      name: 'addRoomTypeManage',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/PMS/roomManage/addRoomTypeManage')
      }
   },
   {
      path: '/resCup_Summary/:resCupData',
      name: 'resCupSummary',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/eBooking/reserveSummary/reserveSummary')
      }
   }

]
