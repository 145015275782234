export default [
   {
      path: '/thesaurus',
      name: 'thesaurus',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/OMS/intellectQA/thesaurus')
      }
   }, {
      path: '/semantic_slot',
      name: 'semanticSlot',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/OMS/intellectQA/semanticSlot')
      }
   }, {
      path: '/manage_QA',
      name: 'manageQA',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/OMS/intellectQA/manageQA')
      }
   }
]