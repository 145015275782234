export default [
    {
        path: '/bargaining/bargaining_unit',
        name: 'bargainingUnit',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/business/bargainingUnit/bargainingUnit')
        },

    },
    {
        path: '/bargaining/price_template',
        name: 'bargainingPriceTemplate',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/PMS/priceManage/housePriceCalendar/housePriceCalendar')
        },

    },
]