<template>
   <el-form class="popupRule" ref="ruleForm" :model="ruleForm" >
      <el-form-item label="弹出频率">
         <div class="grey" >
            若用户<span class="black">点击</span>弹窗，下次触发相同条件时，弹窗将会
            <el-select size="mini" class="width-100" v-model="ruleForm.clickWinAction" placeholder="请选择" >
               <el-option v-for="item in ejectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <!-- problem： 功能缺失，待接口支持 -->
            <div v-show="ruleForm.clickWinAction === 'IMG'">
               添加弹出次数上限：活动期间，最多向用户展示
               <el-input-number class="width-100" size="mini" :min="1" :max="10" label="描述文字" />
               次
            </div>
            <div v-show="ruleForm.clickWinAction !== 'TEXT'">
               <el-checkbox></el-checkbox>添加弹出时间间隔：点击弹窗后
               <el-input-number size="mini" class="width-100 m-right-5" :min="1" :max="10" label="描述文字"></el-input-number>
               <el-input-number size="mini" class="width-100" :min="1" :max="10" label="描述文字"></el-input-number>
               内不再弹出
            </div>
         </div>

         <div class="grey">
            若用户<span class="black">关闭</span>弹窗，下次触发相同条件时，弹窗将会
            <el-select size="mini" class="width-100" v-model="ruleForm.closeWinAction" placeholder="请选择" >
               <el-option v-for="item in ejectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <!-- problem： 功能缺失，待接口支持 -->
            <div v-show="ruleForm.closeWinAction === 'IMG'">
               添加弹出次数上限：活动期间，最多向用户展示
               <el-input-number class="width-100" size="mini" :min="1" :max="10" label="描述文字" />
               次
            </div>
            <div v-show="ruleForm.closeWinAction !== 'TEXT'">
               <el-checkbox></el-checkbox>添加弹出时间间隔：点击弹窗后
               <el-input-number size="mini" class="width-100 m-right-5" :min="1" :max="10" label="描述文字"></el-input-number>
               <el-input-number size="mini" class="width-100" :min="1" :max="10" label="描述文字"></el-input-number>
               内不再弹出
            </div>
         </div>
      </el-form-item>
   </el-form>
</template>

<script>
export default {
   name: "popupRule",
   data(){
      return{
         ruleForm:{
            clickWinAction:'TEXT',  // 点击
            closeWinAction:'TEXT',  // 关闭
         },
         ejectList: [ // 弹出列表
            { id: "TEXT", name: '不再弹出' },
            { id: "IMG", name: '限制次数内弹出' },
            { id: "VOICE", name: '每次弹出' },
         ],
      }
   },
   methods:{}
}
</script>

<style scoped lang="scss">
.popupRule{
   .grey{
      color: #606266;
      .black{
         color: black;
         font-weight: bold;
      }
   }
}
</style>