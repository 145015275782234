export default [
   {
      path: '/alarm_configurationalarm',
      name: 'alarmConfigurationalarm',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/AIOT/alarmManagement/alarmConfigurationalarm')
      }
   }
]
