export default [
   {
      path: '/enterprise_wechat/live_code',
      name: 'liveCode',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/data/enterpriseWechat/liveCode/list')
      }
   }, {
      path: '/enterprise_wechat/live_code/create',
      name: 'liveCodeCreate',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/data/enterpriseWechat/liveCode/create')
      }
   }, {
      path: '/quick_reply',
      name: 'quickReply',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/data/enterpriseWechat/quickReply')
      }
   }, {
      path: '/customer_info',
      name: 'customerList',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/data/enterpriseWechat/customerManage/customerList')
      }
   }, {
      path: '/enterprise_wechat/tag_manage',
      name: 'tagManage',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/data/enterpriseWechat/customerManage/tagManage')
      }
   }, {
      path: '/mass_message',
      name: 'customerInfo',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/data/enterpriseWechat/customerManage/massMessage/list')
      }
   }, {
      path: '/mass_message/create',
      name: 'customerCreate',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/data/enterpriseWechat/customerManage/massMessage/create')
      }
   }, {
      path: '/moments_mass',
      name: 'momentsMass',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/data/enterpriseWechat/customerManage/momentsMass/list')
      }
   }, {
      path: '/moments_mass/create',
      name: 'momentsMass',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/data/enterpriseWechat/customerManage/momentsMass/create')
      }
   }, {
      path: '/enterprise_wechat/data_statistics',
      name: 'dataStatistics',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/data/enterpriseWechat/dataStatistics')
      }
   }, {
      path: '/enterprise_wechat/staff_manage',
      name: 'staffManage',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/data/enterpriseWechat/staffManage')
      }
   }, {
      path: '/enterprise_wechat/config',
      name: 'staffManage',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/data/enterpriseWechat/config')
      }
   }
]
