/* 优惠券 */
export const coupon = {
   // 创建优惠券
   addCoupon: '/event/coupon/add',
   // 编辑优惠券
   editCoupon: '/event/coupon/edit',
   // 获取优惠券列表
   couponPage: '/event/coupon/getPage',
   // 获取优惠券列表
   couponList: '/event/coupon/getList',
   // 删除优惠券
   delCoupon: '/event/coupon/delete',
   // 优惠券详情
   couponDetail: '/event/coupon/get',
   // 优惠券领取列表
   couponReceive: '/event/couponmember/getPage',
   // 优惠券领取列表
   delCouponReceive: '/event/couponmember/delete',
   // 优惠券领取核销
   couponWriteOff: '/event/couponmember/writeOff',
   // 优惠券领取详情
   couponReceiveDetail: '/event/couponmember/get',
   // 修改优惠券状态
   couponStatus: '/event/coupon/updateStatus',
   // 领券链接
   receiveLink: '/event/couponmember/receive',
}

/* 预售券 */
export const preSale = {
   // 获取预售券列表
   preSalePage: '/event/couponPresell/getPage',
   // 获取预售券列表
   preSaleList: '/event/couponPresell/getList',
   // 保存预售券
   savePreSale: '/event/couponPresell/save',
   //删除预售券
   deletecouponPresell:'/event/couponPresell/delete/',
   // 下载海报
   downloadBatch: '/event/couponPresell/downloadBatch',
   //预售券审核
   examinecouponPresell:'/event/couponPresell/updateStatus',
   // 海报查询
   posterPage:'/event/couponPresell/getDistributorPage',
   // 导出预售券统计报表
   exportCouponMyselfList: "/event/couponPresell/exportCouponMyselfList",
   // 导出预售券汇总报表
   getCouponPresellSalesExcel: "/event/couponPresell/getCouponPresellSalesExcel",
   // 获取预售券汇总报表
   getCouponPresellSalesInfo: "/event/couponPresell/getCouponPresellSalesInfo",
   // 获取整体概况 预售券数量
   getCouponPresellSellDetail: "/event/couponPresell/getCouponPresellSellDetail",
   // 获取整体概况 优惠券数量
   couponStatistics: "/event/couponmember/couponStatistics",
   // 分销海报统计数
   statisticsCouponPresell: '/event/couponPresell/getStatisticsCouponPresell',
   // 获取预售券购买列表
   couponMyselfPage: '/event/couponPresell/getCouponMyselfPage',
   // 预售劵购买列表核销接口
   useCouponMyself: '/event/couponMyself/useCouponMyself',
}

/* 分销商 */
export const distributor = {
   // 添加分销商
   saveDistributor: '/event/distributor/save',
   // 获取分销商列表
   distributorPage: '/event/distributor/getPage',
   // 获取分销商列表
   distributor: '/event/distributor/getList',
   // '渠道分配'
   bindChannel: '/event/couponPresell/bind',
   // 已分配
   getBindList: '/event/distributor/getBindList',
   // 删除分销商
   delDistributor: '/event/distributor/delete',
   // 删除已分配渠道
   deleteChannel: '/event/couponPresell/unbind',
   // 渠道商业绩列表
   distributorSalesList: '/event/distributor/getDistributorSalesList',
   // 渠道商业绩详情
   couponPresell: '/event/couponPresell/getCoupon',
}

/* 对账报表 */
export const reconciliation = {
   // 获取销售额
   getAmount: "/order/getAmount",
   // 获取销售概况echarts数据
   getAmountDetail: "/order/getAmountDetail",
   // 销售概况导出
   getAmountExcel: '/order/getAmountExcel',
   // 获取订房销售明细
   getRoomRateDetail: "/order/getRoomRateDetail",
   // 获取订房销售明细详情
   getRoomRateDetailInformation: "/order/getRoomRateDetailInformation",
   // 订房导出
   getRoomRateDetailExcel: "/order/getRoomRateDetailExcel",
   // 获取预售券销售明细
   getStatisticsCouponMyself: "/event/couponMyself/getStatisticsCouponMyself",
   // 获取预售券销售明细详情
   getStatisticsCouponMyselfList: "/event/couponMyself/getStatisticsCouponMyselfList",
   // 预售券导出
   CouponExcel: '/event/couponMyself/statisticsCouponMyselfExportExcel',
   // 获取分账订单列表
   getShareTheMoneyList: "/order/shareTheMoneyList",
   // 分账订单列表导出
   shareTheMoneyListExcel: "/order/shareTheMoneyListExcel",
   // 获取分账订单明细
   orderCommissionInfoDetail: "/event/marketingUserIncome/orderCommissionInfoDetail",
   // 分账订单明细导出
   orderCommissionInfoDetailExcel: "/event/marketingUserIncome/orderCommissionInfoDetailExcel",
   // 重新分账
   anewProfitsharing: "/event/marketingUserIncome/anewProfitsharing",
   //交易账单
   getBill:'/order/getBill',
   //导出交易账单
   getBillExcel:'/order/getBillExcel'
}

/* 订单管理 */
export const order = {
   // 获取订单列表
   getOrder: '/order/getPage',
   // 获取酒店列表
   getHotels: '/hotel/selectHotelList',
   // 获取公司列表
   getAllCompany: '/system/company/getAllCompany',
   // 修改订单状态
   updateOrderStatus: '/order/updateOrderStatus',
   // 获取酒店订单列表
   hotelOrder: '/order/hotel/getPage',
   // 取消酒店预订单
   cancelHotelOrder: '/order/hotel/cancelOrder',
   // 完成酒店订单
   finishHotelOrder: '/order/profitsharing',
   // 导出订单数据
   orderHotelsExcel: '/order/hotel/getOrderHotelsExcel',
}

/* 创作中心 */
export const writing = {
   // 创建素材
   createMaterial: '/event/writingCenter/create',
   // 编辑素材
   editMaterial: '/event/writingCenter/update',
   // 获取素材列表
   materialPage: '/event/writingCenter/getPage',
   // 获取文件列表
   getFileList: '/event/material/getFileList',
   // 获取素材列表
   materialList: '/event/writingCenter/getList',
   // 删除素材
   delMaterial: '/event/writingCenter/delete',
   // 上传图片
   uploadMaterialImg: '/event/material/uploadImg',
}

/* 发券中心 */
export const issuing = {
   // 创建任务
   create: '/event/coupongiven/add',
   // 获取券列表
   getPage: '/event/coupongiven/getPage',
   // 获取任务指定卡券列表
   givenCards: '/event/coupongivenitem/getList',
   // 发送卡券
   send: '/event/coupongiven/send',
   // 删除卡券
   delete: '/event/coupongiven/delete',
   // 获取指定用户列表
   givenUsers: '/event/coupongivenuser/getList'
}
