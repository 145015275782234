<template>
  <el-tabs v-model="activefloor" @tab-click="handleClick">
    <el-tab-pane v-for="(item, index) in floorOpt" :key="index" :name="index +''">
      <span slot="label">
        <span v-text="item.label"></span>
        <span v-if="index > 0" v-text="$t('msg.floors')"></span>
      </span>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  props: ['floorOpt'],
  data () {
    return {
       activefloor: '', // 楼层
       floorList: ''
    }
  },
   methods: {
    // 楼层点击
    handleClick (v) {
      this.$emit('setFloorClick', {floor: this.floorOpt[Number(this.activefloor)].value})
    }
  },
  watch: {
    floorOpt (e) {
       this.activefloor = '0'
       this.floorOpt = e
       /*let r = e.filter(item => {
          return item.label > 0 || item.label === '全部'
       })
       this.floorOpt = r*/
    }
  }
}
</script>

<style scoped>
  .label{
    font-size: 14px;
    vertical-align: middle;
  }
</style>
