<template>
   <el-drawer class="addcheckIn" :visible.sync="checkInBools" direction="rtl" :wrapperClosable="false" :before-close="closeCheckin">
      <div slot="title">
         <el-page-header @back="closeCheckin" content="入住人信息"></el-page-header>
      </div>
      <!-- main -->
      <div class="checkin-main">
         <el-row class="roomsInfo">
            <el-col :span="9">入住时间：{{currentChecks.startTime}}</el-col>
            <el-col :span="3">{{currentChecks.duration}}</el-col>
            <el-col :span="9">{{currentChecks.rooms ? currentChecks.rooms : '未排房'}}/{{currentChecks.roomType}}</el-col>
            <el-col :span="3">￥{{currentChecks.roomRate}}</el-col>
         </el-row>
         <div class="content">
            <div class="p-t-m-20">入住人姓名为必填项</div>
            <div class="combinInfo">
               <div v-for="(item,index) in checkinLists" :key="index" class="m-bottom-10">
                  <el-input class="input-combin" placeholder="证件号" v-model="item.certificateNo" style="width: 535px">
                     <el-input class="width-100"  slot="prepend" placeholder="姓名" v-model="item.realName"></el-input>
                     <el-input class="width-137"  slot="prepend" placeholder="手机号" v-model="item.mobile"></el-input>
                     <el-select class="width-137" slot="prepend" v-model="item.certificateType"  placeholder="请选择">
                        <el-option
                              v-for="item in dictData['certificate-type-list']"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                        </el-option>
                     </el-select>
                     <el-button slot="append" type="text" @click="cardRead(item,index)">读卡</el-button>
                  </el-input>
                  <el-button class="hover-delete m-left-15" type="text" icon="el-icon-delete" @click="checkinDel(item,index)"></el-button>
               </div>
            </div>
            <el-button class="m-top-10 m-bottom-20" type="primary" plain size="small" icon="el-icon-plus" @click="checkinAdd">添加入住人</el-button>
         </div>

      </div>
      <!-- footer -->
      <div class="checkin-footer">
         <el-button @click="closeCheckin">取消</el-button>
         <el-button class="bg-gradient" type="primary" style="margin:0px 20px" @click="handleSave()">完成</el-button>
      </div>
   </el-drawer>
</template>

<script>
import CardReader from '@/utils/cardReader/CardReader'
import { getDict } from '@/common/js/common'
import { mapState } from "vuex";
import { checkinMemberModify } from "../../api/pms/orderDetails";
import { getByBrandCode } from '@/api/pms/atrialState/atrialState'
export default {
   name: "addcheckIn",
   props:{
      // 是否显示
      checkInpersonBool:{
         type:Boolean,
         default:false,
      },
      // 房间信息
      currentCheck:{
         type:Object,
         default: function (){
            return {}
         },
      },
      // 入住人信息
      checkinList:{
         type:Array,
         default: function (){
            return [{
               realName:'',
               mobile:'',
               certificateType:'',
               certificateNo:'',
            }]
         },
      },
      /*
      * 预订单中的入住人-edit
      * 订单详情中的入住人-SingleEdit
      * 详情-编辑 预订单中的入住人-EditInfo
      * */
      state:{
         type:String,
         default:'edit',
      }

   },
   watch:{
      currentCheck(){
         this.currentChecks = this.currentCheck
      },
      checkinList(){
         this.checkinLists = this.checkinList
         // 给身份证设置默认值
         this.certificateDefault = this.dictData['certificate-type-list'][0].value
         this.checkinList.forEach(item=>{item.certificateType = this.certificateDefault })
      },
     checkInpersonBool(newVal){
        if(newVal){ getDict(['certificate-type','payment-method'],false) }
       this.checkInBools = this.checkInpersonBool
     },
   },
   computed: {
      ...mapState(['dictData','hotelInfo']),
   },
   data(){
      return{
         checkInBools:false,        // 是否显示
         certificateDefault:'',     // 证件类型默认值
         currentChecks:{},
         // 入住人信息
         checkinLists:[{
            realName:'',
            mobile:'',
            certificateType:'',     // 证件类型
            certificateNo:'',       // 证件号
         }],
         removeIds:[],              // 需要删除的id数组
         addCheckinMemberList:[],   // 新添加的入住人
         modifyCheckinMemberList:[],// 需要修改的入住人信息
      }
   },
   mounted() {
      
   },
   methods:{
      // 关闭 抽屉
      closeCheckin(){
         this.checkInBools = false
         this.checkinLists = []
         this.$emit("closeCheckin",this.checkInBools)
      },
      // 读卡
      cardRead(item){
         let bool = true
         const params = { hotelId: this.hotelInfo.id }
         getByBrandCode(params).then((res) => {
            if(res.success){
               res.data.forEach(item=>{
                  if(item.hardwareType === 'ID_CARD_READER'){
                     bool = false
                     this.$message.info('brandCode:'+item.brandCode)
                  }
               })
            }
         })
        if(bool){
           const cardReader = new CardReader()
           cardReader.connect()                                  // 连接
           let res = cardReader.readCert()                       // 读卡
           if(res.hasOwnProperty('resultContent')) {
              item.realName = res.resultContent.partyName        // 姓名
              item.certificateNo = res.resultContent.certNumber  // 身份证号
              item.certificateType = 'IDCARD'
           }
           cardReader.disconnect()                               // 断卡
        }
      },

      // 删除入住人
      checkinDel(item,index){
         if(this.state === 'SingleEdit' || this.state === 'EditInfo'){
            if(item.hasOwnProperty('id')) this.removeIds.push(item.id)
         }
         this.checkinLists.splice(index,1)
      },
      // 添加入住人
      checkinAdd(){
         this.checkinLists.push({
            realName:'',
            mobile:'',
            certificateType:'',        // 证件类型
            certificateNo:'',          // 证件号
         })
      },

      // 完成
      handleSave(){
         // 验证
         let bool = true
         this.checkinLists.forEach((item,index)=>{
            if(!item.realName) return this.$message.error(`第${index+1}条数据姓名为空`),bool = false;
            if(!item.mobile) return this.$message.error(`第${index+1}条数据手机为空`),bool = false;
            if(!item.certificateType) return this.$message.error(`第${index+1}条数据证件类型为空`),bool = false;
            if(!item.certificateNo) return this.$message.error(`第${index+1}条数据证件号为空`),bool = false;
         })

         if(bool){
            if(this.state === 'SingleEdit'){
               this.checkinList.forEach(item=>{
                  // 需要修改的入住人信息
                  if(item.hasOwnProperty('id')){
                     this.modifyCheckinMemberList.push({
                        hotelId:this.hotelInfo.id,
                        realName:item.realName,
                        mobile:item.mobile,
                        certificateNo:item.certificateNo,
                        certificateType:item.certificateType,
                        id:item.id,
                     })
                  }else{
                     // 新添加的入住人信息
                     this.addCheckinMemberList.push({
                        hotelId:this.hotelInfo.id,
                        realName:item.realName,
                        mobile:item.mobile,
                        certificateNo:item.certificateNo,
                        certificateType:item.certificateType,
                        checkinRoomId: this.currentChecks.id
                     })
                  }
               })
               let param = {
                  removeIds:this.removeIds,
                  addCheckinMemberList:this.addCheckinMemberList,
                  modifyCheckinMemberList:this.modifyCheckinMemberList,
               }
               checkinMemberModify(param).then(res=>{
                  if(res.success){
                     this.removeIds = []
                     this.addCheckinMemberList = []
                     this.modifyCheckinMemberList = []
                     this.$emit('handleFinish',false)
                     this.$message.success("编辑入住人成功")
                  }
               })
            }else{
               let data = ''
               if(this.state === 'EditInfo'){
                  data = this.currentChecks.id
               }else if(this.state === 'edit'){
                  data = this.currentChecks.keys
               }
               this.$emit('handleFinish',this.checkinLists,false,data,this.removeIds)
            }
       /*     else if(this.state === 'EditInfo'){
            }else{
               console.log('c')
                this.$emit('handleFinish',this.checkinLists,false,this.currentChecks.keys)

            }*/
         }

      },
   },
}
</script>

<style scoped lang="scss">
$border-grey:#eff0f5;
.addcheckIn{
   // header
   ::v-deep .el-drawer__open{
      .el-drawer__header{
         border-bottom: 1px solid $border-grey;
         padding: 5px 20px;
         margin: 0;
         .el-page-header{ border-bottom: 0px solid transparent}
      }
      .el-drawer{ width: 36% !important; }
   }
   // main
   .checkin-main{
      //margin: 0 20px;
      .roomsInfo{
         padding: 0 20px;
         text-align: center;
         background-color:#fafbfe;
         height: 45px;
         line-height: 45px;
         color: #99a9bf;
      }
      .content{
         padding: 0 20px;
         ::v-deep .input-combin{
            .el-input-group__prepend {
               padding: 0;
               border-left: 0px solid transparent;
               width: 355px;
               .el-input__inner{
                  height: 37px;
                  border-right: 0px solid transparent;
                  border-top: 0px solid transparent;
                  border-bottom: 0px solid transparent;
                  background-color: #fff;
               }
               .el-select{
                  border-left: 1px solid #DCDFE6;
                  border-right: 1px solid #DCDFE6;
               }
               //.el-input-number{
               //   line-height: 37px;
               //   .el-input-number__increase, .el-input-number__decrease{
               //      //top:0!important;
               //      border-radius: 0;
               //   }
               //}
            }
         }

      }
   }
   // footer
   .checkin-footer{
      width: 100%;
      height: 65px;
      line-height: 65px;
      text-align: right;
      background-color: white;
      position: absolute;
      bottom: 0px;
      border-top: 1px solid $border-grey;
      padding: 0 10px;
   }
}
</style>
