export default [
   {
      path: '/preferential_mall/order_manage',
      name: 'orderManage',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/orderCenter/orderManage/list')
      }
   }, {
      path: '/preferential_mall/order_manage/detail',
      name: 'orderDetail',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/orderCenter/orderManage/detail')
      }
   }, {
      path: '/preferential_mall/after_sale_center',
      name: 'afterSaleCenter',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/orderCenter/afterSalesCenter')
      }
   },  {
      path: '/preferential_mall/order_write_off',
      name: 'orderWriteOff',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/orderCenter/orderWriteOff')
      }
   }
]
