export default [
  {
    path: '/hotel_product_categories',
    name: 'hotelProductCategories',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/AIOT/hotelDevice/productManage/productCategories')
    }
  },
  {
    path: '/hotel_product_list',
    name: 'hotelProductList',
    meta: { token: true },
    components: {
      sidebar:() => import('@/views/AIOT/hotelDevice/productManage/productList')
    }
  },
  {
    path: '/hotel_all/:device_type_id',
    name: 'hotelAll',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/AIOT/hotelDevice/hotelAll/hotelAll')
    }
  },
  {
    path: '/hotel_device_detail',
    name: 'hotelDeviceDetail',
    meta: { token: true },
    components: {
      sidebar:() => import('@/views/AIOT/hotelDevice/hotelAll/hotel_device_detail')
    }
  },
  {
    path: '/hotel_device_edit',
    name: 'hotelDeviceEdit',
    meta: { token: true },
    components: {
      sidebar:() => import('@/views/AIOT/hotelDevice/hotelAll/hotel_device_edit')
    }
  },
  {
    path: '/hotel_scenes_config',
    name: 'hotelScenesConfig',
    meta: { token: true },
    components: {
      sidebar:() => import('@/views/AIOT/hotelDevice/hotel_scenes_config/hotel_scenes_config')
    }
  },
  {
    path: '/hotel_scenes_detail',
    name: 'hotelScenesDetail',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/AIOT/hotelDevice/hotel_scenes_config/hotel_scenes_detail')
    }
  },
  {
    path: '/hotel_scenes_edit',
    name: 'hotelScenesEdit',
    meta: { token: true },
    components: {
      sidebar:() => import('@/views/AIOT/hotelDevice/hotel_scenes_config/hotel_scenes_edit')
    }
  },
  {
    path: '/hotel_device_alarm',
    name: 'hotelDeviceAlarm',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/AIOT/hotelDevice/deviceAlarm/deviceAlarm')
    }
  },
  {
    path: '/hotel_flyingroom_alarm',
    name: 'hotelDeviceAlarm',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/AIOT/hotelDevice/deviceAlarm/flyingroomAlarm')
    }
  },
  {
    path: '/hotel_offline_alarm',
    name: 'hotelDeviceAlarm',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/AIOT/hotelDevice/deviceAlarm/offlineAlarm')
    }
  }
]