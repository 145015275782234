export default [
    {
        path: '/channel_kanban/basic_indicators',
        name: 'basicIndicators',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/business/channelKanban/basicIndicators')
        },
    },
    {
        path: '/channel_kanban/traffic_track',
        name: 'trafficTrack',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/business/channelKanban/trafficTrack')
        },
    }
]