export default [
  {
    path: '/distribution_code',
    name: 'distributionCode',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/business/preSaleCoupon/distributionCode/distributionCode'),
    },
  },
  {
    path: '/add_entrance',
    name: 'addEntrance',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/business/coupon/addEntrance/addEntrance'),
    },
  },
  {
    path: '/add_coupon/:coupon_type',
    name: 'addCoupon',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/business/coupon/addEntrance/addCoupon'),
    },
  },
  {
    path: '/pre_sale_survey',
    name: 'survey',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/business/survey/survey'),
    },
  },
  {
    path: '/pre_sale_list',
    name: 'preSaleList',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/business/preSaleCoupon/preSaleList/list'),
    },
  },
  {
    path: '/pre_sale_adds',
    name: 'preSaleAdd',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/business/preSaleCoupon/preSaleList/add'),
    },
  },
  {
    path: '/pre_sale_buy',
    name: 'preSaleBuy',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/business/preSaleCoupon/preSaleBuy/buyList'),
    },
  },
  {
    path: '/coupon_audit',
    name: 'couponAudit',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/business/coupon/auditList/audit'),
    },
  },
  {
    path: '/coupon_list',
    name: 'couponList',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/business/coupon/couponList/coupon'),
    },
  },
  {
    path: '/receive_list',
    name: 'receiveList',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/business/coupon/receiveList/receive'),
    },
  },
  {
    path: '/preSaleDetails',
    name: 'preSaleDetails',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/business/preSaleCoupon/preSaleDetails/preSaleDetails'),
    },
  },
  {
    path: '/pictureSetting',
    name: 'pictureSetting',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/business/wechatSetting/pictureSetting'),
    },
  },
  {
    path: '/cash',
    name: 'cashManagement',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/business/CashManagement/cash'),
    },
  },
  {
    path: '/real_time_statistics',
    name: 'realTimeStatistics',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/business/userStatistics/realTimeStatistics'),
    },
  },
  {
    path: '/overall_trend',
    name: 'overallTrend',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/business/userStatistics/overallTrend'),
    },
  },
  {
    path: '/lifecycle',
    name: 'lifecycle',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/business/userStatistics/lifecycle'),
    },
  },
]
