export default [
   {
      path: '/aiot/index',
      name: 'aiotIndex',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/AIOT/index/index')
      }
   },{
      path: '/hotel_manage',
      name: 'Hotel',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/AIOT/hotelManage/hotelList/hotel/hotel')
      }
   }, {
      path: '/synchro',
      name: 'synchro',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/AIOT/hotelManage/hotelList/device/synchro')
      }
   }, {
      path: '/device',
      name: 'device',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/AIOT/hotelManage/hotelList/device/device')
      }
   }, {
      path: '/hotel_add_basic',
      name: 'HotelAddBasic',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/AIOT/hotelManage/hotelList/hotel/HotelAddBasic')
      }
   }, {
      path: '/hotel_add_detail',
      name: 'HotelAddDetail',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/AIOT/hotelManage/hotelList/hotel/HotelAddDetail')
      }
   },
   {
      path: '/building',
      name: 'Building',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/AIOT/hotelManage/building/building')
      }
   },
   {
      path: '/room_type',
      name: 'roomType',
      components: {
         sidebar: () => import('@/views/AIOT/hotelManage/roomType/roomType')
      }
   },
   {
      path: '/room_manage',
      name: 'roomList',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/AIOT/hotelManage/roomManage/roomList/roomList')
      }
   },
   {
      path: '/room_device',
      name: 'roomDevice',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/AIOT/hotelManage/roomManage/roomDevice/roomDevice')
      }
   },
   {
      path: '/sample_room',
      name: 'sampleRoom',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/AIOT/hotelManage/sampleRoom/sampleRoom')
      }
   },
   {
      path: '/hotel_facilities',
      name: 'hotelfacilities',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/AIOT/hotelManage/hotelFacilities/facilities')
      }
   },
   {
      path: '/pms_manage',
      name: 'pmsManage',
      components: {
         sidebar:() => import('@/views/AIOT/hotelManage/pmsManage/pms')
      }
   },
   {
      path: '/voice_device',
      name: 'voiceDevice',
      meta: { token: true },
      components: {
         sidebar:  () => import('@/views/AIOT/hotelManage/voiceDevice/voiceDevice')
      }
   },
]
