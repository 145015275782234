<template>
  <div class="page-div">
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :pager-count="5"
      :page-sizes="pageSize >= 50 ? [50, 70, 90, 110] : [10, 20, 30, 40, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      hide-on-single-page
    ></el-pagination>
  </div>
</template>
<script>
  export default {
    data() {
      return {}
    },
    props: {
      total: {
        type: Number,
        default: 1,
      },
      pageSize: {
        type: Number,
        default: 10,
      },
      currentPage: {
        type: Number,
        default: 1,
      },
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event)
      },
      handleSizeChange(val) {
        this.$emit('handleSizeChangeSub', val)
      },
      handleCurrentChange(val) {
        this.$emit('handleCurrentChangeSub', val)
        this.$emit('total', 0)
      },
    },
  }
</script>
<style scoped>
  .page-div {
    text-align: right;
    background: white;
    margin: 30px 0 0 0;
  }
</style>
