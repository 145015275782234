<template>
   <el-row class="operateSetcontent">
         <el-col :span="14" class="operateLeft">
            <el-form class="" ref="ruleForm" :model="ruleForm" :rules="rules">
               <el-form-item label="模板选择">
                  <div class="img-box">
                     <span><i class="el-icon-picture-outline"></i></span>
                  </div>
               </el-form-item>
               <el-form-item class="border-top" label="背景图片" prop="mediaPath" >
                  <el-input disabled  placeholder="输入填写URL" class="input-with-select width-500" />
                  <div class="imgTips width-480 margin-left-70">
                     图片最大宽度为640PX最大高度为420PX（缩放样式以具体设备为准），格式支持JPG、PNG、JPEG、GIF格式，
                     图片大小在2M以内。网址必须以https://或http://开头。
                  </div>
                  <el-row class="upload margin-left-70 width-500">
                     <el-upload
                           class="avatar-uploader"
                           action="#"
                           :limit="1"
                           :show-file-list="true"
                           :on-change="changeUpload"
                           :auto-upload="false"
                           :multiple="false"
                           list-type="picture-card"
                           style="z-index: 999">
                        <p>选中图片</p>
                     </el-upload>
                     <p style="color: red">请输入以https://或http://开头的网址</p>
                  </el-row>
               </el-form-item>
               <el-form-item class="border-top" label="跳转地址" prop="" >
                  <el-radio-group v-model="ruleForm.textType">
                     <el-radio label="APPLETS_PAGE">小程序页面跳转地址</el-radio>
                     <el-radio label="PAGE">公众号文章跳转地址</el-radio>
                       <el-radio label="COUPON_PRESELL">预售券</el-radio>
                      <el-radio label="COUPON">优惠券</el-radio>
                  </el-radio-group>

                  <el-row v-if="ruleForm.textType === 'APPLETS_PAGE' || ruleForm.textType === 'PAGE'">
                     <el-input  class="width-500 margin-left-70" placeholder="请输入" v-model="ruleForm.pagePath"></el-input>
                     <el-input  class="width-500 margin-left-70" placeholder="小程序内的跳转链接(小程序根目录下的 app.json 文件中的页面路径)" disabled></el-input>
<!--                     <el-row class="text" v-show="textType === 'APPLETS_PAGE' || textType === 'PAGE'"> </el-row>-->
                  </el-row>
                  <el-form-item label="选择预售券" v-if="ruleForm.textType === 'COUPON_PRESELL'" >
                     <el-select  placeholder="请选择券" v-model="ruleForm.couponId" >
                        <el-option
                              v-for="item in preSaleList"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                        </el-option>
                     </el-select>
                  </el-form-item>
                  <el-form-item label="选择优惠券" v-if="ruleForm.textType === 'COUPON'" >
                     <el-select  placeholder="请选择券" v-model="ruleForm.couponId">
                        <el-option
                              v-for="item in couponList"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                        </el-option>
                     </el-select>
                  </el-form-item>

               </el-form-item>
               <el-form-item class="border-top" >
                  <el-button class="bg-gradient" @click="backPage">上一步</el-button>
                  <el-button class="bg-gradient" @click="submit">下一步</el-button>
               </el-form-item>

            </el-form>
         </el-col>
         <el-col :span="10" class="operateRight">
            <el-row class="img">
               <el-row class="dynamic-img">
                  <img :src="ruleForm.mediaPath" alt="">
               </el-row>
               <img src="@/assets/create_applet_img.jpg" alt="" >
            </el-row>
         </el-col>
   </el-row>
</template>

<script>
import { urlObj } from '@/api/interface'
import { coupon , preSale } from  '@/api/interface/business'
export default {
   name: "operateSetcontent",
   props:{
      active:{
         type:Number,
         default:2
      },
   },
   watch:{
      active(){ this.activeNum = this.active },
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id  && oldVal) {
               this.hotelId = this.hotelInfo.id
               this.getCoupons()
               this.getPreSalePage()
            }
         },
         deep: true
      },
   },
   data(){
      let validMediaPath = (rule, value, callback) => {
         if (!this.ruleForm.file) callback(new Error('背景图片不能为空！'))
         else callback()
      }
      return{
         activeNum:'',
         ruleForm:{
            mediaPath:'',              // 图片
            textType:'APPLETS_PAGE',   // 跳转地址类型
            pagePath:'',
            file:'',
            couponId:'',
         },
         rules:{
            mediaPath: [{ required: true, validator: validMediaPath, trigger: 'change' }],
         },
         hotelId:'',
         couponList:{},  // 优惠券
         preSaleList:{}, // 预售券
      }
   },
   mounted() {
      this.getCoupons()
      this.getPreSalePage()
      if(this.$route.query.action !== 'add') this.getEditInfo()
   },
   methods:{
      // 编辑/复制回显数据
      getEditInfo(){
         const { eventWechatDTO } = JSON.parse(sessionStorage.getItem('activityObj') || '{}')
         for (const k in this.ruleForm) k in eventWechatDTO && (this.$nextTick(()=>{
            this.ruleForm[k] = eventWechatDTO[k]
         }))
         this.$nextTick(()=>{
            this.ruleForm.mediaPath = window.getResourcesHost() + eventWechatDTO.mediaPath
            // if(this.ruleForm.textType === 'APPLETS_PAGE' || this.ruleForm.textType === 'PAGE') this.ruleForm.pagePath = eventWechatDTO.pagePath
         })
         // this.ruleForm.file // problem：回显请求参数问题
      },
      // 获取优惠券列表
      getCoupons(){
         const url = coupon.couponList
         const param = { hotelId: this.hotelId }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               let couponList = res.records
               this.couponList = couponList.map(item => {
                  return { label: item.mainTitle, value: item.id }
               })
            }
         })
      },
      // 获取预售券列表
      getPreSalePage(){
         const url = preSale.preSaleList
         let param = { hotelId: this.hotelId }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.total = res.total
               let preSaleList = res.records
               this.preSaleList = preSaleList.map(item => {
                  return { label: item.mainTitle, value: item.id }
               })
            }
         })
      },

      // 选中图片
      changeUpload(file){
         this.ruleForm.mediaPath = file.url
         this.ruleForm.file = file.raw
      },
      // 上一步
      backPage(){
         this.$emit('backPage',  this.activeNum,)
      },
      // 下一步
      submit() {
         this.$refs.ruleForm.validate(valid => {
            if (!valid) return
            this.$emit('nextStep', this.ruleForm)
         })
      },
   }

}
</script>

<style scoped lang="scss">
.operateSetcontent{
   .operateLeft{
      .img-box{
         display: inline-block;
         width: 160px;
         height: 100px;
         border: solid 1px #dddddd;
         span{
            display: block;
            width: 70%;
            height: 60px;
            line-height: 60px;
            font-size: 26px;
            text-align: center;
            color: #dddddd;
            margin: 18px auto 0;
            border: solid 1px #dddddd;
         }
      }
      .imgTips{
         padding: 10px;
         font-size: 12px;
         line-height: 15px;
         color: #999999;
         border: solid 1px #dddddd;
      }
      .upload{
         .avatar-uploader{
            line-height: 30px;
            /deep/ .el-upload-list__item{
               width: 80px; height: 80px;
            }
            /deep/ .el-upload--picture-card{
               width: 100%!important;
               height: 28px!important;
               p{
                  font-size: 12px;
                  width: 100%;
                  position: absolute;
                  line-height: 28px;
               }
               .avatar-uploader .el-upload i{
                  height: 28px!important;
                  line-height: 28px!important;
               }
            }
         }
      }

   }
   .operateRight{
      padding: 40px 0;
      text-align: center;
      height: auto;
      background-color: #f8f5ff;
      .img{
         width: 254px;
         margin: 0 auto;
         position: relative;
         .dynamic-img{
            width: 100%;
            top: 30%;
            padding: 0 10px;
            position: absolute;
            img{
               width: 100%;
            }
         }
      }
   }



   .border-top{
      border-top: 1px solid #ddd;
      padding-top: 22px;
   }
   .margin-left-70 {margin: 10px 0 0 70px;}

}


</style>
