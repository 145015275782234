export default [
   {
      path: '/shift_log',
      name: 'shiftLog',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/PMS/shiftLog/shiftLog')
      }
   }
]
