export default [
   {
      path: '/mall_transformation',
      name: 'mallTransformation',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/smtech/transformation/mallTransformation')
      }
   },{
      path: '/secondary_marketing',
      name: 'secondaryMarketing',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/smtech/transformation/secondaryMarketing')
      }
   },
]