export default [
   {
      path: '/order_manage',
      name: 'orderManage',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/business/orderManage/order/order')
      },
   }, {
      path: '/order_manage/order_detail',
      name: 'orderDetail',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/business/orderManage/order/detail')
      },
   }, {
      path: '/order_manage/deliver_goods',
      name: 'deliverGoods',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/business/orderManage/order/deliverGoods')
      },
   }, {
      path: '/business_flow',
      name: 'businessFlow',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/business/orderManage/flow/flow')
      },
   }, {
      path: '/withdrawal_record',
      name: 'withdrawalRecord',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/business/orderManage/withdrawal/withdrawal')
      },
   }, {
      path: '/hotelRes_order/:hotelRes_info',
      name: 'hotelOrder',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/business/orderManage/hotelOrder/hotelOrder')
      },
   }, {
      path: '/after_sales_center',
      name: 'afterSalesCenter',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/business/orderManage/afterSales/afterSales')
      },
   }
]
