export default [
   // {
   //    path: '/member_list',
   //    name: 'memberList',
   //    meta: { token: true },
   //    components: {
   //       sidebar: () => import('@/views/data/memberManage/memberList/list')
   //    },
   // },
   {
      path: '/member_detail',
      name: 'memberDetail',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/data/memberManage/memberList/detail')
      },
   },
   {
      path: '/growth_val',
      name: 'growthVal',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/data/memberManage/growthVal/growthVal')
      },
   }, {
      path: '/activity_conf',
      name: 'activityConf',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/data/memberManage/growthVal/activityConf')
      },
   }, {
      path: '/consume_conf',
      name: 'consumeConf',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/data/memberManage/growthVal/consumeConf')
      },
   }, {
      path: '/RFM_conf',
      name: 'RFMConf',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/data/memberManage/growthVal/RFMConf')
      },
   }, {
      path: '/growth_list',
      name: 'growthList',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/data/memberManage/growthList/growthList')
      },
   }, {
      path: '/member_user/:member_user',
      name: 'userList',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/data/memberManage/userList/userList')
      },
   }, {
      path: '/user_check',
      name: 'userCheck',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/data/memberManage/userList/userCheck')
      },
   }, {
      path: '/income_details',
      name: 'incomeDetails',
      meta: { token: true },
      components: {
         sidebar: () => import("@/views/data/memberManage/userList/Income_details")
      },
   },{
      path: '/member_rights',
      name: 'memberRights',
      components: {
         sidebar:() => import('@/views/data/memberManage/memberRights/newRights')
      },
   },{
      path: '/fission',
      name: 'fission',
      meta: { token: true },
      components: {
         sidebar:() => import("@/views/data/memberManage/fission/fission")
      },
   },{
      path:'/dataSurvey/:echartsData',
      name: 'dataSurvey',
      meta: { token: true },
      components: {
         sidebar:() => import("@/views/data/memberManage/dataSurvey/dataSurvey")
      }
   }
]