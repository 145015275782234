export default [
   {
      path: '/consume_pro',
      name: 'consumePro',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/data/memberPortrait/consumePro')
      }
   }, {
      path: '/personal_overall_por',
      name: 'personalOverallPor',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/data/memberPortrait/personalOverallPor')
      }
   },
]