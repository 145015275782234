export default [
   {
      path: '/pms/report_system',
      name: 'reportSystem',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/PMS/accommodationSetting/reportSystem/reportSystem')
      }
   }
]

