export default [
   {
      path: '/data_statistics',
      name: 'datastatistics',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/OMS/hotelHousekeeper/index/index')
      },
   },
   {
      path: '/work_order_list',
      name: 'workOrderList',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/OMS/hotelHousekeeper/workOrderManage/list/list')
      },
   }, {
      path: '/work_order_add',
      name: 'workOrderAdd',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/OMS/hotelHousekeeper/workOrderManage/list/add')
      },
   }, {
      path: '/work_order_detail',
      name: 'workOrderDetail',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/OMS/hotelHousekeeper/workOrderManage/list/detail')
      },
   }, {
      path: '/work_order_category',
      name: 'workOrderCategory',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/OMS/hotelHousekeeper/workOrderManage/category/category')
      },
   },
   {
      path: '/staff_assessment',
      name: 'staffAssessment',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/OMS/hotelHousekeeper/staffAssessment/assessment.vue')
      },
   },
   {
      path: '/edit',
      name: 'edit',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/OMS/hotelHousekeeper/staffAssessment/edit')
      },
   },
   {
      path: '/plan_tasks',
      name: 'taskList',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/OMS/hotelHousekeeper/workOrderManage/planTasks/list')
      },
   },
   {
      path: '/create_task',
      name: 'createTask',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/OMS/hotelHousekeeper/workOrderManage/planTasks/create')
      },
   }
]
