/*
* 身份证读卡控件
* */
// 创建读卡控件
let CertCtl = new IDCertCtl();

export default CertCtl

// 身份证读卡控件创建
function IDCertCtl() {
   // 创建用于与服务交换数据的对象
   this.xhr = createXmlHttp();
   this.type = "CertCtl";
   this.height = 0;
   this.width = 0;
   // 连接
   this.connect = CertCtl_connect;
   // 断开
   this.disconnect = CertCtl_disconnect;
   // 获取状态
   this.getStatus = CertCtl_getStatus;
   // 读卡
   this.readCert = CertCtl_readCert;
   // 读IC卡序列号
   this.readICCardSN = CertCtl_readICCardSN;
   // 读身份证物理卡号
   this.readIDCardSN = CertCtl_readIDCardSN;
   // 读安全模块接口
   this.getSAMID = CertCtl_getSAMID;
}

// 创建XMLHttpRequest 对象，用于在后台与服务器交换数据
function createXmlHttp() {
   var xmlHttp = null;
   //根据window.XMLHttpRequest对象是否存在使用不同的创建方式
   if (window.XMLHttpRequest) {
      xmlHttp = new XMLHttpRequest();                  //FireFox、Opera等浏览器支持的创建方式
   }/* else {
      xmlHttp = new ActiveXObject("Microsoft.XMLHTTP");//IE浏览器支持的创建方式
   }*/
   return xmlHttp;
}

//连接方法
function CertCtl_connect() {
   let result = "";
   //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
   CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/connect", false);
   //发送请求
   try {
      CertCtl.xhr.send();
   } catch (e) {
      console.log(e)
   }
   //返回值readyState   0: 请求未初始化
   //				    1: 服务器连接已建立
   //				    2：请求已接收
   //				    3: 请求处理中
   //				    4: 请求已完成，且响应已就绪
   //返回值status      200: "OK"
   //					404: 未找到页面
   //当返回值readyState为4且status为200时,为查询成功
   if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
      result = CertCtl.xhr.responseText;
      CertCtl.xhr.readyState = 1;
   }
   return result;
}

// 断开方法
function CertCtl_disconnect() {
   let result = "";
   //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
   CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/disconnect", false);
   //发送请求
   try {
      CertCtl.xhr.send();
   } catch (e) {
      console.log(e)
   }
   if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
      result = CertCtl.xhr.responseText;
      CertCtl.xhr.readyState = 1;
   }
   return result;
}

// 获取状态方法
function CertCtl_getStatus() {
   let result = "";
   //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
   CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/getStatus", false);
   //发送请求
   try {
      CertCtl.xhr.send();
   } catch (e) {
      console.log(e)
   }
   if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
      result = CertCtl.xhr.responseText;
      CertCtl.xhr.readyState = 1;
   }
   return result;
}

//执行读卡操作
function CertCtl_readCert() {
   let result = "";
   try {
      //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
      //CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/readCard", false); //readCard读卡，不生成正反面仿复印件
      CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/readCert", false);	  //readCert读卡，生成正反面仿复印件
      //发送请求
      CertCtl.xhr.send();
      if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
         result = CertCtl.xhr.responseText;
         CertCtl.xhr.readyState = 1;
      }
   } catch (e) {
      console.log(e)
   }
   return result;
}

//获取IC卡序列号
function CertCtl_readICCardSN() {
   let result = "";
   //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
   CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/readICCardSN", false);
   //发送请求
   try {
      CertCtl.xhr.send();
   } catch (e) {
      console.log(e)
   }
   if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
      result = CertCtl.xhr.responseText;
      CertCtl.xhr.readyState = 1;
   }
   return result;
}

// 获取身份证物理卡号
function CertCtl_readIDCardSN() {
   var result = "";
   //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
   CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/readIDCardSN", false);
   //发送请求
   try {
      CertCtl.xhr.send();
   } catch (e) {
      console.log(e)
   }
   if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
      result = CertCtl.xhr.responseText;
      CertCtl.xhr.readyState = 1;
   }
   return result;
}

//获取身份证物理卡号
function CertCtl_getSAMID() {
   let result = "";
   //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
   CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/getSAMID", false);
   //发送请求
   try {
      CertCtl.xhr.send();
   } catch (e) {
      console.log(e)
   }
   if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
      result = CertCtl.xhr.responseText;
      CertCtl.xhr.readyState = 1;
   }
   return result;
}
