export const system = {
   // 商户/租户列表
   companies: '/system/company/getList',
   // 商户/租户列表(分页)
   companiesPaging: '/system/company/getPage',
   // 添加商户
   addCompany: '/system/company/register',
   // 删除商户
   delCompany: '/system/company/delete',
   // 编辑商户
   editCompany: '/system/company/update',
   // 商户绑定(入驻)酒店
   bindingHotel: '/hotel/hotel-company/add',
   // 获取已绑定(入驻)商户酒店列表
   getBindingHotel: '/hotel/hotel-company/getHotelCompanyList',
   // 解绑(移除)入驻酒店
   removeHotel: '/hotel/hotel-company/remove',
   // 保存服务配置
   saveBusinessCfg: '/goods/companyCategory/add',
   // 组织列表
   org: '/system/org/getList',
   // 组织列表（分页）
   orgPaging: '/system/org/getPage',
   // 添加组织
   addOrg: '/system/org/add',
   // 删除组织
   delOrg: '/system/org/delete',
   // 编辑组织
   editOrg: '/system/org/update',
   // 角色列表(分页)
   rolePaging: '/system/role/getPage',
   // 添加角色
   AddRole: '/system/role/add',
   // 删除角色
   DelRole: '/system/role/delete',
   // 编辑角色
   editRole: '/system/role/update',
   // 菜单列表
   menus: '/system/role-menu/getAuthRoleMenus',
   // 菜单授权给用户
   authRoleMenu: '/system/role-menu/authRoleMenu',
   // 角色未授予用户
   unUserByRole: '/system/role-user/getUnUserByRoleId',
   // 角色已授予用户
   userByRole: '/system/role-user/getCheckUserByRoleId',
   // 角色未授权酒店列表
   unAuthHotelPage: '/system/role-hotel/unAuthHotelPage',
   // 角色已授权酒店列表
   authHotelPage: '/system/role-hotel/authHotelPage',
   // 保存角色授权酒店
   authRoleHotel: '/system/role-hotel/authRoleHotel',
   // 删除已授权酒店
   delRoleHotel: '/system/role-hotel/delRoleHotel',
   // 用户列表
   userList: '/system/user/getPage',
   // 删除用户
   DelUser: '/system/user/delete',
   // 添加用户
   AddUser: '/system/user/add',
   // 编辑用户
   editUser: '/system/user/update',
   // 重置用户密码
   resetPwd: '/system/account/resetPassword',
   // 未授予角色列表
   unRoleByUser: '/system/role-user/getUnRoleByUserId',
   // 已授予角色列表
   roleByUser: '/system/role-user/getCheckRoleByUserId',
   // 角色用户关联
   authRoleUser: '/system/role-user/authRoleUser',
   // 角色与用户解绑
   unBindUserRole: '/system/role-user/unBindUserRole',
   // 字典列表
   dict: '/system/dict/getList',
   // 字典列表（分页）
   dictPaging: '/system/dict/getPage',
   // 添加字典
   addDict: '/system/dict/add',
   // 删除字典
   delDict: '/system/dict/delete',
   // 编辑字典
   editDict: '/system/dict/update',
   // 字典行上下移动
   move: '/system/dict/move',
   // 字典子节点
   subDict: '/system/dict/getSubDict',
   // 菜单列表(分页)
   menusPaging: '/system/menu/getPage',
   // 菜单列表
   menu: '/system/menu/getList',
   // 添加菜单
   AddMenu: '/system/menu/add',
   // 编辑菜单
   editMenu: '/system/menu/update',
   // 删除菜单
   delMenu: '/system/menu/delete',
   // 对菜单进行排序
   menuMove: '/system/menu/move',
   // 根据用户权限获取菜单列表
   getAuthMenuByUser: '/system/role-menu/getAuthMenuByUser',
   // admin获取菜单列表
   getAllMenuByAdmin: '/system/role-menu/getAllMenuByAdmin',
   // 根据用户名获取账号信息
   getAccount: '/system/account/getAccount',
   // 用户登录日志
   loginLogPaging: '/system/loginLog/getPage',
   // 系统操作日志
   operateLogPaging: '/system/operateLog/getPage',
   // 在线用户
   getOnlineUser: '/system/account/getOnlineUser',
   // 踢用户下线
   offlineUser: '/system/account/offlineUser',
   // 获取系统配置信息
   getConfig: '/system/cfg/getList',
   // 更新系统配置
   updateConfig: '/system/cfg/update',
   // 修改手机号
   changeMobile: '/system/account/changeMobile',
   // 获取app版本列表
   appVersion: '/system/appversion/getPage',
   // 删除app版本
   delAppVersion: '/system/appversion/delete',
   // 添加app版本
   addAppVersion: '/system/appversion/add',
   // 编辑app版本
   editAppVersion: '/system/appversion/update',
   // 发布或下架App版本
   changeAppStatus: '/system/appversion/changeStatus',
   // 获取APP下载二维码
   getAppQRCode: '/system/appversion/getAppQRCode',
   // 获取国际语言列表
   getLanguages: '/system/language/getList',
   // 添加国际语言
   addLanguage: '/system/language/add',
   // 编辑国际语言
   editLanguage: '/system/language/update',
   // 删除国际语言
   delLanguage: '/system/language/delete',
   // 获取资源译文列表
   getLangResList: '/system/language/getLangResourceList',
   // 保存资源译文
   saveLangResource: '/system/language/saveLangResource',
   // 删除资源译文
   clearLangRes: '/system/language/clearLanguageResource',
   // 查询通知模板与接收人
   eventNotifyData: '/event/eventNotifyData/getPage',
   // 获取消息数
   getUnreadNotify: '/event/eventNotifyData/getUnreadNotify',
   // 通知消息设置为已读
   readNotify: "/event/eventNotifyData/readNotify",
}

/* 小程序版本 */
export const appletVersion = {
   // 获取小程序体验码
   getExperienceQrcode: '/oauth2/wechat/thirdparty/getExperienceQrcode',
   // 加急审核
   speedupAudit: '/oauth2/wechat/thirdparty/speedupAudit',
   // 查询加急次数
   queryQuota: '/oauth2/wechat/thirdparty/queryQuota',
   // 提交审核
   submitAudit: '/oauth2/wechat/thirdparty/submitAudit',
   // 更新审核状态
   updateVersion: '/hotel/hotel-cfg/updateVersion',
   // 撤回审核
   recallAudit: '/oauth2/wechat/thirdparty/recallAudit',
   // 回退版本
   rollbackRelease: '/oauth2/wechat/thirdparty/rollbackRelease',
   // 上传审核素材
   uploadMediaAudit: '/oauth2/wechat/thirdparty/uploadMediaAudit',
   // 初始化域名
   setAppletDomain: '/oauth2/wechat/thirdparty/setAppletDomain',
   // 发布小程序
   updateReleaseTime: '/oauth2/wechat/thirdparty/release',
   // 更新酒店第三方平台应用版本发布时间
   hotelupdateReleaseTime: '/hotel/hotel-cfg/updateReleaseTime',
   // 上传代码
   commitCode: '/oauth2/wechat/thirdparty/commitCode',
   // 获取小程序最新模板
   getTemplateLast: '/oauth2/wechat/thirdparty/getTemplateDraftList',
   // 获取模板id
   getminiTemplateList: '/oauth2/wechat/thirdparty/getTemplateList',
   // 将草稿添加到模板库
   addTemplate: '/oauth2/wechat/thirdparty/addTemplate',
   // 小程序插件配置
   appletPlugin: '/oauth2/wechat/thirdparty/appletPlugin',
   // 删除指定模板
   deleteTemplate: '/oauth2/wechat/thirdparty/deleteTemplate',
   // 查询接口
   queryInterface: '/oauth2/wechat/thirdparty/getPrivacyInterface',
   // 申请权限
   applyPpermission: '/oauth2/wechat/thirdparty/applyPrivacyInterface',
   // 隐私保护协议
   privacyAgreement: '/oauth2/wechat/thirdparty/setPrivacySetting',
}
