export default [
   {
      path: '/treasure_box',
      name: 'treasureBox',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/pageConfig/treasureBox')
      }
   }, {
      path: '/search_page_hot_words',
      name: 'goodsList',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/pageConfig/hotWords')
      }
   }, {
      path: '/classify_page_manage',
      name: 'goodsList',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/pageConfig/classify')
      }
   },  {
      path: '/adsense_manage',
      name: 'goodsList',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/pageConfig/adsense')
      }
   },
]
