<template>
   <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
         <el-breadcrumb-item v-for="(val, key) in crumbsObj" :key="key" :to="val">{{ key }}</el-breadcrumb-item>
      </el-breadcrumb>
   </el-row>
</template>
<script>
export default {
  props: { crumbs: Map },
  computed: {
    // Map转对象
    crumbsObj() {
      const obj = Object.create(null);
      for (const [k, v] of this.crumbs) obj[k] = v
      return obj
    }
  }
}
</script>
<style lang="scss" scoped></style>
