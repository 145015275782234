<!-- 抽屉 -->
<template>
   <el-drawer class="drawer" :visible.sync="bools" direction="rtl" :wrapperClosable="false" :before-close="close" v-if="bools">
      <div slot="title">
         <el-page-header @back="close" :content="headerTitle"></el-page-header>
      </div>
      <slot name="drawerMain"></slot>
   </el-drawer>
</template>
<!--
 <slot name="pushRule"></slot>
<template v-slot:drawerMain>
-->
<script>
export default {
   name: "drawer",
   props:{
      bool:{
         type:Boolean,
         default:false,
      },
      headerTitle:String
   },
   watch:{
      bool(){
         this.bools = this.bool
      },
   },
   data(){
      return{
         bools:false,
      }
   },

   methods:{
      // 关闭
      close(){
         this.bools = false
         this.$emit('close',this.bools )
      },
   }

}
</script>

<style scoped lang="scss">
.drawer {
   // header
   ::v-deep .el-drawer__open {
      .el-drawer__header {
         border-bottom: 1px solid #eff0f5;
         padding: 5px 20px;
         margin: 0;

         .el-page-header {
            border-bottom: 0px solid transparent
         }
      }

      .el-drawer {
         width: 36% !important;
      }
   }


}

</style>