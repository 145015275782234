import request from "@/api/service";

const api = {
   // 获取读卡器品牌
   byBrandCode: '/pms/hardwareConfig/getByBrandCode',
   add: 'pms/roomCard/add',

   // 查询入住房间信息
   roomInfo:'/pms/checkinRoom/getCheckInfo',

   // 获取色卡列表
   getConfigList: '/pms/roomStateConfig/getConfigList',
   // 保存房态配色
   saveConfig: '/pms/roomStateConfig/saveConfig',
};

export function getByBrandCode(params) {
   return request({
      url: api.byBrandCode,
      method: 'GET',
      params
   })
}

export function addRoomCard(params) {
   return request({
      url: api.add,
      method: 'POST',
      data: params
   })
}

// 查询入住房间信息
export function getRoomInfo(params) {
   return request({
      url: api.roomInfo,
      method: 'GET',
      params
   })
}

// 获取色卡列表
export function axios_getConfigList(data) {
   return request({
      url: api.getConfigList,
      method: 'POST',
      data
   })
}
// 保存房态配色
export function axios_saveConfig(data) {
   return request({
      url: api.saveConfig,
      method: 'POST',
      data
   })
}