<template>
  <div id="app">
     <router-view v-if="isRouterAlive"/>
  </div>
</template>

<script>
window.getResourcesHost = ()=>{
   let host = window.location.host
   let url = `http://${host}/resources/`
   return url
}
window.getSocketHost = ()=>{
   let host = window.location.host;
   let url = `wss://${host}/event`
   return url
}
export default {
   data(){
      return{
         isRouterAlive: true
      }
   },
   provide () {
      return {
         reload: this.reload
      }
   },
   methods: {
      reload () {
         this.isRouterAlive = false
         this.$nextTick(() => {
            this.isRouterAlive = true
         })
      }
   }
}
</script>

<style lang="scss">
   @import '@/common/scss/index.scss';
   /* 顶部导航 */
   .el-header{
      background: #087ffd;
      height: 80px!important;
      position: relative;
      padding: 0 10px;
      box-shadow: 4px 4px 6px #a9d4ff;
   }
   /* 表格缩进 */
   .not-check{
      .el-table__row td:first-of-type{
         text-indent: 25px;
      }
      .el-table__row--level-0 td,
      .el-table__row--level-1 td,
      .el-table__row--level-2 td,
      .el-table__row--level-3 td{
         text-indent: 0!important;
      }
   }
   .not-check-left{
      .flex-left{
         .el-table__row td:first-of-type{
            text-indent: 20px;
         }
         .el-table__row--level-0 td,
         .el-table__row--level-1 td,
         .el-table__row--level-2 td,
         .el-table__row--level-3 td{
            text-indent: 0!important;
         }
      }
   }
   .has-check{
      .el-table__row td:nth-child(2){
         text-indent: 20px;
      }
      .el-table__row--level-0 td,
      .el-table__row--level-1 td,
      .el-table__row--level-2 td,
      .el-table__row--level-3 td{
         text-indent: 0!important;
      }
   }
</style>
