export default [
   {
      path: '/reconciliation_statement/sales_overview',
      name: 'salesOverview',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/statement/salesOverview')
      }
   }, {
      path: '/reconciliation_statement/sales_details',
      name: 'salesDetails',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/statement/salesDetails/list')
      }
   }, {
      path: '/reconciliation_statement/sales_details/detail',
      name: 'salesDetails',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/statement/salesDetails/detail')
      }
   }, {
      path: '/reconciliation_statement/income_details',
      name: 'incomeDetails',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/statement/incomeDetails/list')
      }
   }, {
      path: '/reconciliation_statement/income_details/detail',
      name: 'incomeDetails',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/statement/incomeDetails/detail')
      }
   }
]
