export default [
  {
     path: '/pet_card_set',
     name: 'petCardSet',
     meta: { token: true },
     components: {
        sidebar:  () => import('@/views/data/petCard/petCardSet/petCardSet')
     }
  }, {
     path: '/pet_card_record',
     name: 'petCardRecord',
      meta: { token: true },
     components: {
        sidebar: () => import('@/views/data/petCard/petCardRecord/petCardRecord')
     }
  }
]