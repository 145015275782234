export default [
   {
      path: '/pms/room_business_summary',
      name: 'roomBusinessSummary',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/PMS/statistics/roomBusinessSummary')
      }
   }, {
      path: '/pms/make_a_summary',
      name: 'makeASummary',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/PMS/statistics/makeASummary')
      }
   },
   {
      path:'/pms/index',
      name:'arrivingTodaySummary',
      meta:{ token: true },
      components: {
         sidebar:() => import('@/views/PMS/index/index')
      }
   },
   {
      path:'/pms/business_summary',
      name:'businessSummary',
      meta:{ token: true },
      components: {
         sidebar:() => import('@/views/PMS/statistics/businessSummary')
      }
   },
   {
      path:'/pms/analysis_daily',
      name:'analysisDaily',
      meta:{ token: true },
      components: {
         sidebar:() => import('@/views/PMS/statistics/analysisDaily')
      }
   },
   {
      path:'/pms/business_daily',
      name:'businessDaily',
      meta:{ token: true },
      components: {
         sidebar:() => import('@/views/PMS/statistics/businessDaily')
      }
   },
   {
      path:'/pms/proceeds_summarys',
      name:'proceedsSummarys',
      meta:{token:true},
      components:{
         sidebar:() => import('@/views/PMS/statistics/proceedsSummarys')
      },
      children:[
         {
            path: '/pms/proceeds_summarys/proceeds_summary',
            name: 'proceedsSummary',
            meta: { token: true },
            components: {
               proceedsSummary: () => import('@/views/PMS/statistics/proceedsSummary')
            }
         },
         {
            path: '/pms/proceeds_summarys/proceeds_records',
            name: 'proceedsRecords',
            meta: { token: true },
            components: {
               proceedsSummary: () => import('@/views/PMS/statistics/proceedsRecords')
            }
         },
      ]
   }
]
