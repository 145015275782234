export default [
   {
      path: '/preferential_mall/time_limit_preferential',
      name: 'preferentialList',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/promotionCenter/preferential/list')
      }
   }, {
      path: '/preferential_mall/time_limit_preferential/create',
      name: 'preferentialList',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/promotionCenter/preferential/create')
      }
   }
]
