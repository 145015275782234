<template>
  <el-row class="search-item" >
    <label>所属酒店：</label>
    <el-select class="width-220" v-model="storeId" @change="date_packer(storeId)" clearable>
      <el-option v-for="item in hotellist" :key="item.key" :label="item.hotelName" :value="item.id" ></el-option>
    </el-select>
  </el-row>
</template>
<script>
import {urlObj} from "@/api/interface";
import { mapState } from 'vuex'
export default {
  props: ['storeIdQ'],
  data () {
    return {
      storeId: '',
      hotellist:[]
    }
  },
  computed: {
    ...mapState(["hotelInfo"])
  },
  mounted () {
    this.getholtel()
  },
  methods: {
    date_packer (val) {
      this.$emit('setstoreId', val)
      console.log(val)
    },
    //获取酒店列表
    getholtel(){
      const url = urlObj.getHotelslist
      const param = {
      }

      this.$axios.post(url, param, 'json').then(res => {
        if (res.success){
          this.hotellist = res.records
          this.storeId = this.hotelInfo.id
        }
      })
    },
  },
  watch: {
    storeIdQ (e) {
      if (e === '') {
        this.storeId = ''
      } else {
        this.storeId = e
        console.log(this.storeId)
      }
    }
  }
}
</script>

<style scoped>

</style>
