export default [
    {
        path: '/activity_list/:platform',
        name: 'activityList',
        meta: { token: true },
        components: {
            sidebar: () => import('@/views/smtech/operate/list')
        }
    },
    {
        path: '/create_wechat',
        name: 'createPopup',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/smtech/operate/appletsPopup/newcreatePopup')
        }
    },
    {
        path: '/create_publicaccount',
        name: 'createMass',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/smtech/operate/mass/createMass')
        }
    }, {
        path: '/create_web',
        name: 'createWeb',
        meta: { token: true },
        components: {
            sidebar: () => import('@/views/smtech/operate/webPopup/createWeb')
        }
    }, {
        path: '/create_douyin',
        name: 'createDouyin',
        meta: { token: true },
        components: {
            sidebar: () => import('@/views/smtech/operate/tiktok/createDouyin')
        }
    },{
        path: '/create_douyin_message',
        name: 'createDouyinMessage',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/smtech/operate/tiktok/createDouyinMessage')
        }
    }, {
        path: '/create_information_flow_advertising',
        name: 'createAdvert',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/smtech/operate/advert/createInformationFlowAdvertising')
        }
    }, {
        path: '/applet_analysis',
        name: 'appletAnalysis',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/smtech/operate/appletsPopup/analysis')
        }
    },
    {
        path: '/create_message',
        name: 'createMessage',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/smtech/operate/shortMessage/createMessage')
        }
    },
    {
        path: '/temPlates',
        name: 'temPlates',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/smtech/operate/shortMessage/createAtemplates')
        }
    }, {
        path: '/createa_templates',
        name: 'createAtemplates',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/smtech/operate/shortMessage/createAtemplates')
        }
    },
     {
        path: '/sms_campaign',
        name: 'smsCampaign',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/smtech/operate/shortMessage/smsCampaign')
        }
    }, {
        path: '/modify_template',
        name: 'modifyTemplate',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/smtech/operate/shortMessage/modifyTemplate')
        }
    }, {
        path: '/create_email',
        name: 'createEmail',
        meta: { token: true },
        components: {
            sidebar: () => import('@/views/smtech/operate/mail/createEmail')
        }
    }, {
        path: '/copy-mail',
        name: 'copyMail',
        meta: { token: true },
        components: {
            sidebar: () => import('@/views/smtech/operate/mail/copyMail')
        }
    }, {
        path: '/modify-mail',
        name: 'modifyMail',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/smtech/operate/mail/modifyMail')
        }
    },
   {
        path: '/short_message',
        name: 'shortMessage',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/smtech/operate/shortMessage/shortMessage')
        }
    },
    {
        path: '/mail',
        name: 'mail',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/smtech/operate/mail/mail')
        }
    }, {
        path: '/limit_seckill',
        name: 'limitSeckill',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/smtech/operate/limitSeckill/limitSeckill')
        }
    }, {
        path: '/add_seckill',
        name: 'addSeckill',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/smtech/operate/limitSeckill/addSeckill')
        }
    }
]
