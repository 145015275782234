import request from "@/api/service";

const api = {
   // 订单操作日志
   getOperateLog:'/pms/orderOperateLog/getList/',

   // 新增消费
   consumptionAdd:'/pms/consumption/add',
   // 移除消费
   consumptionDel:'/pms/consumption/removeAndSetAmount/',

   // 添加提醒
   reminderAdd:'/pms/orderReminder/add',
   // 编辑提醒
   reminderEdit:'/pms/orderReminder/modify',
   // 删除提醒
   reminderDel:'/pms/orderReminder/remove/',


   // 办理入住
   handlecheckIn:'/pms/checkinRoom/checkin',
   // 办理入住(异常订单)
   handlecheckIn2 :'/pms/nightTrialbookInfo/checkin',
   // 撤销入住
   cancelCheckIn :'/pms/checkinRoom/cancelCheckIn',
   // 撤销入住(异常订单)
   cancelCheckIn2 :'/pms/nightTrialbookInfo/cancelCheckIn',
   // 办理退房
   handlecheckOut:'/pms/checkinRoom/checkout',
   // 撤销退房
   cancelCheckout:'/pms/checkinRoom/cancelCheckout',
   // 取消预定
   cancelReserve:'/pms/checkinRoom/cancelReserve',
   // 恢复预定
   recoverReserve:'/pms/checkinRoom/recoverReserve',

   // 编辑入住人
   checkinMemberModify:'/pms/checkinMember/modify',

}


// 获取订单操作日志
export function getOperateLog(id){
   return request({
      url: api.getOperateLog + id,
      method:'get'
   })
}

// 添加消费
export function consumptionAdd(data){
   return request({
      url:api.consumptionAdd,
      method:'post',
      header:{'resoniseType':'JSON'},
      data:data ,
   })
}
// 移除消费
export function consumptionDel(id){
   return request({
      url:api.consumptionDel + id,
      method:'post',
   })
}


// 添加提醒
export function reminderAddEdit(data,state){
   return request({
      url: state === 'add' ? api.reminderAdd : api.reminderEdit,
      header:{'resoniseType':'JSON'},
      method:'post',
      data
   })
}
// 删除提醒
export function reminderDel(id){
   return request({
      url: api.reminderDel + id,
      method:'post',
   })
}

// 办理入住/退房  撤销入住/退房  取消预定
export function handlecheckInOut(data,state,abnormalOrders){
   let url = ''
   switch (state){
      case 'in':
         // url = abnormalOrders ? api.handlecheckIn2 : api.handlecheckIn
         url = api.handlecheckIn
         break;
      case 'cancelIn':
         // url = abnormalOrders ? api.cancelCheckIn2 : api.cancelCheckIn
         url = api.cancelCheckIn
         break;
      case 'out':
         url =  api.handlecheckOut
         break;
      case 'cancelOut':
         url =  api.cancelCheckout
         break;
      case 'cancelReserve':
         url =  api.cancelReserve
         break;
      case 'recoverReserve':
         url = api.recoverReserve
         break;
   }
   return request({
      url: url ,
      method:'post',
      header:{'resoniseType':'JSON'},
      data:data,
   })
}

export function  checkinMemberModify(data){
   return request({
      url: api.checkinMemberModify ,
      method:'post',
      header:{'resoniseType':'JSON'},
      data:data,
   })
}

