import zhLocale from 'element-ui/lib/locale/lang/zh-CN' // 引入element语言包
const zh_CN = {
  msg: {
    'hored_hotel': '达易住智慧酒店',
    'hored_smart_hotel': '达易住智慧酒店管理平台',
    'customer_system': '后台管理系统',
    'smart_hotel_cloud': '达易住智慧酒店云平台',
    'order_system': '订单系统',
    'login': '登录',
    'inp_login_name': '请输入登录名',
    'inp_pwd': '请输入密码',
    'ver_code': '验证码',
    'ver_code_a': '验证码：',
    'get_code': '获取验证码',
    'tab_code': '点击更换验证码',
    'change_one': '看不清？换一张',
    'forget_pwd': '忘记密码',
    'forget_pwd_a': '忘记密码？',
    'inp_mobile_num': '请输入手机号',
    'inp_new_pwd': '请输入新密码',
    'inp_confirm_pwd': '请确认新密码',
    'immed_modify': '立即修改',
    'acc_login': '帐号登录',
    'home': '首页',
    'device_manage': '设备管理',
    'room_device': '房间设备',
    'config': '配置',
    'config_name': '配置名称',
    'config_value': '配置值',
    'pms_config': 'PMS配置',
    'scene_config': '场景配置',
    'data_statistics': '数据统计',
    'added_services': '增值服务',
    'dict_manageme': '字典管理',
    'added_service': '增值服务',
    'info_manage': '消息管理',
    'operation_log': '操作日志',
    'device_alert': '设备报警',
    'customer_service': '客户服务',
    'account_manage': '帐户管理',
    'room_manage': '房间管理',
    'hotel_manage': '酒店管理',
    'division_manage': '部门管理',
    'user_manage': '用户管理',
    'authority_manage': '权限管理',
    'role_manage': '角色管理',
    'menu_manage': '菜单管理',
    'voice_manage': '语音管理',
    'small_manage': '小度管理',
    'tmall_manage': '天猫管理',
    'smart_hotel_platform': '智慧酒店管理平台',
    'modify_mobile': '修改手机号',
    'inp_old_mobile': '请输入旧手机号',
    'old_mobile_a': '旧手机号：',
    'inp_new_mobile': '请输入新手机号',
    'new_mobile_a': '新手机号：',
    'modify_pwd': '修改密码',
    'info': '信息',
    'old_pwd_a': '旧密码：',
    'inp_old_pwd': '请输入旧密码',
    'new_pwd_a': '新密码：',
    'confirm_pwd_a': '确认密码：',
    'inp_ok_pwd': '请输入确认密码',
    'exit_a': '退出',
    'select': '请选择',
    'select_hotel': '选择酒店',
    'dev_name_a': '设备名称：',
    'dev_name': '设备名称',
    'dev_type_a': '设备类型：',
    'dev_type': '设备类型',
    'dev_number_a': '设备编码：',
    'dev_number': '设备编码',
    'dev_status_a': '设备状态：',
    'dev_status': '设备状态',
    'floor_room_a': '楼层房间：',
    'floor_room': '楼层房间',
    'install_location_a': '安装位置：',
    'install_location': '安装位置',
    'dev_brand': '品牌',
    'dev_brand_a': '品牌：',
    'gateway': '网关',
    'hotel_a': '酒店：',
    'floor_a': '楼层：',
    'floor': '楼层',
    'floors': '层',
    'room_icon_a': '图标：',
    'room_icon': '图标',
    'font_icon': '字体图标',
    'upload_icon': '上传图标',
    'prefix': '前缀',
    'starting_room_num': '开始房号',
    'cutoff_room_num': '截止房号',
    'suffix': '后缀',
    'room_num_a': '房间号：',
    'room_num': '房间号',
    'room_number_a': '房间编号：',
    'room_number': '房间编号',
    'roomtype_name_a': '房型名称：',
    'roomtype_name': '房型名称',
    'roomtype_code_a': '房型编码：',
    'roomtype_code': '房型编码',
    'room_name_code': '名称/编码',
    'facilities_name_a': '设施名称：',
    'facilities_name': '设施名称',
    'facilities_code_a': '设施编码：',
    'facilities_code': '设施编码',
    'categories_name_a': '分类名称：',
    'categories_name': '分类名称',
    'categories_code_a': '分类编码：',
    'categories_code': '分类编码',
    'node_type_a': '节点类型：',
    'node_type': '节点类型',
    'whether_with_a': '是否带电池：',
    'whether_with': '是否带电池',
    'topic_prefix_a': 'Topic前缀：',
    'topic_prefix': 'Topic前缀',
    'topic_info': '请输入您的Topic类名',
    'product_name_a': '产品名称：',
    'product_name': '产品名称',
    'product_code_a': '产品编码：',
    'product_code': '产品编码',
    'product_key_a': '产品Key：',
    'product_key': '产品Key',
    'product_secret_a': '产品秘钥：',
    'product_secret': '产品秘钥',
    'add_facilities': '新建设施',
    'edit_facilities': '编辑设施',
    'add_categories': '新建分类',
    'edit_categories': '编辑分类',
    'add_product': '新建产品',
    'edit_product': '编辑产品',
    'product_detail': '产品详情',
    'photo_a': '照片：',
    'photo': '照片',
    'category_a': '所属分类：',
    'category': '所属分类',
    'product_image_a': '产品图片：',
    'product_image': '产品图片',
    'format_a': '规格：',
    'format': '规格',
    'product_format_a': '产品规格：',
    'product_format': '产品规格',
    'product_number_a': '产品型号：',
    'product_number': '产品型号',
    'product_description_a': '型号描述：',
    'product_description': '型号描述',
    'start_room_num_a': '开始房间号：',
    'start_room_num': '开始房间号',
    'end_room_num_a': '结束房间号：',
    'end_room_num': '结束房间号',
    'online_status_a': '在线状态：',
    'mac_address': 'MAC地址',
    'mac_address_a': 'MAC地址：',
    'search': '搜索',
    'reset': '重置',
    'number': '序号',
    'logo': 'LOGO',
    'time': '时间',
    'time_starting': '开始时间',
    'time_end': '结束时间',
    'own_hotel': '所属酒店',
    'own_hotel_a': '所属酒店：',
    'remarks_location': '备注位置',
    'remarks_location_a': '备注位置：',
    'unlock_status': '是否锁定',
    'unlock_status_a': '是否锁定：',
    'sync_status': '是否同步',
    'sync_status_a': '是否同步：',
    'no_sync': '未同步',
    'add_gateway': '新增中 - 待网关回复',
    'edit_sync': '已修改 - 未同步',
    'edit_gateway': '修改中 - 待网关回复',
    'del_gateway': '删除中 - 待网关回复',
    'sync_success': '同步成功',
    'sync_failure': '同步失败',
    'oper_status': '操作状态',
    'oper_status_a': '操作状态：',
    'status': '状态',
    'status_a': '状态：',
    'online': '在线',
    'offline': '离线',
    'inactivated': '未激活',
    'hour': '小时',
    'last_online_time': '最后在线时间',
    'last_online_time_a': '最后在线时间：',
    'last_offline_time': '最后离线时间：',
    'last_offline_time_a': '最后离线时间：',
    'operating': '操作',
    'operating_a': '操作：',
    'more_status': '更多状态',
    'status_list': '状态列表',
    'infrared_list': '红外宝指令列表',
    'key_name': '按键名称',
    'key_val': '按键值',
    'result_cmd': '发送指令',
    'time_limit_a': '时间范围：',
    'detail': '详情',
    'more': '更多',
    'add': '添加',
    'disable': '禁用',
    'enable': '启用',
    'remove': '删除',
    'establish':'创建',
    'sync_gateway': '同步网关',
    'all_delete': '批量删除',
    'all_locking': '批量锁定',
    'all_unlocking': '批量解锁',
    'instruction': '指令',
    'instruction_a': '指令：',
    'edit': '编辑',
    'edit_a': '编辑：',
    'copy': '复制',
    'clone': '克隆',
    'fuzzy_copy': '模糊复制',
    'open': '打开',
    'close': '关闭',
    'Pleaseselectstatus':'请选择状态',
    'see':'查看',
    'Workordertype':'工单类型',
    'Department':'所属部门',
    'contactinformation':'联系方式',
    'personincharge':'负责人',
    'Jobtitle':'工单标题',
    'Serial':'序号',
    'open_close': '开启/关闭',
    'manipulation': '操控',
    'sync_dev_a': '是否同步子设备：',
    'gateway_sync': '是否同步子设备的楼层，房间号：',
    'curtain_open_close_socket': '窗帘电机 > 开启/关闭',
    'wifi_open_close_socket': 'WIFI插座 > 开启/关闭',
    'wifi_open_close_tip': 'WIFI温控面板 > 开启/关闭',
    'auto_close_time_a': '自动关闭时间：',
    'auto_close_hover_tip': '自动关闭时间，若在设置的时间内有客人进入房间，则设置的自动关闭时间将失效;若超过设置的时间，房间没有客人进入，该设备将自动关闭。',
    'auto_close_tip': '选择自动关闭时间后，不可更改；时间设置，仅本次有效。',
    'dev_alias_tip': '设备别名，将会在配置场景时使用到，若同一位置出现2个同一类型设备，系统无法识别；出现此情况，将以设备别名来区分设备！',
    'dev_location_tip': '设备位置，将会在配置场景时使用到，若不填写位置，场景配置可能无效。',
    'unlock': '开锁',
    'lock': '关锁',
    'child_dev': '子设备',
    'wake': '一键唤醒',
    'dev_detail': '设备详情',
    'dev_info': '设备信息',
    'trigger_dev_list': '触发设备列表',
    'oftrigger_dev_list': '被触发设备列表',
    'scene_photo': '场景照片',
    'scene_info': '场景信息',
    'gat_info': '网关信息',
    'build_unit': '所属楼栋/单元',
    'build_unit_a': '所属楼栋/单元：',
    'create_terminal': '创建终端',
    'create_terminal_a': '创建终端：',
    'sync_gateway_a': '同步网关状态：',
    'dev_buildUnit': '设备所属楼栋/单元',
    'dev_buildUnit_a': '设备所属楼栋/单元：',
    'owned_floor_a': '所属楼层：',
    'belong_floorRoom_a': '所属楼层/房间：',
    'own_gateway_a': '所属网关：',
    'gateway_version_a': '网关版本：',
    'dev_model_a': '设备型号：',
     'dev_model': '设备型号',
    'dev_product_a': '所属产品：',
    'dev_key_a': '设备Key：',
    'dev_secret_a': '设备秘钥：',
    'agreement_type_a': '协议类型：',
    'internet_time': '入网时间：',
    'push_messages_a': '是否推送消息：',
    'panel_brightness_a': '面板亮度：',
    'cur_direction_a': '窗帘转动方向：',
    'whe_normal_a': '电量是否正常：',
    'whe_power_a': '是否推送电量：',
    'device_list': '设备按键列表',
    'key_position': '按键位置',
    'key_alias': '按键别名',
    'key_status': '按键状态',
    'key_type': '按键类型',
    'whe_connect': '是否接灯',
    'control_list': '温控面板参数列表',
    'mode': '模式',
    'heating': '制热',
    'refrigeration': '制冷',
    'low': '低',
    'middle': '中',
    'high': '高',
    'auto': '自动',
    'switch': '开关',
    'scene': '场景',
    'switch_statusr': '开关状态',
    'lock_status': '童锁状态',
    'current_temperature': '当前温度',
    'target_temperature': '目标温度',
    'wind_speed': '风速',
    'time_on_time': '定时开启时间',
    'time_off': '定时关闭时间',
    'reporting_time': '上报时间',
    'baozi_equipment': '红外宝子设备',
    'brand_name': '品牌名称',
    'brand_id': '品牌编号',
    'brand_model': '品牌型号',
    'location': '位置',
    'location_a': '位置：',
    'alias': '别名',
    'first': '一位',
    'second': '二位',
    'third': '三位',
    'fourth': '四位',
    'first_alias': '第一位别名',
    'second_alias': '第二位别名',
    'third_alias': '第三位别名',
    'fourth_alias': '第四位别名',
    'first_alias_a': '第一位别名：',
    'second_alias_a': '第二位别名：',
    'third_alias_a': '第三位别名：',
    'fourth_alias_a': '第四位别名：',
    'first_status_a': '第一位状态：',
    'second_status_a': '第二位状态：',
    'third_status_a': '第三位状态：',
    'fourth_status_a': '第四位状态：',
    'first_switch_a': '第一位是否接灯：',
    'second_switch_a': '第二位是否接灯：',
    'third_switch_a': '第三位是否接灯：',
    'fourth_switch_a': '第四位是否接灯：',
    'normal_room': '正常房间',
    'abnormal_room': '异常房间',
    'all_room': '所有房间',
    'no_data': '暂无数据',
    'create_type': '创建类型',
    'create_type_a': '创建类型：',
    'amount': '数量',
    'total_nubmer': '设备总数量',
    'return': '返回',
    'previous': '上一步',
    'dev_alias': '设备别名',
    'dev_alias_a': '设备别名：',
    'dev_location': '设备位置',
    'dev_location_a': '设备位置：',
    'room_list': '房间列表',
    'room_detail': '房间详情',
    'curr_room_a': '当前房间号：',
    'edit_device': '编辑设备',
    'change_status': '更改状态',
    'dict_type_a': '字典类型：',
    'dict_val': '字典值',
    'dict_cn_val': '字典值(中文)',
    'dict_val_a': '字典值：',
    'dict_en_val': '字典值(英文)',
    'dict_en_val_a': '英文值：',
    'add_dict': '新建字典',
    'dict_type': '字典类型',
    'dict_attr': '字典属性',
    'dict_attr_a': '字典属性：',
    'alarm_s': '报警',
    'action': '动作',
    'open_s': '开',
    'close_s': '关',
    'open_close_s': '开,关',
    'dict_code': '字典码',
    'edit_dict': '编辑字典',
    'create_time': '创建时间',
    'update_time': '修改时间',
    'create_time_a': '创建时间：',
    'scene_model': '场景模式',
    'control_panel': '温控面板',
    'control_off_time_a': '延时执行时间：',
    'control_unit_second_a': '(单位/分钟)',
    'control_panel_txt': '当温控面板开启后，如果在以下设定的时间内，所选择产品没一个被触发，那就将这个温控面板关闭。',
    'new_scene_explain_txt': '触发设备、被执行设备会根据设备位置、设备类型进行查找，可能会匹配到一个及以上的设备。建议配置完成后，进入详情查看，不需要加入到场景的设备，可以在编辑功能里去掉该设备。',
    'edit_scene_explain_txt': '触发设备、被执行设备会根据设备的MAC匹配。建议配置完成后，进入详情查看，不需要加入到场景的设备，可以在编辑功能里去掉该设备。',
    'copy_scene_explain_txt': '复制场景功能，会根据该房间的设备位置、设备类型进行查找，可能会匹配到零个及以上的设备。建议配置完成后，进入详情查看，不需要加入到场景的设备，可以在编辑功能里去掉该设备。',
    'copy_scene_explain_txts': '复制场景功能，会根据当前房间所在酒店，房间的类型，触发设备类型，以及触发设备在房间所处的位置来查找目标房间   ，符合条件的房间会被查询出来。建议配置完成后，进入详情查看，不需要加入到场景的设备，可以在编辑功能里去掉该设备。',
    'edit_hotel_floor_txt': '酒店楼层修改后，之前创建的房间及设备所属楼层也需要手动去修改，否则显示的楼层将对应不上。',
    'add_scene': '新建场景',
    'add_scene_a': '新建场景：',
    'scene_edit_scene_model': '新建场景 > 编辑场景模式',
    'scene_model_chinese': '场景模式(中文)',
    'scene_model_english': '场景模式(英文)',
    'add_scene_add_execute_dev': '新建场景 > 添加被执行设备',
    'add_scene_add_dev': '新建场景 > 添加设备',
    'select_location_a': '选择位置：',
    'device_property': '设备属性',
    'device_property_a': '设备属性：',
    'new_scene_add_trigger_dev': '新建场景 > 添加触发设备',
    'new_scene_add_app_room': '新建场景 > 添加应用房间',
    'copy_scene_app_info': '复制场景 > 复制结果提示',
    'copy_scene_success_room': '复制成功房间',
    'copy_scene_failure_room': '复制失败房间',
    'copy_know_the': '我知道了',
    'copy_scene': '复制场景',
    'copy_scene_a': '一键场景复制',
    'edit_scene_edit_execute_dev': '编辑场景 > 编辑执行设备',
    'edit_scene_edit_trigger_dev': '编辑场景 > 编辑触发设备',
    'select_trigger_dev_attr': '选择触发设备及属性',
    'confirm_trigger_dev_attr': '确认触发设备及属性',
    'select_execute_dev_attr': '选择执行设备及属性',
    'confirm_execute_dev_attr': '确认执行设备及属性',
    'select_execute_dev_config': '选择设备及设备配置',
    'confirm_execute_dev_config': '确认设备及设备配置',
    'device_config': '设备配置',
    'room_no_device': '该房间没有设备！',
    'all_select': '全选',
    'customize': '自定义',
    'customize_a': '自定义：',
    'customize_model': '自定义模式',
    'start_upload': '开始上传',
    'save': '保存',
    'cancel': '取消',
    'trigger_device': '触发设备',
    'trigger_condition_a': '触发条件：',
    'perform_an_action_a': '执行动作：',
    'trigger_device_a': '触发设备：',
    'trigger_dev_explain': '触发设备作为一个场景执行的主动设备，如：欢迎模式，门磁感应器感应到人进入房间，房间内的走廊灯打开，门磁感应器作为触发设备。',
    'executed_device': '被执行设备',
    'executed_device_a': '被执行设备：',
    'execute_on': '执行条件',
    'triggered_condition': '被触发条件',
    'valid': '有效',
    'invalid': '无效',
    'are_unlocked': '已锁定',
    'not_unlock': '未锁定',
    'scene_detail': '场景详情',
    'detail_info': '详情信息',
    'sync_info': '同步信息',
    'sync_device_a': '同步设备：',
    'app_scenario_a': '应用场景：',
    'support_actions_a': '支持动作：',
    'device_info': '设备信息',
    'basic_info': '基本信息',
    'permission_info': '权限信息',
    'application_room_a': '应用房间：',
    'please_add_app_room': '请添加应用房间',
    'look_scenes_list': '查看场景列表',
    'room_scenes_list': '样板房场景列表',
    'room_dev_list': '房间设备列表',
    'select_tg_dev': '已选触发设备',
    'select_tger_dev': '已选被触发设备',
    'execute_dev': '执行设备',
    'execute_dev_a': '执行设备：',
    'execute_term_a': '执行条件：',
    'execute_dev_explain': '被执行设备作为一个场景执行的被动设备，如：欢迎模式，门磁感应器感应到人进入房间，房间内的走廊灯打开，走廊灯打开作为被执行设备。',
    'trigger_term_a': '被触发条件：',
    'trigger_dev': '被触发设备',
    'trigger_dev_a': '被触发设备：',
    'example_room': '样板房：',
    'target_room_a': '目标房间：',
    'target_room': '目标房间',
    'copy_deit_dExamp': '一键场景复制 > 样板房编辑',
    'copy_add_dExamp': '一键场景复制 > 目标房间添加',
    'copy_matching_test': '一键场景复制 > 匹配测试',
    'dExamp_name_a': '样板房名称：',
    'dExamp_name': '样板房名称',
    'dExamp_id_a': '样板房编号：',
    'dExamp_id': '样板房编号',
    'current_dExamp': '当前样板房：',
    'add_dExamp': '添加样板房',
    'edit_dExamp': '编辑样板房',
    'sel_target_room': '目标房间选择',
    'res_match_test': '匹配测试结果',
    'sample_room': '样板房',
    'target_rooms': '目标房',
    'matching': '匹配',
    'matching_no': '不匹配',
    'copy_success': '复制成功',
    'copy_failure': '复制失败',
    'matching_results_a': '匹配结果：',
    'matching_results': '匹配结果',
    'copy_the_result_a': '复制结果：',
    'copy_the_result': '复制结果',
    'to_match': '重新匹配',
    'to_loading': '数据正在匹配中...',
    'matching_test': '匹配测试',
    'begin_to_copy': '开始复制',
    'scene_mode': '场景模式',
    'scene_mode_a': '场景模式：',
    'scene_off_time_a': '场景关闭时间：',
    'scene_off_times': '秒',
    'scene_off_delayed_a': '场景延时时间：',
    'scene_off_time_explain': '场景关闭时间，如客人进入卫生间，红外感应器感应到人，把配置的所有灯光打开，客人离开卫生间后，红外感应器多少时间感应不到人，关闭所有灯光。',
    'scene_off_delay_explain': '场景延时时间，如客人进入卫生间，门磁感应器感应到人后，延时十秒打开房间的灯光、窗帘等场景。',
    'edit_scene': '编辑场景',
    'edit_info': '编辑信息',
    'effective_a': '是否生效：',
    'whe_applets_a': '是否同步小程序：',
    'whe_voice_a': '是否同步语音：',
    'effective_explain': '若选中生效，配置完场景完后，该场景立马生效。',
    'yes': '是',
    'no': '否',
    'positive': '正',
    'anti': '反',
    'normal': '正常',
    'abnormal': '异常',
    'remark': '备注',
     'remark_a': '备注：',
    'remarks': '备注信息',
    'remarks_a': '备注信息：',
    'inp_remark_info': '请输入备注信息',
    'miniprogram_a': '小程序菜单授权：',
    'miniprogram': '小程序菜单授权',
    'select_sys_a': '选择系统：',
    'type': '类型',
    'type_a': '类型：',
    'user_type': '用户类型',
    'user_type_a': '用户类型：',
    'log_type': '日志类型',
    'log_type_a': '日志类型：',
    'to': '至',
    'start_date': '开始日期',
    'end_date': '结束日期',
    'user_or_mobile': '用户名/手机号',
    'operate_info': '操作信息',
    'operate_time': '操作时间',
    'operate_time_a': '操作时间：',
    'alarm_time': '报警时间',
    'alarm_time_a': '报警时间：',
    'alarm_msg': '报警消息',
    'msg_content': '消息内容',
    'info_time': '信息时间',
    'service_type': '服务类型',
    'service_type_a': '服务类型：',
    'data_type_a': '数据类型：',
    'phone_number': '手机号码',
    'phone_number_a': '手机号码：',
    'user_name': '用户名',
    'user_name_a': '用户名：',
    'start_time': '发起时间',
    'province': '省份',
    'province_a': '省份：',
    'city': '城市',
    'city_a': '城市：',
    'area': '区/县',
    'area_a': '区/县：',
    'new_hotel': '新建酒店',
    'superior_hotel': '上级酒店',
    'superior_hotel_a': '上级酒店：',
    'hotel_basic_info': '酒店基本信息',
    'hotel_basic_info_a': '酒店基本信息：',
    'hotel_affiliation': '所属单位',
    'hotel_affiliation_a': '所属单位：',
    'hotel_type': '酒店类型',
    'hotel_type_a': '酒店类型：',
    'hotel_logo': '酒店LOGO',
    'hotel_logo_a': '酒店LOGO：',
    'hotel_info': '酒店详细信息',
    'hotel_star': '酒店星级',
    'hotel_star_a': '酒店星级：',
    'hotel_area': '酒店面积',
    'hotel_area_a': '酒店面积：',
    'hotel_booking': '最多预订天数',
    'hotel_booking_a': '最多预订天数：',
    'hotel_in_time': '入住时间',
    'hotel_in_time_a': '入住时间：',
    'hotel_breakfast_time': '早餐时间',
    'hotel_breakfast_time_a': '早餐时间：',
    'hotel_out_time': '退房时间',
    'hotel_out_time_a': '退房时间：',
    'hotel_reception_phone': '前台电话',
    'hotel_reception_phone_a': '前台电话：',
    'hotel_website': '酒店官网',
    'hotel_website_a': '酒店官网：',
    'hotel_notice': '入住须知',
    'hotel_notice_a': '入住须知：',
    'hotel_introduction': '酒店介绍',
    'hotel_introduction_a': '酒店介绍：',
    'hotel_geolocation': '地理位置',
    'hotel_geolocation_a': '地理位置：',
    'hotel_room_type': '酒店房型',
    'hotel_room_type_a': '酒店房型：',
    'hotel_facilities': '酒店设施',
    'hotel_facilities_a': '酒店设施：',
    'hotel_applet': '小程序选项卡权限',
    'hotel_applet_a': '小程序选项卡权限：',
    'hotel_name_code': '酒店名称/酒店编码',
    'hotel_name': '酒店名称',
    'hotel_name_a': '酒店名称：',
    'hotel_alias': '酒店别名',
    'hotel_alias_a': '酒店别名：',
    'pms_config_all': 'PMS参数配置项',
    'pms_city': 'PMS选择',
    'pms_city_a': 'PMS选择：',
    'pms_hotel_code': 'PMS酒店编码',
    'pms_hotel_code_a': 'PMS酒店编码：',
    'pms_name': 'PMS名称',
    'pms_name_a': 'PMS名称：',
    'pms_code': 'PMS编码',
    'pms_code_a': 'PMS编码：',
    'pms_logo': 'PMS LOGO',
    'pms_logo_a': 'PMS LOGO：',
    'service_name': '服务商名称',
    'service_name_a': '服务商名称：',
    'contact_person': '联系人',
    'contact_person_a': '联系人：',
    'e_mail': '电子邮箱',
    'e_mail_a': '电子邮箱：',
    'add_pms': '新建PMS',
    'edit_pms': '编辑PMS',
    'hotel_code': '酒店编码',
    'hotel_code_a': '酒店编码：',
    'hotel_phone': '酒店电话',
    'hotel_phone_a': '酒店电话：',
    'start_floor': '开始楼层',
    'start_floor_a': '开始楼层：',
    'end_floor': '结束楼层',
    'end_floor_a': '结束楼层：',
    'mailbox': '邮箱',
    'mailbox_a': '邮箱：',
    'area_code': '区号',
    'fax_number': '传真号',
    'regional_location_a': '区域位置：',
    'subdistrict_office': '街道办/具体到楼栋房号',
    'fax_address': '传真地址',
    'fax_address_a': '传真地址：',
    'detail_address': '详细地址',
    'detail_address_a': '详细地址：',
    'hotel_detail': '酒店详情',
    'province_city_area_a': '省市区：',
    'edit_hotel': '编辑酒店',
    'dept_name': '部门名称',
    'dept_name_a': '部门名称：',
    'add_dept': '新建部门',
    'dept_detail': '部门详情',
    'superior_dept': '上级部门',
    'superior_dept_a': '上级部门：',
    'contact_phone': '联系电话',
    'contact_phone_a': '联系电话：',
    'principal': '主负责人',
    'principal_a': '主负责人：',
    'vice_principal': '副负责人',
    'vice_principal_a': '副负责人：',
    'contact_mailbox': '联系邮箱',
    'contact_mailbox_a': '联系邮箱：',
    'contact_address': '联系地址',
    'contact_address_a': '联系地址：',
    'affiliation': '所属单位',
    'affiliation_a': '所属单位：',
    'phone': '电话',
    'add_time': '添加时间',
    'add_time_a': '添加时间：',
    'user_source': '用户来源',
    'user_source_a': '用户来源：',
    'temporary_visit': '临时访问',
    'member_user': '会员用户',
    'addWway': '新增方式',
    'addWway_a': '新增方式：',
    'roomtype_one': '一间',
    'roomtype_batch': '批量',
    'roomtype': '房型',
    'roomtype_a': '房型：',
    'room_type_a': '房间类型：',
    'room_type': '房间类型',
    'room_status_a': '房间设备状态：',
    'room_status': '房间设备状态',
    'building_unit': '楼栋/单元',
    'building_unit_a': '楼栋/单元：',
    'building_add': '添加楼栋',
    'building_edit': '编辑楼栋',
    'building_manage': '楼栋管理',
    'building_name_a': '楼栋名称：',
    'building_name': '楼栋名称',
    'building_number_a': '楼栋编号：',
    'building_number': '楼栋编号',
    'build_time_a': '修建时间：',
    'build_time': '修建时间',
    'build_number_a': '楼层数：',
    'build_number': '楼层数',
    'build_no': '地上层数',
    'build_under': '地下层数',
    'build_towards_a': '朝向：',
    'build_towards': '朝向',
    'unit_add': '添加单元',
    'unit_edit': '编辑单元',
    'unit_manage': '单元管理',
    'unit_name_a': '单元名称：',
    'unit_name': '单元名称',
    'unit_number_a': '单元编号：',
    'unit_number': '单元编号',
    'unit_build': '所属楼栋',
    'room_type_manage': '房型管理',
    'add_Room': '添加房间类型',
    'room_type_cn_a': '房间类型(中文)：',
    'room_type_en_a': '房间类型(英文)：',
    'room_type_cn': '房间类型(中文)',
    'room_type_en': '房间类型(英文)',
    'room_type_photo_edit': '房型照片编辑',
    'room_type_config': '房间类型配置',
    'room_build_config': '楼栋管理配置',
    'default_room_type_a': '默认房间类型：',
    'customize_room_type_a': '自定义房间类型：',
    'add_check_in': '模拟入住',
    'add_room': '新建房间',
    'add_room_type': '新建房型',
    'room_floor_a': '房间楼层：',
    'batch_add_room': '批量建房间',
    'special_note_a': '特别说明：',
    'special_note_txt': '批量新建房间号此功能，开始房间号、结束房间号只支持数字，不支持带字母或其他；递增数为1。',
    'hotel_room_type_txt': '如果只填写中文，切换到英文时将会匹配不到英文。',
    'chinese': '中文',
    'english': '英文',
    'floor_num': '楼层',
    'edit_room': '编辑房间',
    'edit_room_type': '编辑房型',
    'dept_a': '部门：',
    'login_name_mobile': '登录名 / 姓名 / 手机号',
    'add_user': '新建用户',
    'reset_password': '重置密码',
    'login_name': '登录名',
    'login_name_a': '登录名：',
    'password': '密码',
    'password_a': '密码：',
    'name': '姓名',
    'name_a': '姓名：',
    'phone_num_a': '手机号：',
    'phone_num': '手机号',
    'ownership_hotel_a': '归属酒店：',
    'ownership_dept_a': '归属部门：',
    'job_num': '工号',
    'job_num_a': '工号：',
    'landline': '座机',
    'landline_a': '座机：',
    'user_detail': '用户详情',
    'edit_user': '编辑用户',
    'edit_user_reset_password': '编辑用户 > 重置密码',
    'voice_wizard': '语音精灵',
    'tmall_elf': '天猫精灵',
    'small_degree': '小度',
    'alexa': 'Alexa',
    'amazon_speaker': '亚马逊智能语音响',
    'google_speaker': '谷歌智能语音响',
    'voice_device': '语音设备',
    'voice_device_a': '语音设备：',
    'add_voice': '添加语音设备',
    'edit_voice': '编辑语音设备',
    'voice_txt': '填写的语音序列号跟语音官方网站激活的序列号保持一致，若不一致将无法控制智能设备。',
    'small_txt': '填写的小度序列号跟小度官方网站激活的序列号保持一致，若不一致将无法控制智能设备。',
    'add_small': '添加小度',
    'device_serial_number': '设备序列号',
    'device_serial_room': '设备序列号/房间号',
    'device_version': '设备版本',
    'device_version_a': '设备版本：',
    'device_room': '所属房间',
    'device_room_a': '所属房间：',
    'bind_user': '绑定用户',
    'bind_user_a': '绑定用户：',
    'bind_username': '绑定用户名',
    'bind_username_a': '绑定用户名：',
    'device_openId': '设备openId',
    'device_openId_a': '设备openId：',
    'hotel_version': '酒店版本',
    'home_version': '家庭版本',
    'serial_number': '序列号',
    'serial_number_a': '序列号：',
    'edit_small': '编辑小度',
    'add_tmall': '添加天猫',
    'edit_tmall': '编辑天猫',
    'tmall_type_a': '天猫类型：',
    'own_role': '所属角色',
    'own_role_a': '所属角色：',
    'login_app_permission_a': '登录APP权限：',
    'control_sys_menu_permission_a': '客控系统菜单权限：',
    'order_sys_menu_permission_a': '预订系统菜单权限：',
    'customer_sys': '客控系统',
    'order_sys': '预订系统',
    'sence_sync': '场景同步终端',
    'small_program': '小程序',
    'intelligent_voice': '智能语音',
    'role_name_ce': '角色名称/英文名称',
    'role_name_code': '角色名称/编码',
    'role_name': '角色名称',
    'role_name_en': '英文名称',
    'role_name_a': '角色名称：',
    'role_name_a_en': '英文名称：',
    'add_role': '新建角色',
    'no_admin': '是否管理员',
    'no_admin_a': '是否是管理员：',
    'available': '是否可用',
    'available_a': '是否可用：',
    'edit_role': '编辑角色',
    'allocatioin_role': '分配角色',
    'user_a': '用户：',
    'allocatioin_a': '角色：',
    'hint_a': '提示：',
    'role_tip_txt': '只有角色为 酒店管理员、酒店运维员 才能登录APP。',
    'assign_permissions': '分配权限',
    'menu_name': '菜单名称',
    'menu_name_a': '菜单名称：',
    'menu_name_code': '菜单名称/编码',
    'english_name': '菜单名称(英文)',
    'english_name_a': '英文名称：',
    'menu_name_en': '菜单名称(英文)',
    'menu_name_en_a': '菜单名称(英文)：',
    'new_menu': '新建菜单',
    'menu_link': '菜单链接',
    'menu_link_a': '菜单链接：',
    'sort': '排序',
    'sort_a': '排序：',
    'no_show': '是否显示',
    'no_show_a': '是否显示：',
    'menu_detail': '菜单详情',
    'menu_permission': '菜单权限',
    'menu_permission_a': '菜单权限：',
    'superior_menu': '上级菜单：',
    'affiliated_system_a': '所属系统：',
    'edit_menu': '编辑菜单',
    'company_name': '单位名称',
     'company_name_a': '单位名称：',
    'company_code': '单位编码',
     'company_name_credit_code': '单位名称/编码/信用代码',
    'company_nature': '单位性质',
     'company_nature_a': '单位性质：',
    'industry': '所属行业',
    'social_credit_code': '统一社会信用代码',
    'legal_person': '法人代表',
     'legal_person_a': '法人代表：',
    'company_number': '单位号码',
    'company_email': '单位邮箱',
     'company_email_a': '单位邮箱：',
     'register_time': '注册时间',
    'new_company': '新增单位',
    'org_name': '组织名称',
    'org_name_a': '组织名称：',
    'org_name_code': '组织名称/编码',
    'org_coding': '组织编码',
    'org_coding_a': '组织编码：',
    'org_type': '组织类型',
    'org_type_a': '组织类型：',
    'tenant_store': '租户/商户',
    'tenant_store_a': '租户/商户：',
    'add_tenant': '添加租户',
    'add_store': '添加商户',
    'edit_tenant': '编辑租户',
    'edit_store': '编辑商户',
    'role_encoding': '角色编码',
    'role_encoding_a': '角色编码：',
    'affiliated_org': '所属组织',
    'org_list': '组织列表',
    'head_portrait': '头像',
    'head_portrait_a': '头像：',
    'nickname': '昵称',
    'nickname_a': '昵称：',
    'gender': '性别',
    'gender_a': '性别：',
    'tenantMerchantList': '租户/商户列表',
    'client_a': '客户端：',
    'new_dict': '新增字典',
    'dict_name': '字典名称',
    'dict_name_a': '字典名称：',
    'dict_encoding': '字典编码',
    'dict_encoding_a': '字典编码：',
    'hidden_values': '隐藏值',
    'hidden_values_a': '隐藏值：',
    'display_value': '显示值',
    'display_value_a': '显示值：',
    'dict_color': '字典颜色',
    'dict_color_a': '字典颜色：',
    'sort_number': '排序号',
    'is_init': '是否初始化',
    'is_it_hidden': '是否隐藏',
    'is_it_hidden_a': '是否隐藏：',
    'dict_icon_a': '字典图标：',
    'dict_icon': '字典图标',
    'parent_dict': '所属父字典：',
     'tenant_name': '租户名称',
    'tenant_name_a': '租户名称：',
    'store_name_a': '商户名称：',
    'is_it_effective_a': '是否有效：',
    'menu_code': '菜单编码',
    'menu_code_a': '菜单编码：',
    'menu_path': '菜单路径',
    'client': '客户端',
    'parent_menu_a': '所属父菜单：',
    'menu_icon': '菜单图标',
    'menu_icon_a': '菜单图标：',
    'is_it_enabled_a': '是否启用：',
    'menu_type': '菜单类型',
    'member': '会员',
     'member_name_nickname_a': '会员名称/昵称：',
     'member_name': '会员名称',
     'member_name_a': '会员名称：',
    'member_a': '会员：',
    'add_member': '新增会员',
     'date_of_birth': '出生日期',
    'date_of_birth_a': '出生日期：',
    'document_type_a': '证件类型：',
     'identification_number': '证件号码',
    'identification_number_a': '证件号码：',
    'nationality_a': '国籍：',
     'administrative_area': '省/市/区(县)',
    'administrative_area_a': '省/市/区(县)：',
    'menu_resource_grant_role': '菜单资源授予角色',
    'roles_granted_to_users': '角色授予用户',
    'menu_list': '菜单列表',
    'resource_list': '资源列表',
    'no_user_selected': '未选择用户',
    'user_selected': '已选择用户',
    'select_role': '请选择参与的角色',
    'role_list': '角色列表',
    'selected_roles': '已选角色',
     'next_step': '下一步',
     'define': '确定',
     'tenant_list': '租户列表',
     'new_members': '新建会员',
     'new_org': '新建组织',
     'user_name_nickname': '用户名称/昵称',
     'user_name_nickname_a': '用户名称/昵称：',
     'participation_role': '参与角色',
     'success': '成功',
     'fail': '失败',
     'operation_name': '操作名称',
     'detailed_info': '详细信息',
     'detailed_info_a': '详细信息：',
     'login_time': '登录时间',
     'login_time_a': '登录时间：',
     'operating_system': '操作系统',
     'operating_system_a': '操作系统：',
     'terminal': '终端',
     'ip_address': 'IP地址',
     'ip_address_a': 'IP地址:',
     'exit_time': '退出时间',
     'refresh': '刷新',
     'online_duration': '在线时长',
     'up': '上移',
     'down': '下移',
     'resource_auth': '资源授权',
     'user_auth': '用户授权',
     'platform': '平台',
     'whole': '全部',
     'tenant': '租户',
     'store': '商户',
     'secret': '秘密',
     'man': '男',
     'woman': '女',
     'browser_a': '浏览器：',
     'operation_duration_a': '操作时长：',
     'operation_method_a': '操作方法：',
     'parameter_a': '参数：',
     'version_name_a': '版本名称：',
     'version_name': '版本名称',
     'application_type_a': '应用类型：',
     'application_type': '应用类型',
     'app_type_a': 'App detailed_info_a类型：',
     'version_no': '版本号',
     'version_no_a': '版本号：',
     'file_name': '文件名称',
     'release_time': '发布时间',
     'release_time_a': '发布时间：',
     'off_shelf_time': '下架时间',
     'Generationtime':'生成时间',
     'Genera-tiontime':'生成时间：',
     'Work-orderstatus':'工单状态：',
     'file_path': '文件路径',
     'new_version': '新增版本',
     'release': '发布',
     'lower_shelf': '下架',
     'unpublished': '未发布',
     'published': '已发布',
     'removed_from_shelf': '已下架',
     'app_install_files_a': 'App 安装文件：',
     'logging_in': '登录中...',
     'welcome_to': '欢迎登录',
     'online_device': '在线设备',
     'off_line_device': '离线设备',
     'disable_device': '禁用设备',
     'device_not_activated': '未激活设备',
     'unlimited': '不限',
     'new_ssm': '新增自助机',
     'produce_date_a': '生产日期：',
     'factory_version': '出厂版本',
     'factory_version_a': '出厂版本：',
     'current_version_a': '当前版本：',
     'member_ID': '会员号',
     'member_ID_a': '会员号：',
     'file_upload_settings': '文件上传设置'
  },
  ...zhLocale
}
export default zh_CN
