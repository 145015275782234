export default [
    {
        path: '/room_state',
        name: 'roomState',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/PMS/roomState/roomState')
        }
    }, {
        path: '/room_state/check_in',
        name: 'checkIn',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/PMS/roomState/checkIn')
        }
    }
]