export default [
  {
    path: '/index',
    name: 'index',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/login/index'),
    },
  },
  {
    path: '/hotel_index',
    name: 'hotel_index',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/login/hotel_index'),
    },
  },
  {
    path: '/demo',
    name: 'demo',
    components: {
      sidebar: () => import('@/views/login/demo'),
    },
  },
]
