/*
* 读卡器
* */
import CertCtl from './IDCertCtl'
import { Message } from 'element-ui';

class CardReader {
   constructor() {}
   // 连接
   connect() {
      try {
         // 调用对应的连接方法,并赋值给result
         let result = CertCtl.connect();
         // 如果result为空,代表读卡插件未启动
         if (result === "") {
            return Message({ message: '未启动读卡插件!', type: 'error' })
         }
         // 返回连接成功的状态
         return result
      } catch (e) {
         console.log(e)
      }
   }
   // 读卡
   readCert() {
      // 调用对应的读卡方法
      let result = CertCtl.readCert();
      // 如果result为空,代表读卡插件未启动
      if (result === "") {
         Message({ message: '未启动读卡插件!', type: 'error' })
      } else {
         let resultObj = eval('(' +result+')');
         // resultFlag为0代表读卡成功
         if (resultObj.resultFlag === 0) {
            return resultObj
         } else if (resultObj.resultFlag === -1) {
            if (resultObj.errorMsg === "端口打开失败") {
               Message({ message: '读卡器未连接', type: 'error' })
            } else {
               Message({ message: resultObj.errorMsg, type: 'error' })
            }
         } else if (resultObj.resultFlag === -2) {
            Message({ message: resultObj.errorMsg, type: 'error' })
         }
      }
   }
   // 断开
   disconnect() {
      try {
         // 调用对应的断开连接方法,并赋值给result
         const result = CertCtl.disconnect();
         //如果result为空,代表读卡插件未启动
         if (result === "") {
            Message({ message: '未启动读卡插件!', type: 'error' })
         } else {
            return result
         }
      } catch (e) {
         console.log(e)
      }
   }
}

export default CardReader
