export default [
   {
      path: '/channel_distribution',
      name: 'channelDistribution',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/distributionCenter/channelDistribution')
      }
   }, {
      path: '/channel_distribution/statistics',
      name: 'statistics',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/distributionCenter/statistics')
      }
   }, {
      path: '/channel_distribution/statistics/detail',
      name: 'detail',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/distributionCenter/detail')
      }
   },
]
