export default [
   {
      path: '/pms/index',
      name: 'pmsIndex',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/PMS/index/index')
      }
   }
]
