export default [
   {
      path: '/staff_card',
      name: 'roomState',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/PMS/sendCardManage/staffCard')
      }
   }
]
