import enLocale from 'element-ui/lib/locale/lang/en' // 引入element语言包
const en_US = {
  msg: {
    'hored_hotel': 'Dayi live Smart Hotel',
    'hored_smart_hotel': 'Dayi live Smart Hotel Management Platform',
    'customer_system': 'Background management system',
    'smart_hotel_cloud': 'Dayi live Smart Hotel Cloud',
    'order_system': 'Order system',
    'login': 'Login',
    'inp_login_name': 'Login name',
    'inp_pwd': 'Password',
    'ver_code': 'Verify code',
    'ver_code_a': 'Verify code：',
    'get_code': 'Get verify code',
    'tab_code': 'Click to switch',
    'change_one': 'Change one',
    'forget_pwd': 'Forget password',
    'forget_pwd_a': 'Forget password？',
    'inp_mobile_num': 'Please enter phone number',
    'inp_new_pwd': 'Please enter a new password',
    'inp_confirm_pwd': 'Please confirm the new password',
    'immed_modify': 'Modify now',
    'acc_login': 'Account login',
    'home': 'Home',
    'device_manage': 'Device manage',
    'room_device': 'Room device',
    'config': 'Config',
    'config_name': 'Config name',
    'config_value': 'Config value',
    'pms_config': 'PMS config',
    'scene_config': 'Scene config',
    'data_statistics': 'Data statistics',
    'added_services': 'Added services',
    'dict_manageme': 'Dictionary manage',
    'added_service': 'Added service',
    'info_manage': 'Message manage',
    'operation_log': 'Operation log',
    'device_alert': 'Device alarm',
    'customer_service': 'Customer service',
    'account_manage': 'Account manage',
    'room_manage': 'Room manage',
    'hotel_manage': 'Hotel manage',
    'division_manage': 'Division manage',
    'user_manage': 'User manage',
    'authority_manage': 'Authority manage',
    'role_manage': 'Role manage',
    'menu_manage': 'Menu manage',
    'voice_manage': 'Voice manage',
    'small_manage': 'Xiaodu manage',
    'tmall_manage': 'Tmall manage',
    'smart_hotel_platform': 'Smart Hotel Management Platform',
    'modify_mobile': 'Modify mobile',
    'inp_old_mobile': 'Please enter the old phone number',
    'old_mobile_a': 'Old phone number：',
    'inp_new_mobile': 'Please enter a new phone number',
    'new_mobile_a': 'New phone number：',
    'modify_pwd': 'Change password',
    'info': 'Information',
    'establish':'establish',
    'old_pwd_a': 'Old password：',
    'inp_old_pwd': 'Please enter the old password',
    'new_pwd_a': 'New password：',
    'confirm_pwd_a': 'Confirm pass：',
    'inp_ok_pwd': 'Please confirm your password',
    'exit_a': 'Sign out',
    'select': 'Select',
    'Pleaseselectstatus':'Please select status',
    'see':'see',
    'Workordertype':'Workordertype',
    'Department':'Department',
    'contactinformation':'contact',
    'personincharge':'person in charge',
    'Jobtitle':'Job title',
    'Serial':'Serial',
    'Generationtime':'Generationtime',
    'Genera-tiontime':'Generationtime：',
    'Work-orderstatus':'Workorderstatus：',
    'select_hotel': 'Select hotel',
    'dev_name_a': 'Device name：',
    'dev_name': 'Device name',
    'dev_type_a': 'Device type：',
    'dev_type': 'Device type',
    'dev_number_a': 'Device number：',
    'dev_number': ' Devicenumber',
    'dev_status_a': 'Device status：',
    'dev_status': 'Device status',
    'floor_room_a': 'Floor Room：',
    'floor_room': 'Floor Room',
    'install_location_a': 'Instal location：',
    'install_location': 'Instal location',
    'dev_brand': 'Brand',
    'gateway': 'Gateway',
    'hotel_a': 'Hotel：',
    'floor_a': 'Floor：',
    'floor': 'Floor',
    'floors': 'Floor',
    'room_icon_a': 'Icon：',
    'font_icon': 'Font icon',
    'upload_icon': 'Upload icon',
    'prefix': 'Prefix',
    'starting_room_num': 'Start RoomNo',
    'cutoff_room_num': 'cutoff RoomNo',
    'suffix': 'Suffix',
    'room_icon': 'Icon',
    'room_num_a': 'RoomNo：',
    'room_num': 'RoomNo',
    'room_number': 'Room number',
    'roomtype_name_a': 'Room type name：',
    'roomtype_name': 'Room type name',
    'roomtype_code_a': 'Room type code：',
    'roomtype_code': 'Room type code',
    'room_name_code': 'name / Encoding',
    'facilities_name_a': 'Facility name：',
    'facilities_name': 'Facility name',
    'facilities_code_a': 'Facility code：',
    'facilities_code': 'Facility code',
    'categories_name_a': 'Category name：',
    'categories_name': 'Category name',
    'categories_code_a': 'Category code：',
    'categories_code': 'Category code',
    'node_type_a': 'Node type：',
    'node_type': 'Node type',
    'whether_with_a': 'Whether with battery：',
    'whether_with': 'Whether with battery',
    'topic_prefix_a': 'Topic prefix：',
    'topic_prefix': 'Topic prefix',
    'topic_info': 'Please enter your topic class name',
    'product_name_a': 'Product name：',
    'product_name': 'Product name',
    'product_code_a': 'Product code：',
    'product_code': 'Product code',
    'product_key_a': 'Product key：',
    'product_key': 'Product key',
    'product_secret_a': 'Product Secret：',
    'product_secret': 'Product Secret',
    'add_facilities': 'New facilities',
    'edit_facilities': 'Editing facilities',
    'add_categories': 'New category',
    'edit_categories': 'Edit category',
    'add_product': 'New product',
    'edit_product': 'Edit product',
    'product_detail': 'Product Details',
    'photo_a': 'Photo：',
    'photo': 'Photo',
    'category_a': 'Category：',
    'category': 'Category',
    'product_image_a': 'Product image：',
    'product_image': 'Product image',
    'format_a': 'Format：',
    'format': 'Format',
    'product_format_a': 'Product format：',
    'product_format': 'Product format',
    'product_number_a': 'Product number：',
    'product_number': 'Product number',
    'product_description_a': 'Model description：',
    'product_description': 'Model description',
    'start_room_num_a': 'Start room num：',
    'start_room_num': 'Start room num',
    'online_status_a': 'Online status：',
    'end_room_num_a': 'End room number：',
    'end_room_num': 'End room number',
    'mac_address': 'MAC address',
    'mac_address_a': 'MAC address：',
    'search': 'Search',
    'reset': 'Reset',
    'number': 'Number',
    'logo': 'LOGO',
    'log': 'LOG',
    'time': 'Time',
    'time_starting': 'Starting time',
    'time_end': 'End Time',
    'own_hotel': 'Hotel',
    'own_hotel_a': 'Hotel：',
    'remarks_location': 'Remarks location',
    'remarks_location_a': 'Remarks location：',
    'unlock_status': 'Whether the lock',
    'unlock_status_a': 'Whether the lock：',
    'sync_status': 'Whether the sync',
    'sync_status_a': 'Whether the sync：',
    'no_sync': 'No syn',
    'add_gateway': 'Add middle - to wait for gateway reply',
    'edit_sync': 'Modified - not synchronized',
    'edit_gateway': 'Modified - waiting for gateway reply',
    'del_gateway': 'Delete - wait for gateway reply',
    'sync_success': 'Sync success',
    'sync_failure': 'Sync failure',
    'oper_status': 'Operation status',
    'oper_status_a': 'Operation status：',
    'status': 'Status',
    'status_a': 'Status：',
    'online': 'Online',
    'offline': 'Offline',
    'inactivated': 'Inactivated',
    'hour': 'Hour',
    'last_online_time': 'Last online time',
    'last_online_time_a': 'Last online time：',
    'last_offline_time': 'Last offline time：',
    'last_offline_time_a': 'Last offline time：',
    'operating': 'Operating',
    'operating_a': 'Operating：',
    'more_status': 'More status',
    'status_list': 'Status list',
    'infrared_list': 'Infrared treasure instruction list',
    'key_name': 'Key name',
    'key_val': 'Key value',
    'result_cmd': 'Send instruction',
    'time_limit_a': 'Time limit：',
    'detail': 'Detail',
    'more': 'More',
    'add': 'Add',
    'disable': 'Disable',
    'enable': 'Enable',
    'remove': 'Remove',
    'sync_gateway': 'Sync gateway',
    'all_delete': 'Batch delete',
    'all_locking': 'Batch locking',
    'all_unlocking': 'Batch unlock',
    'instruction': 'Instruction',
    'instruction_a': 'Instruction：',
    'edit': 'Edit',
    'edit_a': 'Edit：',
    'copy': 'Copy',
    'clone': 'Clone',
    'fuzzy_copy': 'Fuzzy copy',
    'open': 'Open',
    'close': 'Close',
    'open_close': 'Open/Close',
    'manipulation': 'Manipulation',
    'gateway_sync': 'Same step equipment floor, room number:',
    'curtain_open_close_socket': 'Curtain motor > Open/Close',
    'wifi_open_close_socket': 'WIFI socket > Open/Close',
    'wifi_open_close_tip': 'WIFI temperature control panel > Open/Close',
    'auto_close_time_a': 'Auto close time：',
    'auto_close_hover_tip': 'The automatic closing time, if there is a guest entering the room within the set time, the set automatic closing time will be invalid; if the room exceeds the set time, the room will be automatically closed if there is no guest entering.',
    'auto_close_tip': 'After selecting the auto-off time, it cannot be changed; the time setting is valid only this time.',
    'dev_alias_tip': 'The device alias will be used when configuring the scenario. If two devices of the same type appear in the same location, the system cannot recognize the device. In this case, the device alias is used to distinguish the device！',
    'dev_location_tip': 'The device location will be used when configuring the scenario. If you do not fill in the location, the scenario configuration may be invalid.',
    'unlock': 'Unlock',
    'lock': 'Lock',
    'child_dev': 'Child device',
    'wake': 'Wake',
    'dev_detail': 'Device detail',
    'dev_info': 'Device Information',
    'trigger_dev_list': 'Trigger device list',
    'oftrigger_dev_list': 'List of triggered devices',
    'scene_photo': 'Scene photos',
    'scene_info': 'Scene information',
    'gat_info': 'Gateway information',
    'build_unit': 'Building / unit',
    'build_unit_a': 'Building / unit：',
    'create_terminal': 'Create terminal',
    'create_terminal_a': 'Create terminal：',
    'sync_gateway_a': 'Sync gateway status：',
    'dev_buildUnit': 'Device Building / Unit',
    'dev_buildUnit_a': 'Device Building / Unit：',
    'owned_floor_a': 'Owned floor：',
    'belong_floorRoom_a': 'Floor / Room：',
    'own_gateway_a': 'Own gateway：',
    'gateway_version_a': 'Gateway version：',
    'dev_model_a': 'Device model：',
    'dev_product_a': 'Product：',
    'dev_key_a': 'Device key：',
    'dev_secret_a': 'Device secret：',
    'agreement_type_a': 'Agreement type：',
    'internet_time': 'Internet access time：',
    'push_messages_a': 'Whether to push messages：',
    'panel_brightness_a': 'Panel brightness：',
    'cur_direction_a': 'Curtain rotation direction：',
    'whe_normal_a': 'Whether the battery is normal：',
    'whe_power_a': 'Whether to push power：',
    'device_list': 'Device key list',
    'key_position': 'Key position',
    'key_alias': 'Key alias',
    'key_status': 'Key status',
    'key_type': 'Button type',
    'whe_connect': 'Whether connect',
    'control_list': 'Temperature control panel parameter list',
    'mode': 'Mode',
    'heating': 'Heating',
    'refrigeration': 'Refrigeration',
    'low': 'LOW',
    'middle': 'MIDDLE',
    'high': 'HIGH',
    'auto': 'AUTO',
    'switch': 'SWITCH',
    'scene': 'SCENE',
    'switch_statusr': 'Switch status',
    'lock_status': 'Child lock status',
    'current_temperature': 'Current Temperature',
    'target_temperature': 'Target temperature',
    'wind_speed': 'Wind speed',
    'time_on_time': 'Timing on time',
    'time_off': 'Time off',
    'reporting_time': 'Reporting time',
    'baozi_equipment': 'Infrared Baozi equipment',
    'brand_name': 'brand name',
    'brand_id': 'Brand ID',
    'brand_model': 'Brand model number',
    'location': 'Location',
    'location_a': 'Location：',
    'alias': 'Alias',
    'first': 'First',
    'second': 'Second',
    'third': 'Third',
    'fourth': 'Fourth',
    'first_alias': 'First alias',
    'second_alias': 'Second alias',
    'third_alias': 'Third alias',
    'fourth_alias': 'Fourth alias',
    'first_alias_a': 'First alias：',
    'second_alias_a': 'Second alias：',
    'third_alias_a': 'Third alias：',
    'fourth_alias_a': 'Fourth alias：',
    'first_status_a': 'First status：',
    'second_status_a': 'Second status：',
    'third_status_a': 'Third status：',
    'fourth_status_a': 'Fourth status：',
    'first_switch_a': 'First connect：',
    'second_switch_a': 'Second connect：',
    'third_switch_a': 'Third connect：',
    'fourth_switch_a': 'Fourth connect：',
    'normal_room': 'Normal room',
    'abnormal_room': 'Abnormal room',
    'all_room': 'All room',
    'no_data': 'No Data',
    'create_type': 'Create type',
    'create_type_a': 'Create type：',
    'amount': 'Amount',
    'total_nubmer': 'Total number of devices',
    'return': 'Return',
    'previous': 'Previous',
    'dev_alias': 'Device alias',
    'dev_alias_a': 'Device alias：',
    'dev_location': 'Device location',
    'dev_location_a': 'Device location：',
    'room_list': 'Room list',
    'room_detail': 'Room list',
    'curr_room_a': 'Current RoomNo：',
    'edit_device': 'Edit device',
    'change_status': 'Change status',
    'dict_type_a': 'Dictionary type：',
    'dict_val': 'Dictionary value',
    'dict_cn_val': 'Dictionary value(Chinese)',
    'dict_val_a': 'Dictionary value：',
    'dict_en_val': 'Dictionary value (English)',
    'dict_en_val_a': 'English value：',
    'add_dict': 'New dictionary',
    'dict_type': 'Dictionary type',
    'dict_attr': 'Dictionary attribute',
    'dict_attr_a': 'Dictionary attribute：',
    'alarm_s': 'Alarm',
    'action': 'Action',
    'open_s': 'Open',
    'close_s': 'Close',
    'open_close_s': 'Open,Close',
    'dict_code': 'Dictionary code',
    'edit_dict': 'Edit dict',
    'create_time': 'Creation time',
    'update_time': 'modification time',
    'create_time_a': 'Creation time：',
    'scene_model': 'Scene mode',
    'control_panel': 'Temperature control panel',
    'control_off_time_a': 'Delayed execution time：',
    'control_unit_second_a': '(Unit / minute)',
    'control_panel_txt': 'When the temperature control panel is turned on, if none of the selected products are triggered within the time set below, then close the temperature control panel。',
    'new_scene_explain_txt': 'The trigger device and the executed device will search according to the device location and device type, and may match one or more devices. After the configuration is complete, go to the details view and do not need to join the device in the scene. You can remove the device in the editing function.',
    'edit_scene_explain_txt': 'The trigger device and the executed device match according to the MAC address of the device. After the configuration is complete, go to the details view and do not need to join the device in the scene. You can remove the device in the editing function.',
    'copy_scene_explain_txt': 'The copy scene function will search according to the device location and device type of the room, and may match zero or more devices. After the configuration is complete, go to the details view and do not need to join the device in the scene. You can remove the device in the editing function.',
    'copy_scene_explain_txts': 'The copy scene function will search the target room according to the hotel of the current room, the type of room, the type of trigger device, and the location of the trigger device in the room. The rooms that meet the requirements will be searched out.It is recommended to enter the details view after the configuration is completed. Devices that do not need to be added to the scene can be removed from the editing function.',
    'edit_hotel_floor_txt': 'After the hotel floor is modified, the floors of the rooms and device previously created also need to be modified manually, otherwise the displayed floors will not correspond to each other.',
    'add_scene': 'New scene',
    'add_scene_a': 'New scene：',
    'scene_edit_scene_model': 'New scene > Edit scene mode',
    'scene_model_chinese': 'Scene mode (Chinese)',
    'scene_model_english': 'Scene mode (English)',
    'add_scene_add_execute_dev': 'Create a new scene > Add an executed device',
    'add_scene_add_dev': 'New Scene> Add Device',
    'select_location_a': 'Choose location：',
    'device_property': 'Device properties',
    'device_property_a': 'Device properties：',
    'new_scene_add_trigger_dev': 'New scene > Add trigger device',
    'new_scene_add_app_room': 'New scene > Add application room',
    'copy_scene_app_info': 'Copy scene > copy result prompt',
    'copy_scene_success_room': 'Room copied successfully',
    'copy_scene_failure_room': 'Replication failure room',
    'copy_know_the': 'I know the',
    'copy_scene': 'Copy scene',
    'copy_scene_a': 'One key scene replication',
    'edit_scene_edit_execute_dev': 'Edit scene > Edit execution device',
    'edit_scene_edit_trigger_dev': 'Edit scene > Edit trigger device',
    'select_trigger_dev_attr': 'Select trigger device and attributes',
    'confirm_trigger_dev_attr': 'Confirm trigger device and attributes',
    'select_execute_dev_attr': 'Select execution device and attributes',
    'confirm_execute_dev_attr': 'Confirm execution device and attributes',
    'select_execute_dev_config': 'Select device and device configuration',
    'confirm_execute_dev_config': 'Confirm equipment and equipment configuration',
    'device_config': 'Device Configuration',
    'room_no_device': 'There is no device in this room！',
    'all_select': 'Select all',
    'customize': 'Customize',
    'customize_a': 'Customize：',
    'customize_model': 'Custom mode',
    'start_upload': 'Start upload',
    'save': 'Save',
    'cancel': 'Cancel',
    'trigger_condition_a': 'The trigger condition：',
    'perform_an_action_a': 'To perform an action：',
    'trigger_device': 'Trigger device',
    'trigger_device_a': 'Trigger device：',
    'trigger_dev_explain': 'The active device that triggers the device to perform as a scene, such as: welcome mode, the magnetic sensor senses that the person enters the room, the corridor light in the room is turned on, and the magnetic sensor acts as a trigger device.',
    'executed_device': 'Executed device',
    'executed_device_a': 'Executed device：',
    'execute_on': 'Execute on',
    'triggered_condition': 'Triggered condition',
    'valid': 'Valid',
    'invalid': 'Invalid',
    'are_unlocked': 'Locked',
    'not_unlock': 'Unlocked',
    'scene_detail': 'Scene detail',
    'detail_info': 'Details',
    'sync_info': 'Sync info',
    'sync_device_a': 'Sync device：',
    'app_scenario_a': 'App scenario：',
    'support_actions_a': 'Support actions：',
    'device_info': 'Device info',
    'basic_info': 'Basic info',
    'permission_info': 'Permission information',
    'application_room_a': 'Application room：',
    'look_scenes_list': 'View scene list',
    'room_scenes_list': 'Model room scene list',
    'room_dev_list': 'Room equipment list',
    'select_tg_dev': 'Selected trigger device',
    'select_tger_dev': 'Selected triggered device',
    'execute_dev': 'Execution device',
    'please_add_app_room': 'Please add an app room',
    'execute_dev_a': 'Execution device：',
    'execute_term_a': 'Execute On：',
    'execute_dev_explain': 'The passive device that is executed by the executed device as a scene, such as: welcome mode, the door magnetic sensor senses that the person enters the room, the corridor light in the room is turned on, and the corridor turned on as the executed device.',
    'trigger_term_a': 'Triggered：',
    'trigger_dev': 'Triggered device',
    'trigger_dev_a': 'Triggered device：',
    'example_room': 'Example room：',
    'target_room_a': 'Target room：',
    'target_rooms': 'Target room',
    'copy_deit_dExamp': 'One click scene copy > model room editor',
    'copy_add_dExamp': 'One-click scene copy > target room added',
    'copy_matching_test': 'One-click scene replication > matching test',
    'dExamp_name_a': 'Model room name：',
    'dExamp_name': 'Model room name',
    'dExamp_id_a': 'Model room number：',
    'dExamp_id': 'Model room number',
    'current_dExamp': 'Current model room：',
    'add_dExamp': 'Add model rooms',
    'edit_dExamp': 'Edit model room',
    'sel_target_room': 'Target room selection',
    'res_match_test': 'Match test results',
    'sample_room': 'Sample room',
    'target_room': 'Target room',
    'matching': 'Matching',
    'matching_no': 'Mismatching',
    'copy_success': 'Copy success',
    'copy_failure': 'Copy the failure',
    'matching_results_a': 'Matching results：',
    'matching_results': 'Matching results',
    'copy_the_result_a': 'Copy the result：',
    'copy_the_result': 'Copy the result',
    'to_match': 'To match',
    'to_loading': 'The data is matching...',
    'matching_test': 'Matching test',
    'begin_to_copy': 'Begin to copy',
    'scene_mode': 'Scene mode',
    'scene_mode_a': 'Scene mode：',
    'scene_off_time_a': 'Scene off time：',
    'scene_off_times': 'seconds',
    'scene_off_delayed_a': 'Scene delay time：',
    'scene_off_time_explain': 'When the scene is closed, if the guest enters the bathroom, the infrared sensor senses the person, and all the lights of the configuration are turned on. After the guest leaves the bathroom, the infrared sensor does not sense the person for a long time and turns off all the lights.',
    'scene_off_delay_explain': 'Scene delay time, such as guests entering the bathroom, the door magnetic sensor sensing the person, delay ten seconds to open the room lights, curtains and other scenes.',
    'edit_scene': 'Edit scene',
    'edit_info': 'Edit information',
    'effective_a': 'Effective：',
    'whe_applets_a': 'Whether to synchronize applets：',
    'whe_voice_a': 'Whether to synchronize voice：',
    'effective_explain': 'If the check is effective, the scene will take effect immediately after the configuration is complete.',
    'yes': 'Yes',
    'no': 'No',
    'positive': 'Positive',
    'anti': 'Anti',
    'normal': 'Normal',
    'abnormal': 'Abnormal',
    'remark': 'Remarks',
    'remarks': 'Remarks',
    'remarks_a': 'Remarks：',
    'inp_remark_info': 'Please enter a comment',
    'miniprogram_a': 'Applet menu accredit：',
    'miniprogram': 'Applet menu accredit',
    'select_sys_a': 'Select system：',
    'type': 'Type',
    'type_a': 'Type：',
    'user_type': 'User type',
    'user_type_a': 'User type：',
    'log_type': 'Log type',
    'log_type_a': 'Log type：',
    'to': 'to',
    'start_date': 'Start Date',
    'end_date': 'End Date',
    'user_or_mobile': 'Username / Mobile',
    'operate_info': 'Operational information',
    'operate_time': 'Operating time',
    'alarm_time': 'Alarm time',
    'alarm_time_a': 'Alarm time：',
    'alarm_msg': 'Alarm message',
    'msg_content': 'Message content',
    'info_time': 'Info time',
    'service_type': 'Service type',
    'service_type_a': 'Service type：',
    'data_type_a': 'Data type：',
    'phone_number': 'Phone number',
    'phone_number_a': 'Phone number：',
    'user_name': 'Username',
    'user_name_a': 'Username：',
    'start_time': 'the starting time',
    'province': 'Province：',
    'province_a': 'Province：',
    'city': 'City',
    'city_a': 'City：',
    'area': 'Area',
    'area_a': 'Area：',
    'new_hotel': 'New hotel',
    'superior_hotel': 'Superior hotel',
    'superior_hotel_a': 'Superior hotel：',
    'hotel_basic_info': 'Hotel Basic Information',
    'hotel_basic_info_a': 'Hotel Basic Information：',
    'hotel_affiliation': 'Affiliation',
    'hotel_affiliation_a': 'Affiliation：',
    'hotel_type': 'Hotel type',
    'hotel_type_a': 'Hotel type：',
    'hotel_logo': 'Hotel logo',
    'hotel_logo_a': 'Hotel logo：',
    'hotel_info': 'Hotel details',
    'hotel_star': 'Hotel Star',
    'hotel_star_a': 'Hotel Star：',
    'hotel_area': 'Hotel area',
    'hotel_area_a': 'Hotel area：',
    'hotel_booking': 'Max booking days',
    'hotel_booking_a': 'Max booking days：',
    'hotel_in_time': 'Check in time',
    'hotel_in_time_a': 'Check in time：',
    'hotel_breakfast_time': 'Breakfast time',
    'hotel_breakfast_time_a': 'Breakfast time：',
    'hotel_out_time': 'Check out time',
    'hotel_out_time_a': 'Check out time：',
    'hotel_reception_phone': 'Reception phone',
    'hotel_reception_phone_a': 'Reception phone：',
    'hotel_website': 'Hotel official website',
    'hotel_website_a': 'Hotel official website：',
    'hotel_notice': 'Check in notice',
    'hotel_notice_a': 'Check in notice：',
    'hotel_introduction': 'Hotel introduction',
    'hotel_introduction_a': 'Hotel introduction：',
    'hotel_geolocation': 'Geolocation',
    'hotel_geolocation_a': 'Geolocation：',
    'hotel_room_type': 'Hotel Room Type',
    'hotel_room_type_a': 'Hotel Room Type：',
    'hotel_facilities': 'Hotel facilities',
    'hotel_facilities_a': 'Hotel facilities：',
    'hotel_applet': 'Applet tab permissions',
    'hotel_applet_a': 'Applet tab permissions：',
    'hotel_name_code': 'Hotel name/Hotel Code',
    'hotel_name': 'Hotel name',
    'hotel_name_a': 'Hotel name：',
    'hotel_alias': 'Hotel alias',
    'hotel_alias_a': 'Hotel alias',
    'pms_config_all': 'PMS parameter config  items',
    'pms_city': 'PMS selection',
    'pms_city_a': 'PMS selection：',
    'pms_hotel_code': 'PMS Hotel Code',
    'pms_hotel_code_a': 'PMS Hotel Code：',
    'pms_name': 'PMS name',
    'pms_name_a': 'PMS name：',
    'pms_code': 'PMS encoding',
    'pms_code_a': 'PMS encoding：',
    'service_name': 'Service provider name',
    'service_name_a': 'Service provider name：',
    'contact_person': 'Contact person',
    'contact_person_a': 'Contact person：',
    'e_mail': 'E-mail',
    'e_mail_a': 'E-mail：',
    'add_pms': 'New PMS',
    'edit_pms': 'Edit PMS',
    'hotel_code': 'Hotel Code',
    'hotel_code_a': 'Hotel Code：',
    'hotel_phone': 'Hotel phone',
    'hotel_phone_a': 'Hotel phone：',
    'start_floor': 'Start floor',
    'start_floor_a': 'Start floor：',
    'end_floor': 'End floor',
    'end_floor_a': 'End floor：',
    'mailbox': 'Mailbox',
    'mailbox_a': 'Mailbox：',
    'area_code': 'Area code',
    'fax_number': 'Fax number',
    'regional_location_a': 'Regional location：',
    'subdistrict_office': 'Subdistrict office / specific building number',
    'fax_address': 'Fax address',
    'fax_address_a': 'Fax address：',
    'detail_address': 'Address',
    'detail_address_a': 'Address：',
    'hotel_detail': 'Hotel detail',
    'province_city_area_a': 'Province：',
    'edit_hotel': 'Edit hotel',
    'dept_name': 'Dept name',
    'dept_name_a': 'Dept name：',
    'add_dept': 'New dept',
    'dept_detail': 'Dept detail',
    'superior_dept': 'Superior dept',
    'superior_dept_a': 'Superior dept：',
    'contact_phone': 'Contact number',
    'contact_phone_a': 'Contact number：',
    'principal': 'Principal',
    'principal_a': 'Principal：',
    'vice_principal': 'Vice principal',
    'vice_principal_a': 'Vice principal：',
    'contact_mailbox': 'Contact mailbox',
    'contact_mailbox_a': 'Contact mailbox：',
    'contact_address': 'Contact address',
    'contact_address_a': 'Contact address：',
    'affiliation': 'Affiliation',
    'affiliation_a': 'Affiliation：',
    'phone': 'Phone',
    'add_time': 'Add time',
    'add_time_a': 'Add time：',
    'user_source': 'User Source',
    'user_source_a': 'User Source：',
    'temporary_visit': 'Temporary visit',
    'member_user': 'Member users',
    'addWway': 'Add way',
    'addWway_a': 'Add way：',
    'roomtype_one': 'One',
    'roomtype_batch': 'Batch',
    'roomtype': 'Room type',
    'roomtype_a': 'Room type：',
    'room_type_a': 'Room type：',
    'room_type': 'Room type',
    'room_status_a': 'Room equipment status：',
    'room_status': 'Room equipment status',
    'building_unit': 'Building/unit',
    'building_unit_a': 'Building/unit：',
    'building_add': 'Add building',
    'building_edit': 'Edit building',
    'building_manage': 'Building manage',
    'building_name_a': 'Building name：',
    'building_name': 'Building name',
    'building_number_a': 'Building number：',
    'building_number': 'Building number',
    'build_time_a': 'Construction time：',
    'build_time': 'Construction time',
    'build_number_a': 'Floor number：',
    'build_number': 'Floor number',
    'build_no': 'Above-ground layers',
    'build_under': 'Number of underground',
    'build_towards_a': 'Towards：',
    'build_towards': 'Towards',
    'unit_add': 'Add unit',
    'unit_edit': 'Edit unit',
    'unit_manage': 'Unit manage',
    'unit_name_a': 'Unit name：',
    'unit_name': 'Unit name',
    'unit_number_a': 'Unit number：',
    'unit_number': 'Unit number',
    'unit_build': 'Building',
    'room_type_manage': 'Room type manage',
    'add_Room': 'Add room type',
    'add_room_type': 'Add room type',
    'room_type_cn_a': 'Room type (Chinese)：',
    'room_type_en_a': 'Room type (English)：',
    'room_type_cn': 'Room type (Chinese)',
    'room_type_en': 'Room type (English)',
    'room_type_photo_edit': 'Room type photo editing',
    'room_type_config': 'Room type configuration',
    'room_build_config': 'Building manage configuration',
    'default_room_type_a': 'Default room type：',
    'customize_room_type_a': 'Custom room type：',
    'add_room': 'New room',
    'add_check_in': 'Simulated check-in',
    'room_floor_a': 'Room floor：',
    'batch_add_room': 'Batch New room',
    'special_note_a': 'Special note：',
    'special_note_txt': 'Batch new room number This function, start room number, end room number only supports numbers, does not support letters or other; increment number is 1。',
    'hotel_room_type_txt': 'If you only fill in Chinese, you will not be able to match English when you switch to English.',
    'chinese': 'Chinese',
    'english': 'English',
    'floor_num': 'Floor num',
    'edit_room': 'Edit room',
    'edit_room_type': 'Edit room type',
    'dept_a': 'Dept：',
    'login_name_mobile': 'Login name / name / mobile number',
    'add_user': 'New user',
    'reset_password': 'Reset password',
    'login_name': 'Logins',
    'login_name_a': 'Logins：',
    'password': 'Password',
    'password_a': 'Password：',
    'name': 'Name',
    'name_a': 'Name：',
    'phone_num': 'Phone',
    'phone_num_a': 'Phone：',
    'ownership_hotel_a': 'Own hotel：',
    'ownership_dept_a': 'Own dept：',
    'job_num': 'Job nummber',
    'job_num_a': 'Job nummber：',
    'landline': 'Landline',
    'landline_a': 'Landline：',
    'user_detail': 'User detail',
    'edit_user': 'Edit user',
    'edit_user_reset_password': 'Edit user > Reset password',
    'voice_wizard': 'Voice wizard',
    'tmall_elf': 'Tmall Elf',
    'small_degree': 'Small degree',
    'amazon_speaker': 'Amazon Smart Speaker',
    'google_speaker': 'Google Smart Speaker',
    'voice_device': 'Voice device',
    'voice_device_a': 'Voice device：',
    'add_voice': 'Add voice device',
    'edit_voice': 'Edit voice device',
    'voice_txt': 'The filled-in voice serial number is consistent with the serial number activated on the official voice website. If they are inconsistent, the smart device cannot be controlled.',
    'small_txt': 'The completed xiaodu serial number is consistent with the serial number activated by the official website. If it is inconsistent, the smart device cannot be controlled.',
    'add_small': 'Add xiaodu',
    'device_serial_number': 'Devise serial number',
    'device_serial_room': 'Serial number / Room',
    'device_version': 'Device version',
    'device_version_a': 'Device version：',
    'device_room': 'Room',
    'device_room_a': 'Room：',
    'bind_user': 'Bind user',
    'bind_user_a': 'Bind user：',
    'bind_username': 'Bind username',
    'bind_username_a': 'Bind username：',
    'device_openId': 'Device openId',
    'device_openId_a': 'Device openId：',
    'hotel_version': 'Hotel version',
    'home_version': 'Home version',
    'serial_number': 'Serial number',
    'serial_number_a': 'Serial number：',
    'edit_small': 'Edit xiaodu',
    'add_tmall': 'Add tmall',
    'edit_tmall': 'Edit tmall',
    'tmall_type_a': 'Tmall type：',
    'own_role': 'Own role',
    'own_role_a': 'Own role：',
    'login_app_permission_a': 'Login APP permission：',
    'control_sys_menu_permission_a': 'Guest control authority：',
    'order_sys_menu_permission_a': 'Reservation permission：',
    'customer_sys': 'Passenger control system',
    'order_sys': 'Order system',
    'sence_sync': 'Scene synchronization terminal',
    'small_program': 'Small program',
    'intelligent_voice': 'Intelligent Voice',
    'role_name_ce': 'Role name/English name',
    'role_name': 'Role name',
    'role_name_en': 'English name',
    'role_name_a': 'Role name：',
    'role_name_a_en': 'English name：',
    'add_role': 'New role',
    'no_admin': 'Administrator',
    'no_admin_a': 'Administrator？：',
    'available': 'Available',
    'available_a': 'Available？：',
    'edit_role': 'Editing role',
    'allocatioin_role': 'Allocatioin role',
    'user_a': 'User：',
    'allocatioin_a': 'Role：',
    'hint_a': 'Hint：',
    'role_tip_txt': 'Only the role of hotel administrator or hotel operator can log in to the app.',
    'assign_permissions': 'Authority',
    'menu_name': 'Menu name',
    'menu_name_a': 'Menu name：',
    'english_name': 'Menu name (English)',
    'english_name_a': 'English name：',
    'menu_name_en': 'Menu name (English)',
    'menu_name_en_a': 'Menu name (English)：',
    'new_menu': 'New menu',
    'menu_link': 'Menu link',
    'menu_link_a': 'Menu link：',
    'sort': 'Sort',
    'sort_a': 'Sort：',
    'no_show': 'Show',
    'no_show_a': 'Show：',
    'menu_detail': 'Menu details',
    'menu_permission': 'Menu Permission',
    'menu_permission_a': 'Menu Permission：',
    'superior_menu': 'Superior menu：',
    'affiliated_system_a': 'Affiliated system：',
    'edit_menu': 'Edit menu',
     'tenant_list': 'Tenant list',
     'head_portrait': 'Head portrait',
     'nickname': 'Nickname',
     'gender': 'Gender',
     'tenant_name': 'Tenant name',
     'register_time': 'Registration time',
     'new_members': 'New members',
     'member_name': 'Member name',
     'member_name_a': 'Member name：',
     'member_name_nickname_a': 'Member name / nickname',
     'head_portrait_a': 'Head portrait：',
     'nickname_a': 'Nickname：',
     'gender_a': 'Gender：',
     'date_of_birth': 'Date of birth',
     'date_of_birth_a': 'Date of birth：',
     'document_type_a': 'Document type：',
     'identification_number': 'Identification Number',
     'identification_number_a': 'Identification Number：',
     'nationality_a': 'Nationality：',
     'is_it_enabled_a': 'Enable or not：',
     'administrative_area_a': 'Province / city / district (county)：',
     'administrative_area': 'Province / city / district (county)',
     'tenant_store_a': 'Tenant / Store：',
     'tenant_store': 'Tenant / Store',
     'user_name_nickname': 'User name / nickname',
     'user_name_nickname_a': 'User name / nickname：',
     'participation_role': 'Participation role',
     'org_list': 'Organization list',
     'affiliated_org': 'Organization',
     'operate_time_a': 'Operation time：',
     'success': 'Success',
     'fail': 'Fail',
     'menu_list': 'Menu list',
     'operation_name': 'Operation name',
     'detailed_info': 'detailed information',
     'login_time_a': 'Login time：',
     'operating_system': 'Operating system',
     'terminal': 'Terminal',
     'ip_address': 'IP address',
     'login_time': 'Login time',
     'exit_time': 'Exit time',
     'refresh': 'Refresh',
     'online_duration': 'Online duration',
     'dict_name': 'Dictionary name',
     'dict_name_a': 'Dictionary name：',
     'client_a': 'Client：',
     'new_dict': 'Add dictionary',
     'is_it_hidden': 'Hide or not',
     'dict_encoding': 'Dictionary code',
     'hidden_values': 'Hide values',
     'display_value': 'Show',
     'dict_color': 'Dictionary color',
     'sort_number': 'Serial number',
     'is_init': 'initialization',
     'up': 'UP',
     'down': 'DOWN',
     'menu_name_code': 'Menu name / code',
     'menu_code': 'Menu coding',
     'menu_path': 'Menu path',
     'client': 'Client',
     'role_name_code': 'Role name / code',
     'resource_auth': 'Resource authorization',
     'user_auth': 'User authorization',
     'role_encoding': 'Role code',
     'platform': 'Platform',
     'whole': 'Whole',
     'tenant': 'Tenant',
     'store': 'Store',
     'org_name_a': 'Organization name：',
     'org_name_code': 'Organization name / code',
     'new_org': 'New Organization',
     'org_name': 'Organization name',
     'org_coding': 'Organization code',
     'org_type': 'Organization Type',
     'company_name': 'Company name',
     'company_code': 'Company code',
     'company_nature': 'Company nature',
     'industry': 'Industry',
     'social_credit_code': 'Social Credit Code',
     'legal_person': 'Legal person',
     'company_number': 'Company number',
     'company_email': 'Company email',
     'new_company': 'New company',
     'company_name_a': 'Company name：',
     'company_name_credit_code': 'Company name / code / Credit Code',
     'company_email_a': 'Company email：',
     'legal_person_a': 'Legal person：',
     'company_nature_a': 'Company nature：',
     'add_store': 'Add merchant',
     'add_tenant': 'Add tenant',
     'org_type_a': 'Organization type：',
     'org_coding_a': 'Organization code：',
     'role_encoding_a': 'Role code:',
     'is_it_effective_a': 'Effective or not:',
     'roles_granted_to_users': 'Roles granted to users',
     'no_user_selected': 'No user selected',
     'user_selected': 'User selected',
     'menu_resource_grant_role': 'Menu resource grant role',
     'secret': 'Secret',
     'man': 'Man',
     'woman': 'Woman',
     'remark_a': 'Remarks:',
     'select_role': 'Please select the role to participate in',
     'role_list': 'Role list',
     'selected_roles': 'Selected roles',
     'parent_menu_a': 'Parent menu:',
     'menu_icon_a': 'Menu icon:',
     'menu_code_a': 'Menu coding:',
     'is_it_hidden_a': 'Hide or not:',
     "parent_dict": 'Parent dictionary:',
     'dict_icon_a': 'Dictionary Icon:',
     'dict_encoding_a': 'Dictionary code:',
     'dict_color_a': 'Dictionary color:',
     'display_value_a': 'Show:',
     'hidden_values_a': 'Hide values:',
     'dict_icon': 'Dictionary Icon',
     'ip_address_a': 'IP address:',
     'operating_system_a': 'Operating system:',
     'browser_a': 'Browser:',
     'operation_duration_a': 'Operation duration:',
     'operation_method_a': 'Operation method:',
     'parameter_a': 'Parameter:',
     'detailed_info_a': 'detailed information:',
     'version_name_a': 'Version name:',
     'version_name': 'Version name',
     'application_type_a': 'Application type:',
     'app_type_a': 'App type:',
     'application_type': 'Application type',
     'version_no': 'Version No',
     'file_name': 'File name',
     'release_time': 'Release time',
     'off_shelf_time': 'Off shelf time',
     'file_path': "File path",
     'new_version': 'New version',
     'release': 'Release',
     'lower_shelf': "Lower shelf",
     'unpublished': 'Unpublished',
     'published': 'Published',
     'removed_from_shelf': 'Removed from shelf',
     'version_no_a': 'Version No:',
     'app_install_files_a': "App installation files:",
     'release_time_a': 'Release time:',
     'logging_in': 'Logging in...',
     'welcome_to': 'Welcome to',
     'online_device': 'Online device',
     'off_line_device': 'Off-line device',
     'disable_device': 'Disable device',
     'device_not_activated': 'Device not activated',
     'unlimited': 'Unlimited',
     'dev_model': 'Device model'
  },
  ...enLocale
}
export default en_US
