export default [
   {
      path: '/comb_portrait',
      name: 'combPortrait',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/data/scenePlus/combPortrait/combPortrait')
      }
   }, {
      path: '/over_portrait',
      name: 'overPortrait',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/data/scenePlus/overPortrait/overPortrait')
      }
   }, {
      path: '/per_portrait',
      name: 'perPortrait',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/data/scenePlus/perPortrait/perPortrait')
      }
   }, {
      path: '/personal_list',
      name: 'personalList',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/data/scenePlus/perPortrait/personalList')
      }
   },
]