import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
     isCollapse: false, // 菜单是否展开
     menuList: [], // 菜单数据
     menuNum: '0', // 菜单选中
     cachePage: '',  // 缓存页面
     hotelInfo: '',    // 酒店信息
     dictData: {},         // 字典数据
  },
  mutations: {
     HotelInfo (state, val){
      state.hotelInfo = val
    },
     collapseHide (state, val) {
       state.isCollapse = val
     },
     menuListData (state, val) {
       state.menuList = val
     },
     menuNumData (state, val) {
       state.menuNum = val
     },
     cacheData(state, val){
       state.cachePage = val
     },
     DictData(state, val) {
       state.dictData = val
     }
  },
  actions: {
     setHotelInfo (context, val){
      context.commit('HotelInfo', val)
    },
    setCollapseHide (context, val) {
      context.commit('collapseHide', val)
    },
    setMenuList (context, val) {
      context.commit('menuListData', val)
    },
     setMenuNum (context, val) {
       context.commit('menuNumData', val)
     },
     setCachePage(context, val){
        context.commit('cacheData', val)
     },
     setDictData(context, val) {
        context.commit('DictData', val)
     }
  }
})
export default store
