export default [
   {
      path: '/home_product_categories',
      name: 'homeProductCategories',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/AIOT/homeDevice/productManage/productCategories')
      }
   },
   {
      path: '/home_product_list',
      name: 'homeProductList',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/AIOT/homeDevice/productManage/productList')
      }
   },
   {
      path: '/home_all/:device_type_id',
      name: 'homeAll',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/AIOT/homeDevice/homeAll/homeAll')
      }
   },
   {
      path: '/home_device_edit',
      name: 'homeDeviceEdit',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/AIOT/homeDevice/homeAll/home_device_edit')
      }
   },
   {
      path: '/home_scenes_config',
      name: 'homeScenesConfig',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/AIOT/homeDevice/scenesConfig/scenesConfig')
      }
   },
   {
      path: '/home_scenes_detail',
      name: 'homeScenesDetail',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/AIOT/homeDevice/scenesConfig/scenesDetail')
      }
   },
   {
      path: '/home_scenes_edit',
      name: 'homeScenesEdit',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/AIOT/homeDevice/scenesConfig/home_scenes_edit')
      }
   },
   {
      path: '/home_device_alarm',
      name: 'homeDeviceAlarm',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/AIOT/homeDevice/deviceAlarm/deviceAlarm')
      }
   },
   {
      path: '/home_device_detail',
      name: 'homeDeviceDetail',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/AIOT/homeDevice/homeAll/home_device_detail')
      }
   }
]