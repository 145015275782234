export default [
  {
    path: '/house_price_strategy',
    name: 'housepriceStrategy',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/PMS/priceManage/housepriceStrategy/housepriceStrategy'),
    },
  },
  {
    path: '/add_house_price_strategy',
    name: 'addHousepriceStrategy',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/PMS/priceManage/housepriceStrategy/addHousepriceStrategy'),
    },
  },
  {
    path: '/house_price_calendar',
    name: 'housePriceCalendar',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/PMS/priceManage/housePriceCalendar/housePriceCalendar'),
    },
  },
  {
    path: '/hour_room',
    name: 'hourRoom',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/PMS/priceManage/basedHousePrices/hourRoom'),
    },
  },
  {
    path: '/room_calendar',
    name: 'roomCalendar',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/PMS/priceManage/basedHousePrices/roomCalendar'),
    },
  },
  {
    path: '/batch_change',
    name: 'batchChange',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/PMS/priceManage/basedHousePrices/batchChange'),
    },
  },
  {
    path: '/bundle_setting',
    name: 'bundleSetting',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/PMS/priceManage/basedHousePrices/bundleSetting'),
    },
  },
  {
    path: '/change_record',
    name: 'changeRecord',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/PMS/priceManage/basedHousePrices/changeRecord'),
    },
  },
  {
    path: '/house_group',
    name: 'houseGroup',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/PMS/priceManage/basedHousePrices/houseGroup'),
    },
  },
  {
    path: '/floor_group',
    name: 'floorGroup',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/PMS/priceManage/basedHousePrices/floorGroup'),
    },
  },
  {
    path: '/calendar_report',
    name: 'calendarReport',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/PMS/atrialState/calendarReport'),
    },
  },
  {
    path: '/Channel_inventory',
    name: 'ChannelInventory',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/PMS/priceManage/basedHousePrices/ChannelInventory'),
    },
  },
  {
    path: '/house_sort',
    name: 'houseSort',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/PMS/priceManage/houseSort/houseSort'),
    },
  },
  {
    path: '/print_set',
    name: 'printSet',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/PMS/accommodationSetting/printSet/printSet'),
    },
  },
  {
    path: '/color_set',
    name: 'colorSet',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/PMS/atrialState/colorSet'),
    },
  },
  {
    path: '/pms/night_audit_settings',
    name: 'nightAuditSettings',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/PMS/nightAudit/nightAuditSettings'),
    },
  },
  {
    path: '/pms/night_audit_records',
    name: 'nightAuditRecords',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/PMS/nightAudit/nightAuditRecords'),
    },
  },
  {
    path: '/pms/abnormal_orders',
    name: 'abnormalOrders',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/PMS/nightAudit/abnormalOrders'),
    },
  },
  {
    path: '/pms/night_audit_daily',
    name: 'nightAuditDaily',
    meta: { token: true },
    components: {
      sidebar: () => import('@/views/PMS/nightAudit/nightAuditDaily'),
    },
  },
]
