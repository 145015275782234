import Vue from 'vue'
import App from './App.vue'
// 引入element ui
import ElementUI from 'element-ui';
Vue.use(ElementUI);

// 注册全局组件
import registerCom from './components/global/register'
Vue.use(registerCom)
//打印
import Print from '@/common/js/print.js'
Vue.use(Print)
// 引入vuex模块
import store from './store'

// 引入api模块
import './api/service'
import axios from "./api/request"
Vue.prototype.$axios = axios

// 引入图表模块
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
import 'echarts/map/js/china.js'

// 引入富文本模块
import VueTinymce from '@packy-tang/vue-tinymce'
Vue.use(VueTinymce)

// 引入右键弹出菜单模块
import VueContextMenu from 'vue-contextmenu'
Vue.use(VueContextMenu)

// 引入地图模块
import BaiduMap from 'vue-baidu-map'
Vue.config.productionTip = false
 Vue.use(BaiduMap, {
  ak: 'mNfWoetj2YgzZnGqhQilFgZI1Kw9zAc2'
});

import VueDND from 'awe-dnd'
Vue.use(VueDND)

import { VueJsonp  } from 'vue-jsonp'
Vue.use(VueJsonp) // $jsonp被挂载到vue原型上,可直接使用vm.$jsonp()

// 引入样式模块
import i18n from './common/i18n/i18n'
import './common/plugins/element.js'
import './assets/icon/iconfont.css'
import 'babel-polyfill' // 处理IE打不开问题

// 引入路由模块
import router from './router'
import './router/routerRight'
import Router from 'vue-router'
Vue.use(Router)
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err);
}

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
});
