/* 设置路由访问权限 */
import router from './index'

function getToken () {
   return sessionStorage.getItem('access_token')
}

router.beforeEach((to, from, next) => {
   if (!getToken()) {
      if (to.meta.token) {
         next({ path: '/login' })
      }else {
         next()
      }
   }else {
      next()
   }
})
