export default [
   {
      path: '/marketing_template',
      name:'marketingTemplate',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/smtech/marketingTemplate/list')
      },
   },
   {
      path: '/marketing_templat/add',
      name:'Add',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/smtech/marketingTemplate/add')
      },
   },
]
