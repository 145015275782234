export default[
   {
      path: '/pms/hardware/:type',
      name: 'hardwareType',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/PMS/hardwareDocking/hardwareType')
      }
   }
]
