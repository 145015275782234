export default [
    {
        path: '/room_set',
        name: 'roomSet',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/PMS/roomManage/roomSet')
        }
    },
    {
        path: '/room_type_manage',
        name: 'roomTypeManage',
        meta: { token: true },
        components: {
            sidebar:() => import('@/views/PMS/roomManage/roomTypeManage')
        }
    },
    {
        path: '/add_room_type_manage',
        name: 'addRoomTypeManage',
        meta: { token: true },
        components: {
            sidebar: () => import('@/views/PMS/roomManage/addRoomTypeManage')
        }
    }
]