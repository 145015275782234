export default [
   {
      path: '/shops_manage',
      name: 'shopsHome',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/business/shopsManage/shopsHome/home')
      }
   }, {
      path: '/goods_add_Edit',
      name: 'goodsAddEdit',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/business/shopsManage/goodsList/addEdit')

      }
   }, {
      path: '/goods_add_Edit1',
      name: 'goodsAddEdit1',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/business/shopsManage/goodsList/addEdit1')
      }
   }, {
      path: '/goods_list',
      name: 'goodsList',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/business/shopsManage/goodsList/list')
      }
   }, {
      path: '/goods_detail',
      name: 'goodsDetail',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/business/shopsManage/goodsList/detail')
      }
   }, {
      path: '/adress_list',
      name: 'adressList',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/business/shopsManage/adressList/adressList')
      }
   },{
      path: '/Theme_of_mall',
      name: 'ThemeOfMall',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/business/shopsManage/ThemeOfMall/ThemeOfMall')
      }
   }, {
      path: '/goods_category',
      name: 'categoryList',
      meta: { token: true },
      components: {
         sidebar:  () => import('@/views/business/shopsManage/goodsCategory/category')
      }
   }
]