export default [
   {
      path: '/companies_manage',
      name: 'companies',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/system/companies/companies')
      }
   }, {
      path: '/companies_add_edit',
      name: 'companiesAddEdit',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/system/companies/addEdit')
      }
   }, {
      path: '/org_manage',
      name: 'orgManage',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/system/org/org')
      }
   }, {
      path: '/role_manage',
      name: 'roleManage',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/system/role/role')
      }
   }, {
      path: '/user_manage',
      name: 'userManage',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/system/user/user')
      }
   }, {
      path: '/menu_manage',
      name: 'menuManage',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/system/menu/menu')
      }
   }, {
      path: '/data_dict',
      name: 'dataDictionary',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/system/dict/dict')
      }
   }, {
      path: '/app_edition',
      name: 'appEdition',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/system/app/app')
      }
   }, {
      path: '/inter_lang',
      name: 'interLang',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/system/language/lang')
      }
   }, {
      path: '/login_log',
      name: 'loginLog',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/system/log/loginLog')
      }
   }, {
      path: '/operation_log',
      name: 'operateLog',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/system/log/operateLog')
      }
   }, {
      path: '/online_users',
      name: 'onlineUsers',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/system/onlineUsers/onlineUsers')
      }
   }, {
      path: '/system_conf',
      name: 'systemConf',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/system/config/config')
      }
   }, {
      path: '/commercial',
      name: 'commercial',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/system/messageManagement/commercial')
      }
   }, {
      path: '/version_management',
      name: 'versionManagement',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/system/versionManagement/versionManagement')
      }
   }, {
      path: '/hardware_docking_record',
      name: 'hardwareDockingRecord',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/system/hardwareRecord/record')
      }
   },
   {
      path: '/ys_management',
      name: 'yinshengManage',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/system/merchant/yinshengManagement')
      }
   },
   {
      path: '/ys_management/pc_incoming',
      name: "pcIncoming",
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/system/merchant/pcIncoming')
      }
   },
   {
      path: '/ys_wallet',
      name: "yinshengWallet",
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/system/merchant/yinshengWallet')
      }
   },
   {
      path: '/hotel_index_config',
      name: "hotelIndexConfig",
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/system/hotelIndexConfig/list')
      }
   }
]
