export default [
   {
      path: '/agreementList',
      name: 'agreementList',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/business/agreementManage/agreementList')
      }
   },

]
