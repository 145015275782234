export default [
   {
      path: '/dashboard',
      name: 'dashboard',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/smtech/dashboard/dashboard')
      }
   },
   {
       path: '/configure',
       name: 'configure',
      meta: { token: true },
       components: {
          sidebar:() => import('@/views/smtech/dashboard/configure')
       }
    },
   {
      path: '/wechatconfig',
      name: 'wechatconfig',
      meta: { token: true },
      components: {
         sidebar:() => import("@/views/smtech/dashboard/newconfigure/wechatconfig")
      }
   },
   {
      path: '/wechatappletconfig',
      name: 'wechatappletconfig',
      meta: { token: true },
      components: {
         sidebar:() => import("@/views/smtech/dashboard/newconfigure/wechatappletconfig")
      }
   },
   {
      path: '/douyin-platform',
      name: 'douyin-platform',
      meta: { token: true },
      components: {
         sidebar:() => import("@/views/smtech/dashboard/newconfigure/douyin-platform")
      }
   }
]