export default [
   {
      path: '/sell_survey',
      name: 'sellSurvey',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/business/account/sellSurvey/sellSurvey')
      }
   },{
      path: '/Sales_details/:sell_survey',
      name: 'SalesDetails',
      meta: { token: true },
      components: {
         sidebar: () => import("@/views/business/account/SalesDetails/SalesDetails")
      }
   },{
      path: '/Sales_details',
      name: 'SalesDetails',
      meta: { token: true },
      components: {
         sidebar:() => import("@/views/business/account/SalesDetails/check")
      }
   },{
      path: '/split_order_list',
      name:'splitOrderList',
      meta: { token: true },
      components: {
         sidebar:() => import("@/views/business/account/appShare/splitOrderList")
      },
   },
   {
      path: '/bills',
      name:'bills',
      meta: { token: true },
      components: {
         sidebar:() => import("@/views/business/account/bills/bills")
      },
   },
]
