import service from "@/api/service";
import qs from 'qs'
import axios from "axios";

/*
 * get请求
 * @url *请求地址
 * @param *请求参数
 * @headers 设置请求头
 * @responseType 响应类型
*/
export function get(url, params, headers, responseType) {
   return new Promise((resolve, reject) => {
      if (typeof url !== 'string' || params.constructor !== Object) {
         throw new Error('参数类型错误或为空值')
      }
      service.get(url, { params, headers, responseType }).then(res => resolve(res))
         .catch(err => reject(err))
   })
}

/*
 * post请求
 * @url *请求地址
 * @param *请求参数
 * @args 设置请求头属性 参数类型为字符串/对象
 * 参数的值：默认=formData类型 file=文件类型 json=json类型/{ key: val }
*/
export function post(url, param, ...args) {
   return new Promise((resolve, reject) => {
      if (typeof url !== 'string' && param.constructor !== Object && !(param instanceof FormData)) {
         throw new Error(`参数类型错误:url->${url},param->${param}`)
      }
      if (!args.every(item => item.constructor === String || item.constructor === Object)) {
         throw new Error(`参数类型错误:args->${args}`)
      }
      const config = { headers: {} }
      let params = qs.stringify(param)
      args.forEach(item => {
         if (item.constructor === String) {
            if (item.toLocaleLowerCase() === 'file') params = param, config['headers']['Content-Type'] = 'multipart/form-data'
            if (item.toLocaleLowerCase() === 'json') params = param
         }
         if (item.constructor === Object) for (let key in item) config['headers'][key] = item[key]
      })
      service.post(url, params, config).then(res => resolve(res))
         .catch(err => reject(err))
   })
}

/*
 * 并发请求
 * @request 请求队列 类型：map
*/
export function all(request) {
   return new Promise((resolve, reject) => {
      axios.all(request).then(axios.spread(() => {
         Promise.all(arguments[0].values()).then(resList => {
            const res = new Map()
            Array.from(arguments[0].keys()).forEach((item, index) => res.set(item, resList[index]))
            resolve(res)
         })
      })).catch(err => reject(err))
   })
}

export default {
   get,
   post,
   all
}
