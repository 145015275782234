export default [
   {
      path: '/power_saving',
      name: 'powerSaving',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/AIOT/energyManage/powerSaving/list')
      }
   },
   {
      path: '/create_tasks',
      name: 'createTasks',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/AIOT/energyManage/powerSaving/create')
      }
   }, {
      path: '/electricity_statistics',
      name: 'electricityStatistics',
      meta: { token: true },
      components: {
         sidebar:  () => import('@/views/AIOT/energyManage/electricityManage/electricityStatistics')
      }
   }, {
      path: '/electricity_warn',
      name: 'electricityWarn',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/AIOT/energyManage/electricityManage/electricityWarn')
      }
   }, {
      path: '/electricity_line',
      name: 'electricityLine',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/AIOT/energyManage/electricityManage/electricityLine')
      }
   }
]