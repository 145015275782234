export default [
   {
      path: '/activities_analysis',
      name: 'activitiesAnalysis',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/smtech/analysis/activitiesAnalysis')
      }
   }, {
      path: '/activity_analysis',
      name: 'activityAnalysis',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/smtech/analysis/activityAnalysis')
      }
   }, {
      path: '/channel_analysis',
      name: 'channelAnalysis',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/smtech/analysis/channelAnalysis')
      }
   }, {
      path: '/social_analysis',
      name: 'socialAnalysis',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/smtech/analysis/socialAnalysis')
      }
   }, {
      path: '/operate_analysis',
      name: 'operateAnalysis',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/smtech/analysis/operateAnalysis')
      }
   },
]