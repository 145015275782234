export default [
   {
      path: '/preferential_mall/goods/:type',
      name: 'goodsList',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/goodsCenter/list')
      }
   }, {
      path: '/preferential_mall/goods_create',
      name: 'goodsCreate',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/goodsCenter/create')
      }
   }, {
      path: '/preferential_mall/goods_category',
      name: 'goodsCategory',
      meta: { token: true },
      components: {
         sidebar: () => import('@/views/shop/goodsCenter/category')
      }
   },
]
