export default [
   {
      path: '/platform_management',
      name:'platformManagement',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/smtech/noteManagement/platformManagement/list')
      },
   },
   {
      path: '/balance_management',
      name:'balanceManagement',
      meta: { token: true },
      components: {
         sidebar:() => import('@/views/smtech/noteManagement/balanceManagement/list')
      },
   }
]
