import axios from 'axios'
import { urlObj } from "@/api/interface";
import { post } from "@/api/request";
import { Message } from 'element-ui'

const service = axios.create({
   baseURL: process.env.VUE_APP_BASE_API,
   timeout: 60000,
})

// 请求拦截
service.interceptors.request.use(
   config => {
      config.headers.Authorization = sessionStorage.getItem('access_token') || '';
      config.headers.clientType = 'WEB'
      config.headers.lang = localStorage.getItem('lang')
      return config
   },
   error => {
      return Promise.reject(error)
   }
)

// 响应拦截
service.interceptors.response.use(
   response => {
      let res = response.data
      return Promise.resolve(res)
   },
   error => {
      let err = error.response
      if (err.data && err.data.code === 'DYNAMIC_ERROR') {
         // 部分接口在页面自定义消息提醒
      } else {
         Message({ message: err.data.message, type: 'error' })
         if (err.status === 401) getRefreshToken()
      }
      return Promise.reject(err)
   }
)

// 获取刷新token
function getRefreshToken() {
   let refreshToken = localStorage.getItem('refresh_token')
   if (refreshToken) {
      const url = urlObj.refreshToken
      const param = {
         client_id: 'hored',
         client_secret: '74a6e749620148e4ab2e1bb3b7267afe',
         loginMethod: 'ACCOUNT',
         source: 'WEB',
         grant_type: 'refresh_token',
         refresh_token: refreshToken
      }
      post(url, param).then(res => {
         sessionStorage.setItem('access_token', res.access_token)
         localStorage.setItem('refresh_token', res.refresh_token)
         location.reload()
      }).catch(() => window.location = '/login')
   }
}

export default service

