<!-- 曲线图 -->
<template>
  <div :id="chartId"></div>
</template>

<script>
  export default {
    name: 'curveChart',
    props: {
      // 名称
      name: {
        type: String,
        default: '会员人数',
      },
      // id编号
      chartId: {
        type: String,
        default: 'myChart',
      },
      // 颜色
      color: {
        type: String,
        default: '#FFBF00',
      },
      // X轴坐标
      xAxis: {
        type: Array,
        default: () => {
          return ['2021.1.25', '2021.1.26', '2021.1.27', '2021.1.28', '2021.1.29', '2021.1.30', '2021.1.31']
        },
      },
      // 数据
      series: {
        type: Array,
        default: () => {
          return [220, 302, 181, 234, 210, 290, 150]
        },
      },
    },
    methods: {
      drawChart() {
        let chart = {
          color: this.color,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985',
              },
            },
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.xAxis,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dotted',
              },
            },
            axisLabel: {
              interval: 0,
            },
          },
          yAxis: {
            type: 'value',
            splitLine: {
              lineStyle: {
                type: 'dotted',
              },
            },
          },
          series: {
            name: this.name,
            type: 'line',
            stack: '总量',
            smooth: true,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            label: {
              show: true,
              position: 'top',
            },
            areaStyle: {
              opacity: 0.8,
              color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(255, 191, 0)',
                },
                {
                  offset: 1,
                  color: 'rgba(224, 62, 76)',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: this.series,
          },
        }
        setTimeout(() => {
          this.$echarts.init(document.getElementById(this.chartId)).setOption(chart)
        }, 200)
      },
    },
  }
</script>
