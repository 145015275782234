export const urlObj = {
   // 获取刷新token
   refreshToken: '/oauth2/oauth/token',
   // 获取token
   'loginToken': '/oauth2/authorize/login',
   // 用户信息
   'getUser': '/user/findUserByLoginName',
   // 设备统计
   'getDevice': '/device/queryApDeviceTotalCount',
   // 网关统计
   'getGateway': '/device/queryApGatewayTotalCount',
   // 设备在线数量统计
   'devOnline': '/device/queryApDeviceDateCount',
   // 设备数量统计
   'devNum': '/device/queryApDeviceCountMap',
   // 酒店 设备管理(分页)
   'devManagePage': '/device-hotel/device/getPage',
   // 家居 设备管理(分页)
   'devHomeManagePage': '/device-home/device/getPage',
   // 酒店 设备下的分类
   'devPageForPc': '/device-hotel/device/getPageForPc',
   // 家居 设备下的分类
   'devHomePageForPc': '/device-home/device/getPageForPc',
   // 酒店 场景列表
   'getScenePage': '/device-hotel/sceneConfig/getPageForPc',
   // 家居 场景列表
   'getHomeScenePage': '/device-home/sceneConfig/getPageForPc',
   // 设备管理(不分页)
   'devManageList': '/device-hotel/device/getList',
   // 酒店 网关下的设备(不分页)
   'getByGatewayId': '/device-hotel/device/getListForPc',
   // 家居 网关下得设备(不分页)
   'getHomeByGatewayId': '/device-home/device/getListForPc',
   // 酒店 红外宝下的子设备列表
   'devManageListGetPage': '/device-hotel/infrared/getPage',
   // 家居 红外宝下的子设备列表
   'devHomeManageListGetPage': '/device-home/infrared/getPage',
   // 酒店 红外宝下的指令
   'devInfraredDeviceId': '/device-hotel/infraredRefCmd/getInfraredRefCmdByChildDeviceId',
   // 家居 红外宝下的指令
   'devHomeInfraredDeviceId': '/device-home/infraredRefCmd/getInfraredRefCmdByChildDeviceId',
   // 获取酒店
   'getAllHotelList': '/hotel/queryApAllHotelList',
   // 楼层下拉框(设备管理)
   'getFloorList': '/hotel/queryApHotelById',
   // 目标房间
   'getTargetRoom': '/room/getTargetRoom',
   // 酒店 设备详情(设备管理)
   'getDevDetail': '/device-hotel/device/getDeviceDetailsByDeviceIdForPc',
   // 家居 设备详情(设备管理)
   'getHomeDevDetail': '/device-home/device/getDeviceDetailsByDeviceIdForPc',
   // 网关详情(设备管理)
   'getGatDetail': '/device/findGatewayById',
   
   // 详情-->状态(设备管理)
   'getDevStatusList': '/device/getDeviceStatusList',
   
   // 酒店 详情-->更多状态(设备管理)
   'getDevMoreStatusList': '/device-hotel/deviceState/getPage',
   // 家居 详情-->更多状态(设备管理)
   'getHomeDevMoreStatusList': '/device-home/deviceState/getPage',
   // 酒店 WIFI温控面板(获取)
   'getDevTemperature': '/device-hotel/tempPanel/getTempPanelByDeviceId',
   // 家居 WIFI温控面板(获取)
   'getHomeDevTemperature': '/device-home/tempPanel/getTempPanelByDeviceId',
   // 家居 WIFI温控面板(设置)
   'setHomeDevTemperature': '/device-home/tempPanel/operateTemp',
   // 酒店 编辑(设备保存)
   'updateDevice': '/device-hotel/device/updateDevice',
   // 家居 编辑(设备保存)
   'updateHomeDevice': '/device-home/device/updateDevice',
   // 酒店 删除设备
   'deleteDeviceById': '/device-hotel/device/deleteDeviceByDeviceId',
   // 家居 删除设备
   'deleteHomeDeviceById': '/device-home/device/deleteDeviceByDeviceId',
   // 酒店 场景获取设备
   'getDeviceRoomId': '/device-hotel/device/getDeviceInfraredListByRoomId',
   // 酒店 场景获取设备(编辑)
   'getEditDeviceRoomId': '/device-hotel/device/getDeviceInfraredListNoExistSceneId',
   // 家居 场景获取设备
   'getHomeDeviceRoomId': '/device-home/device/getDeviceInfraredListByUserId',
   // 家居 场景获取设备(编辑)
   'getHomeEditDeviceRoomId': '/device-home/device/getDeviceInfraredListNoExistSceneId',
   // 酒店 获取设备状态(开关状态)
   // 家居 获取设备状态(开关状态)
   //'getHomeDevStatus': '/device-home/deviceSwitch/getDeviceSwitchByDeviceId',
   'getHomeDevStatus': '/device-home/device/getDeviceDetailsByDeviceIdForPc',
   
   
   // 酒店 保存设备别名
   'updateSwitchAlias': '/device-hotel/deviceSwitch/batchUpdateDeviceSwitch',
   // 家居 保存设备别名
   'updateHomeSwitchAlias': '/device-home/deviceSwitch/batchUpdateDeviceSwitch',
   // 家居 保存设备状态
   'updateHomeSwitchStatus': '/device-home/deviceSwitch/batchOperatDeviceSwitchState',
   // 酒店 更新设备信息
   'updateDeviceForPc': '/device-hotel/device/updateDeviceForPc',
   // 家居 更新设备信息
   'updateHomeDeviceForPc': '/device-home/device/updateDeviceForPc',
   // 更新开关数据
   'updateDeviceSwitch': '/device-hotel/device/deviceSwitch/updateDeviceSwitch',
   // 编辑(网关保存)
   'updateGateway': '/device/updateGateway',
   // 获取租户/商户
   'getStoreList': '/hotel/getStoreList',
   // 子设备(设备管理)
   'getChildDevList': '/device/getDeviceList',
   // 酒店 开关按钮(设备管理)
   'setSwitchState': '/device-hotel/device/operateSwitch',
   // 酒店设置设备状态
   'setSendIotMsg': '/device-hotel/device/sendIotMsg',
   // 家居设置设备状态
   'setHomeSendIotMsg': '/device-home/device/sendIotMsg',
   // 酒店 一键唤醒
   'devicWakeUp': '/device-hotel/device/reboot',
   // 家居 一键唤醒
   'homeDevicWakeUp': '/device-home/device/reboot',
   // WIFI面板
   'setTemControl': '/device/operateApTemperature',
   // 房间设备
   'getRoomList': '/device-hotel/device/getRoomDeviceStateStatistics',
   'getRoomDevicePage': '/hotel/room/getRoomDevicePage',
   // 酒店 场景配置(不分页)
   'getSceneList': '/device-hotel/sceneConfig/getList',
   // 家居 场景配置(不分页)
   'getHomeSceneList': '/device-home/sceneConfig/getList',
   // 获取场景模式(新建场景--输入框)
   'getExecuteDevice': '/scene/queryApSceneModeByHotelId',
   // 添加场景模式
   'addSceneModel': '/scene/addApSceneMode',
   // 添加场景
   'addApScene': '/scene/addApScene',
   // 酒店 添加场景(新的)
   'addSceneConfig': '/device-hotel/sceneConfig/addSceneConfig',
   // 家居 添加场景(新的)
   'addHomeSceneConfig': '/device-home/sceneConfig/addSceneConfig',
   // 酒店 修改场景(新的)
   'updateSceneConfig': '/device-hotel/sceneConfig/updateSceneConfig',
   // 家居 修改场景(新的)
   'updateHomeSceneConfig': '/device-home/sceneConfig/updateSceneConfig',
   // 酒店 场景批量删除
   'deleteBatchScene': '/device-hotel/sceneConfig/batchDeleteScene',
   // 家居 场景批量删除
   'deleteHomeBatchScene': '/device-home/sceneConfig/batchDeleteScene',
   // 酒店 场景批量锁定，解锁接口
   'sceneBatchLock': '/device-hotel/sceneConfig/batchSceneSwitchLocked',
   // 家居 场景批量锁定，解锁接口
   'homeSceneBatchLock': '/device-home/sceneConfig/batchSceneSwitchLocked',
   // 修改样板房
   'modifyRoomTemplate': '/roomTemplate/modifyRoomTemplate',
   // 分页查询样板房
   'queryRoomTemplatePager': '/roomTemplate/queryRoomTemplatePager',
   // 不分页查询样板房列表
   'queryRoomTemplateList': '/roomTemplate/queryRoomTemplateList',
   // 匹配接口
   'sceneMatch': '/scene/sceneMatch',
   // 场景复制，按房间复制
   'sceneCopyApBy': '/scene/copyApByRoomId',
   // 匹配接口(新的)
   'sceneTest': '/device-hotel/sceneConfig/sceneMatch',
   // 场景复制，按房间复制(新的)
   'sceneClone': '/device-hotel/sceneConfig/sceneClone',
   //  单个场景匹配
   'sceneMatchBySceneId': '/scene/sceneMatchBySceneId',
   //  单个场景匹配(新的)
   'singleSceneMatch': '/device-hotel/sceneConfig/singleSceneMatch',
   //  单个场景复制(新的)
   'sceneCloneBySceneId': '/device-hotel/sceneConfig/sceneCloneBySceneId',
   //  根据设备mac，查设备详情
   'deviceName': '/device/findDeviceByDeviceName',
   // 酒店 场景状态改变
   'sceneState': '/device-hotel/sceneConfig/sceneSwitchState',
   // 家居 场景状态改变
   'homeSceneState': '/device-home/sceneConfig/sceneSwitchState',
   // 酒店 场景锁定状态改变
   'switchLocked': '/device-hotel/sceneConfig/sceneSwitchLocked',
   // 家居 场景锁定状态改变
   'switchHomeLocked': '/device-home/sceneConfig/sceneSwitchLocked',
   // 酒店 场景同步网关
   'SyncGateway': '/device-hotel/sceneConfig/sceneSyncGateway',
   // 家居 场景同步网关
   'homeSyncGateway': '/device-home/sceneConfig/sceneSyncGateway',
   // 酒店 场景同步小程序,语音
   'sceneSynctSceneSync': '/device-hotel/sceneSync/setSceneSync',
   // 家居 场景同步小程序,语音
   'sceneHomeSynctSceneSync': '/device-home/sceneSync/setSceneSync',
   // 删除场景小程序,语音
   'deleteSceneSyncById': '/device-hotel/sceneSync/deleteSceneSyncById',
   // 编辑--保存场景(PC端)
   'modifyScene': '/scene/modifyScene',
   // 编辑--保存场景(APP端)
   'modifyAppScene': '/scene/modifyAppScene',
   // 复制场景
   'copyApScene': '/scene/copyApScene',
   // 克隆场景
   'singleSceneClone': '/device-hotel/sceneConfig/singleSceneClone',
   // 模糊复制场景
   'sceneDimCopy': '/device-hotel/sceneConfig/sceneDimCopy',
   // 酒店 场景详情
   'sceneDetail': '/device-hotel/sceneConfig/getSceneDetailsBySceneIdForPc',
   // 家居 场景详情
   'homeSceneDetail': '/device-home/sceneConfig/getSceneDetailsBySceneIdForPc',
   // 酒店 删除场景
   'delScene': '/device-hotel/sceneConfig/deleteSceneConfig',
   // 家居 删除场景
   'delHomeScene': '/device-home/sceneConfig/deleteSceneConfig',
   // 酒店 删除场景下的设备
   'deleteSceneDeviceById': '/device-hotel/sceneDevice/deleteSceneDeviceById',
   // 家居 删除场景下的设备
   'deleteHomeSceneDeviceById': '/device-home/sceneDevice/deleteSceneDeviceById',
   // 酒店 修改场景下的设备
   'updateSceneDevice': '/device-hotel/sceneDevice/updateSceneDevice',
   // 家居 修改场景下的设备
   'updateHomeSceneDevice': '/device-home/sceneDevice/updateSceneDevice',
   // 查询场景下的设备
   'getListBySceneId': '/device-hotel/sceneDevice/getListBySceneId',
   // 获取触发设备
   'getTriggerDevList': '/device/findDeviceListByRoomId',
   // 字典管理
   'getDictList': '/dictionary/getDictionaryPager',
   // 新建字典
   'addDictionary': '/dictionary/addDictionary',
   // 删除字典
   'delDictionary': '/dictionary/deleteDictionary',
   // 获取字典
   'getDictionary': '/dictionary/findDictionaryById',
   // 修改字典
   'modifyDictionary': '/dictionary/modifyDictionary',
   // 操作日志
   'getLogList': '/device-hotel/deviceLog/getPage',
   // 酒店 设备报警
   'getAlarmList': '/device-hotel/deviceLog/getPage',
   // 家居 设备报警
   'getHomeAlarmList': '/device-home/deviceLog/getPage',
   // 客户服务
   'getCusServiceList': '/device-hotel/deviceLog/getPage',
   // 酒店管理(带分页)
   'getHotelPage': '/hotel/getPage',
   // 酒店 管理(不分页)
   'getHotelList': '/hotel/getList',
   // 新建酒店
   'addHotel': '/hotel/add',
   // 酒店 新建酒店下保存信息
   'addHotelDetails': '/hotel/hotel-details/add',
   // 家居 新建家居下保存信息
   'addHomeDetails': '/home/home-details/add',
   // 新建酒店下修改信息
   'editHotelDetails': '/hotel/hotel-details/update',
   // 设置通知模块
   'eventNotify': '/event/eventNotify/insert',
   // 新增酒店小程序菜单
   'addHotelMenu': '/hotel/addHotelMenu',
   // 获取酒店下面已有菜单
   'menuHotelDetail': '/hotel/getHotelMenuListByHotelId',
   // 酒店详情
   'hotelDetail': '/hotel/getHotel',
   // 家居详情
   'homeDetail': '/home/getHotel',
   // 酒店第二步详情
   'getHotelDetail': '/hotel/hotel-details/getDetails',
   // 删除酒店
   'deleteHotel': '/hotel/delete',
   // 酒店编辑
   'modifyHotel': '/hotel/edit',
   // 开启分账
   openProfiting: '/payment/wechat/openProfitshring',
   // 酒店房间类型(获取)
   'getHoteRoomType': '/hotel/queryApAllRoomType',
   // 绑定酒店房型(获取)
   'bindRoomTypes': '/hotel/roomtype/bindRoomTypes',
   // 酒店 绑定房间类型(获取)
   'getHotelRoomTypeList': '/hotel/roomtype/getHotelRoomTypeList',
   // 家居 绑定房间类型(获取)
   'getHomeRoomTypeList': '/home/roomtype/getHomeRoomTypeList',
   // 删除绑定的房型
   'deleteHotelRoomType': '/hotel/roomtype/deleteHotelRoomType',
   // 批量删除绑定的房型
   'deleteHotelRoomTypes': '/hotel/roomtype/deleteHotelRoomTypes',
   // 酒店房间类型(添加)
   'addHoteRoomType': '/hotel/addApRoomType',
   // 增加酒店房型(增加中英文)
   'addChHoteRoom': '/hotel/addApRoomTypeByHotelId',
   // 修改酒店房型(增加中英文)
   'modifyChHoteRoom': '/hotel/modifyRoomTypeById',
   // 删除酒店房型(增加中英文)
   'delChHoteRoom': '/hotel/deleteRoomTypeById',
   // 房型管理分页
   'getRoomTypePages': '/hotel/room/roomtype/getRoomTypePages',
   // 酒店下增加房型并绑定
   'addBindRoomType': '/hotel/room/roomtype/bindRoomType',
   // 增加房型管理
   'addRoomType': '/hotel/room/roomtype/addRoomType',
   // 修改房型管理
   'editRoomType': '/hotel/room/roomtype/editRoomType',
   // 删除房型管理
   'deleteRoomType': '/hotel/room/roomtype/deleteRoomType',
   // 房型管理详情
   'getRoomTypeDetail': '/hotel/room/roomtype/getRoomTypeDetail',
   // 样板房不分页
   'getRoomTemplateList': '/hotel/room-template/getList',
   // 样板房分页
   'getRoomTemplatePages': '/hotel/room-template/getPage',
   // 增加样板房
   'addRoomTemplate': '/hotel/room-template/add',
   // 修改样板房
   'editRoomTemplate': '/hotel/room-template/edit',
   // 酒店 删除样板房
   'deleteRoomTemplate': '/hotel/room-template/del',
   // 家居 删除样板房
   'deleteHomeRoomTemplate': '/home/room-template/del',
   // 样板房详情
   'getRoomTemplateDetail': '/hotel/room-template/details',
   // 查询房型模板列表
   'getTemplateList': '/hotel/room-template/getList',
   // 设施管理不分页
   'getFacilityList': '/hotel/facility/getList',
   // 设施管理分页
   'getFacilityPages': '/hotel/facility/getPage',
   // 增加设施管理
   'addFacility': '/hotel/facility/add',
   // 修改设施管理
   'editFacility': '/hotel/facility/edit',
   // 删除设施管理
   'deleteFacility': '/hotel/facility/del',
   // 设施管理详情
   'getFacilityDetail': '/hotel/facility/details',
   // pms管理不分页
   'getPmsList': '/hotel/pms/getList',
   // pms管理分页
   'getPmsPages': '/hotel/pms/getPage',
   // 增加pms管理
   'addPms': '/hotel/pms/add',
   // 修改pms管理
   'editPms': '/hotel/pms/edit',
   // 删除pms管理
   'deletePms': '/hotel/pms/del',
   // pms管理详情
   'getPmsDetail': '/hotel/pms/getDetails',
   // 酒店下新增绑定pms
   'addHotelPms': '/hotel/hotel-pms/add',
   // 酒店下绑定pms列表
   'getHotelPms': '/hotel/hotel-pms/getHotelPms',
   // 酒店 产品分类不分页
   'categoryList': '/device-hotel/productCategory/getList',
   // 家居 产品分类不分页
   'getHomeCategoryList': '/device-home/productCategory/getList',
   // 酒店 产品分类分页
   'getCategoryPages': '/device-hotel/productCategory/getPage',
   // 家居 产品分类分页
   'getHomeCategoryPages': '/device-home/productCategory/getPage',
   // 酒店 增加产品分类
   'addCategory': '/device-hotel/productCategory/addProductCategory',
   // 家居 增加产品分类
   'addHomeCategory': '/device-home/productCategory/addProductCategory',
   // 酒店 修改产品分类
   'editCategory': '/device-hotel/productCategory/updateProductCategory',
   // 家居 修改产品分类
   'editHomeCategory': '/device-home/productCategory/updateProductCategory',
   // 酒店 删除产品分类
   'deleteCategory': '/device-hotel/productCategory/deleteProductCategory',
   // 家居 删除产品分类
   'deleteHomeCategory': '/device-home/productCategory/deleteProductCategory',
   // 酒店 产品分类详情
   'getCategoryDetail': '/device-hotel/productCategory/getProductCategoryById',
   // 家居 产品分类详情
   'getHomeCategoryDetail': '/device-home/productCategory/getProductCategoryById',
   // 产品列表不分页
   'productList': '/device-hotel/product/getList',
   // 酒店 产品列表分页
   'getProductPages': '/device-hotel/product/getPage',
   // 家居 产品列表分页
   'getHomeProductPages': '/device-home/product/getPage',
   // 酒店 增加产品列表
   'addProduct': '/device-hotel/product/addProduct',
   // 家居 增加产品列表
   'addHomeProduct': '/device-home/product/addProduct',
   // 酒店 修改产品列表
   'editProduct': '/device-hotel/product/updateProduct',
   // 家居 修改产品列表
   'editHomeProduct': '/device-home/product/updateProduct',
   // 酒店 删除产品列表
   'deleteProduct': '/device-hotel/product/deleteProduct',
   // 家居 删除产品列表
   'deleteHomeProduct': '/device-home/product/deleteProduct',
   // 酒店 产品列表详情
   'getProductDetail': '/device-hotel/product/getProductById',
   // 家居 产品列表详情
   'getHomeProductDetail': '/device-home/product/getProductById',
   // 部门管理
   'getDeptList': '/dept/queryApDeptPager',
   // 'getDeptList': '/dept/getDeptList',
   // 获取部门
   'getDetpList': '/dept/getDeptListByHotelId',
   // 新建部门
   'addDetp': '/dept/addApDept',
   // 部门详情
   'deptDetail': '/dept/findDetailsDeptById',
   // 删除部门
   'delDept': '/dept/deleteDept',
   // 编辑部门
   'modifyDept': '/dept/modifyApDept',
   // 房间管理(带分页)
   'getRoomListByHotel': '/room/queryApRoomPagerByHotel',
   // 房间列表分页
   'roomPage': '/hotel/room/getRoomPage',
   // 'getRoomListByHotel': '/room/getRoomListByHotel',
   // 酒店 房间管理(无分页)
   'getRoomLists': '/hotel/room/getRoomList',
   // 家居 房间管理(无分页)
   'getHomeRoomLists': '/home/room/getRoomList',
   
   'getRoomListNoPage': '/room/queryApRoomListByHotel',
   'getCopyRoom': '/room/getCopyRoom',
   // 新建房间
   'addRoom': '/hotel/room/add',
   // 批量新建房间
   'batchAddRoom': '/hotel/room/addRooms',
   // 模拟入住
   'userCheckIn': '/hotel/hotelCheckin/checkIn',
   // 酒店 获取房间信息
   'getRoom': '/hotel/room/getRoom',
   // 修改房间
   'editRoom': '/hotel/room/editRoom',
   // 删除房间
   'delRoom': '/hotel/room/deleteRoom',
   // 楼栋管理(无分页)
   'getBuildList': '/hotel/build/getBuildList',
   // 新建楼栋
   'addbuild': '/hotel/build/addBuild',
   // 获取楼栋
   'getbuild': '/hotel/build/getBuildDetail',
   // 修改楼栋
   'editbuild': '/hotel/build/editBuild',
   // 删除楼栋
   'delbuild': '/hotel/build/deleteBuild',
   // 酒店 楼栋单元联动
   'buildAndUnitList': '/hotel/build/getBuildAndUnitList',
   // 单元管理(无分页)
   'getBuildUnitList': '/hotel/buildunit/getBuildUnitList',
   
   // 新建单元
   'addUnit': '/hotel/buildunit/addBuildUnit',
   // 酒店 获取单元
   'getUnit': '/hotel/buildunit/getBuildUnitDetail',
   // 家居 获取单元
   'getHomeUnit': '/home/buildunit/getBuildUnitDetail',
   // 修改单元
   'editUnit': '/hotel/buildunit/editBuildUnit',
   // 删除单元
   'delUnit': '/hotel/buildunit/deleteBuildUnit',
   // 语音管理
   'getVoicePages': '/hotel/voice/getHotelVoicePages',
   // 添加语音
   'addHotelVoice': '/hotel/voice/addHotelVoice',
   // 删除语音
   'deleteHotelVoice': '/hotel/voice/deleteHotelVoice',
   // 修改语音
   'editHotelVoice': '/hotel/voice/editHotelVoice',
   // 语音详情
   'getRoomNo': '/hotel/voice/getRoomNo',
   // 语音详情
   'getDetails': '/hotel/voice/getDetails',
   // 天猫管理
   'getTmallList': '/voice/queryApIotPager',
   // 'getTmallList': '/voice/getIotPager',
   // 添加天猫
   'addTmall': '/voice/addIot',
   // 删除天猫
   'delTmall': '/voice/deleteIot',
   // 修改天猫
   'modifyTmall': '/voice/modifyIot',
   // 小度管理
   'getSmallList': '/voice/queryApXiaoduPager',
   // 'getSmallList': '/voice/getXiaoduPager',
   // 添加小度
   'addSmall': '/voice/addXiaodu',
   // 删除小度
   'delSmall': '/voice/deleteXiaodu',
   // 修改小度
   'modifySmall': '/voice/modifyXiaodu',
   // 用户管理
   'getUserList': '/user/queryApUserPager',
   //用户管理  没有分页
   'user': '/system/user/getList',
   // 新建用户
   'addUser': '/user/addSysUser',
   // 用户详情
   'userDetail': '/user/findSysUserById',
   // 删除用户
   'delUser': '/user/deleteSysUserById',
   // 修改用户
   'modifyUser': '/user/modifySysUser',
   // 修改用户密码
   'modifyUserPwd': '/user/updateSysUser',
   // 分配角色
   'setRole': '/user/addUserRole',
   // 角色下拉(分配角色)
   'getRole': '/role/findAllRoleList',
   // 角色管理
   'getRoleList': '/role/getRolePager',
   // 'getRoleList': '/role/getRoleList',
   // 新建角色
   'addRole': '/role/addApRole',
   // 删除角色
   'delRole': '/role/deleteRoleById',
   // 修改角色
   'modifyRole': '/role/modifyApRole',
   // 获取角色菜单
   'getRoleMenu': '/role/findMenuListByRoleId',
   // 分配权限
   'roleAllot': '/role/addRoleMenu',
   // 菜单管理(带分页)
   'getMenuList': '/menu/findMenuPager',
   // 菜单管理(分配权限)
   'getMenuTree': '/menu/findAllMenuTree',
   // 新建菜单
   'addMenu': '/menu/addApMenu',
   // 菜单详情
   'menuDetail': '/menu/findMenuDetailsById',
   // 修改菜单
   'modifyMenu': '/menu/updateApMenu',

// 酒店管理
   // 获取酒店房型照片列表
   'roomTypeImgs': '/hotel/roomtype/getImgs',
   // 上传酒店房型照片
   'uploadRoomTypeImg': '/hotel/roomtype/upload',
   // 获取小程序选中菜单
   'getAppletMenu': '/system/hotel-menu/getAuthRoleMenus',
   // 小程序菜单授权给酒店
   'authAppletMenu': '/system/hotel-menu/authRoleMenu',
   // 同步人脸识别设备
   'synchroDevices': '/hotel/facelock/device/getPage',
   // 同步人脸识别设备
   'synchronousDevice': '/hotel/facelock/device/synchronousDevice',

// 酒店管理
   // 房型管理不分页
   'getRoomTypeList': '/hotel/room/roomtype/getRoomTypeList',
   'roomTypeList': '/hotel/room/roomtype/getList',
   // 保存酒店房型选框
   'batchBindRoomType': '/hotel/roomtype/batchBindRoomType',
   // 根据酒店ID获取房型列表
   'getHotelCheckRoomTypeList': '/hotel/room/roomtype/geconversiontHotelCheckRoomTypeList',
   // 删除酒店列表PMS配置
   'delHotelPms': '/hotel/hotel-pms/deleteHotelPms',
   // 获取酒店支付配置信息
   'hotelCfg': '/hotel/hotel-cfg/query',
   // 保存酒店支付配置信息
   'saveHotelPayCfg': '/hotel/hotel-cfg/add',
   // 获取语音用户列表
   'voiceUsers': '/system/user/getVoiceUsers',
   // 删除图片接口
   'delImg': '/hotel/roomtype/deleteImg',
   // 上传人脸识别照片
   'addHousehold': '/hotel/facelock/device/addHousehold',
   // 人脸识别设备绑定房间
   'bindRoom': '/hotel/facelock/device/bindRoom',
   // 获取入住客人记录
   'hotelCheckinList': '/hotel/hotelCheckin/getHotelCheckinByMemberPages',
   // 获取省\市\县地区列表
   'regionList': '/system/region/getList',

// 酒店设备
   // 删除场景已触发设备
   'delSceneDevice': '/device-hotel/sceneDevice/deleteSceneDeviceById',
   // 验证码
   'verifyCode': '/oauth2/image-code',
   // 退出登录
   'logout': '/oauth2/logout',
   // 修改密码
   'modifyPwd': '/system/account/modifyPassword',
   // 获取手机验证码
   'mobileCode': '/oauth2/mobile-code',
   // 手机号登录
   'mobileLogin' :'/oauth2/authorize/mobile',
   // 获取二维码
   'getLatestQRCode': '/system/appversion/getLatestQRCode',

// 首页
   //合作数据
   'queryStatistic':'/hadoop/companyStatistics/queryStatistic',
   //地区数据
   'getStatisticByProvince':'/hadoop/hotelData/getStatisticByProvince',
   // 酒店 设备数据统计
   'statistics': '/device-hotel/device/statistics',
   // 家居 设备数据统计
   'homeStatistics': '/device-home/device/statistics',
   // 酒店 设备新增数据统计
   'getStatisticsByDate': '/device-hotel/device/statisticsByDate',
   // 家居 设备新增数据统计
   'homeStatisticsByDate': '/device-home/device/statisticsByDate',
   // 获取订单状态
   'getOrderStatus': '/order/getOrderStatus',
   // 获取订单来源
   'orderSource': '/order/getOrderSource',
   // 获取订单统计
   'orderStatistics': '/order/statisticsByDate',
   
   /* 商城管理 */
   // 获取首页报表统计
   'shopStatistics': '/order/getStoreStatistics',
   // 获取商品分页列表
   'getGoodsPaging': '/goods/getPage',
   // 上下架商品
   'updateStatus': '/goods/updateStatus',
   // 删除商品
   'delGoods': '/goods/delete',
   // 添加商品
   'addGoods': '/goods/save',
   // 编辑商品
   'editGoods': '/goods/update',
   // 获取商品详情
   'getGoodsDetails': '/goods/get',
   // 获取商品二级分类目录
   'getSecondCategory': '/goods/companyCategory/get',
   
   // 保存商家配送配置
   'saveConfig': '/hotel/distributionConfig/save',
   // 获取商品分类
   'getGoodsCategory': '/goods/category/getList',
   // 保存商品分类
   'saveCategory': '/goods/category/save',
   // 上传商品详情图
   'uploadDetailFile': '/system/attachment/uploadFile',
   // 上传商品详情图
   'upload': '/system/attachment/upload',
   // 查询商家余额
   'queryCompanyIncome': '/order/company-income/queryCompanyIncome',
   // 申请提现
   'extract': '/order/extract-apply/insert',
   // 获取账单流水
   'getFlow': '/order/income-flow/query',
   // 获取提现记录
   'getWithdrawal': '/order/extract-apply/getPage',
   // 更新提现状态
   'updateExtractStatus': '/order/extract-apply/update',
   //获取自提点列表
   'getadresslist':'/order/fixPoint/getPage',
   //保存自提点
   'addadressdata':'/order/fixPoint/save',
   //设置小程序主题
   'edithoteltheme':'/hotel/edit',
   //获取主题列表信息
   'gethotelthemeinfo':'/hotel/getHotel',

   
   /* 大数据管理 */
   // 获取使用数据
   'useStatistics': '/hadoop/deviceUseStatistics',
   // 获取商城销售统计
   'saleInfo': '/hadoop/getSaleInfo',
   // 获取转化率统计
   'conversion': '/hadoop/conversionRateStatistics',
   // 获取数据概况
   'dataSurvey': '/hadoop/deviceStatistics',
   // 获取入住人手机号
   'checkinPersonMobiles': '/hadoop/getCheckinPersonPage',
   // 获取个人画像数据
   'personalPortrait': '/hadoop/getPersona',
   // 获取组合画像数据
   'combinationPortrait': '/hadoop/getCombination',
   // 获取系统评分
   'systemScore': '/hadoop/getSystemScore',

   // 计划任务
   // 新增计划任务
   'scheduledtask':'/hotelkeeper/workplan/save',
   // 分页查询计划任务列表
   'planTaskPaging':'/hotelkeeper/workplan/getPages',
   
   //查询分页计划工单
   'Querytherepairorder':'/hotelkeeper/workplan/getPages',
   // 查询是否已创建
   'workPlan':'/hotelkeeper/workplan/getList',
   //任务类型
   'taskType':'/system/dict/getList',
   
   /* 数据统计 */
   //根据年月日统计工单信息
   'Datestatistics':'/hotelkeeper/workorderinfo/statisticsWorkOrderByDate',
   //员工接单排行榜
   'rankingList':'/hotelkeeper/workorderinfo/statisticsWorkOrderByReceiveId',
   //客房服务雷达图
   'radarmap':'/hotelkeeper/workorderinfo/statisticsWorkOrderByWorkType',
   //按年月日统计工单信息
   'Timetodate':'/hotelkeeper/workorderinfo/statisticsByDate',

   /* 营销中台 */
   //营销模块里的微信群发助手查询接口
   'marketingRuery':'/event/eventMessage/getPage',
   //微信群发助手创建接口
   //新增web弹窗
   'createAmass':'/event/eventMessage/create',
   // 查询元数据列表
   'metadataList':'/event/metadata/getList',
   //删除
   'deletemessage':'/event/eventMessage/delete',
   //上传图片 语音 视频
   'uploads':'/event/material/upload',
   //图片下拉菜单 语音 视频
   'getFileList':'/event/material/getFileList',
   //图文
   'addNews':'/event/material/addNews',
   //统计不同状态下的数量
   'statisticalMagnitude':'/event/eventMessage/statisticalMagnitude',
   // 运营活动状态
   'activeState':'/event/eventMessage/updateState',
   // 开启/暂停活动
   'activeStart':'/event/eventMessage/updateStart',
   //微信群发修改
   'massChanges':'/event/eventMessage/update',
   //模板查询
   'templateList':'/event/template/getPage',
   //创建模板
   'createAmessage':'/event/template/create',
   //活动分析
   'activityAnalysis':'/event/eventMessage/convertStatistics',
   //短信活动修改
   'Modify':'/event/template/update',
   //统计近一周活动销售情况
   'salesStatistics':'/event/eventMessage/salesStatistics',
   //商城购买转化率
   'getOrderConvert':'/hadoop/event/getOrderConvert',
   //商城总销售
   'salesVolumeStatistics':'/hadoop/event/salesVolumeStatistics',
   //商城热销是商品
   'hotSaleStatistics':'/hadoop/event/hotSaleStatistics',
   //短信模板删除
   'removeTemplates':'/event/template/remove',
   //获取老客户销售统计
   'getRegularCustomerOrderStatistics':'/hadoop/event/getRegularCustomerOrderStatistics',
   //统计用户分布
   'Userdistribution':'/hadoop/event/getSourceStatistics',
   //日期内活动销售情况
   'salesStatisticsas':'/hadoop/event/salesStatistics',
   // 参与各类促销活动用户分布
   'salesPromotionUser':'/hadoop/event/salesPromotionUser',
   //查询配置公众号
   'EnquiryPublicAccount':'/hotel/hotel-cfg/getList',
   //查询配置公众号分页
   'EnquiryPublicAccountpage':'/hotel/hotel-cfg/getPage',
   // 保存企业微信配置
   saveWechatEnterCfg: '/hotel/saveCfg',
   
   // //绑定appid
   // 'saveHotelCfg':'/hotel/hotel-cfg/saveHotelCfg',
   //获取小程序配置
   'getAppletsconfing':'/event/applet/generateAppletPageQrcode',
   //获取小程序配置文件中指定的页面
   'getAppletPages':'/event/applet/getAppletPages',
   //修改抖音配置
   'saveWechatHotelCfg':'/hotel/hotel-cfg/saveHotelCfg',
   //人均访问时长
   'getAccessTime':'/hadoop/event/getAccessTime',
   //次日留存率
   'getRetentionRate':'/hadoop/event/getRetentionRate',
   //二次转化营销
   'getSecondRate':'/hadoop/event/getSecondRate',
   //各门店复购数
   'repurchasesStatistics':'/hadoop/event/repurchasesStatistics',
   //查询抖音配置
   'trillQuery':'/system/douyin/scope/getList',
   //邮件创建模板
   'templateEmail':'/event/templateEmail/create',
   //邮件模板列表
   'mailingList':'/event/templateEmail/getPage',
   //修改邮件模板
   


   
   /* 会员勋章 */
   // 保存会员勋章
   'saveMemberMedal': '/member/medal/save',
   // 删除会员勋章
   'delMemberMedal': '/member/medal/delete',
   // 绑定会员权益
   'medalBindRights': '/member/medalRights/save',
   // 解除会员权益
   'medalUnBindRights': '/member/medalRights/remove',
   //会员积分明细
   'getintegrallist':'/member/integral/getPage',
   
   /* 成长值 */
   // 保存配置
   'saveGrowConfig': '/member/saveGrowConfig',
   // 获取成长值列表
   'growConfList': '/member/getGrowConfigList',
   
   'growthList': '/member/grow/getPage',

   /* 酒店会员画像 */
   // 会员各省分布
   'memberByProvince': '/hadoop/getStatisticsMemberByProvince',
   // 会员各省消费分布
   'memberByConsume': '/hadoop/getStatisticsMemberByConsume',
   // 会员来源渠道
   'memberChannel': '/hadoop/getStatisticsMemberChannel',
   // 会员所在行业
   'memberIndustry': '/hadoop/getStatisticsMemberIndustry',

   /* 活跃度分析 */
   // 统计活跃用户
   'activeUser': '/hadoop/analyseActiveUser',
   // 单次访问时长
   'visitDuration': '/hadoop/getActiveUserVisitDuration',
   // 人均访问次数
   'userVisitMum': '/hadoop/getActiveUserVisitNumber',
   
   // 删除上传图片
   'deleteUpload':'/system/attachment/delete',

   /* 业务中台 */

   /* PMS */
   //新增房型
   'addroomtype':'/pms/roomtype/add',
   //选择排房
   "selectRowHouse":'/pms/orderRowhouse/rowHouse',

   //修改通知配置
   'updateeventNotify':'/event/eventNotify/update',
   //修改消息接收人
   'setReceiver':'/event/eventNotifyUser/setReceiver',
   //获取通知信息
   "getEventNotify":"/event/eventNotify/getPage",
   //
   "updateRuleIntegral":"/hotel/companyHotelCfg/set",

   /*数据中台*/
   /*会员权益*/
   // 获取积分规则比例
   'getRuleIntegral':'/hotel/companyHotelCfg/get',
   /*会员裂变*/
   // 裂变明细
   "getMenFission":"/member/memberfission/getPages",
   // 裂变设置编辑
   "MenFissionEdit":"/member/medal/edit",
   // 裂变设置清空
   "resetMedal":"/member/medal/resetMedal",
   // 获取会员权益
   'memberRights': '/member/rights/getPage',
   // 保存会员权益
   'saveMemberRights': '/member/rights/save',
   // 删除会员权益
   'delMemberRights': '/member/rights/delete',
   // 会员列表 分页
   'membersPaging': '/member/getPage',
   // 会员列表
   'members': '/member/getList',
   //获取新媒体信息
   'getPlatformListinfo':'/member/memberHotelPlatform/getPlatformList',
   //营销跟踪
   'getCouponListByUserId':'/event/couponMyself/getCouponListByUserId',
   //消费跟踪
   "getconsumeList":"/order/getOrderList",
   //充值记录
   'getdepositCardPay':'member/depositCardPay/getPage',
   //行为跟踪
   'memberAction':'/member/memberAction/getPage',
   //有openid的会员列表
   'getPlatformList':'/member/memberHotelPlatform/getPlatformList',
   // 秘钥配置
   'hotelSecret': '/hotel/hotelSecret/getHotelSecret',
   // 保存秘钥
   'saveSecret': '/hotel/hotelSecret/addHotelSecret',
   //秘钥绑定 酒店
   'bindPaasHotel':'/hotel/bindPaasHotel',
   //解绑 酒店
   'unBindPaasHotel':'/hotel/unBindPaasHotel',
   // 添加会员
   'addMember': '/member/add',
   // 编辑会员
   'editMember': '/member/update',
   // 删除会员
   'delMember': '/member/delete',
   // 获取会员等级列表
   'memberLevels': '/member/medal/getList',
   // 同步抖音粉丝
   'syncDouYin': '/event/douyin/platform/syncDouYinFansToMember',
   // 请求验证码
   'reqVerCode': '/oauth2/mobile-code',
   // 验证验证码
   'codeVerify': '/account/codeVerify',

   /*数据概况*/
   //获取用户概况
   "getUserTotal":'/member/memberTotal',
   //获取会员概况
   "getMemberTotal":"/member/memberHotel/memberTotal",
   
   

   // 获取小程序体验码
   getExperienceQrcode: '/oauth2/wechat/thirdparty/getExperienceQrcode',
   // 加急审核
   speedupAudit: '/oauth2/wechat/thirdparty/speedupAudit',
   // 查询加急次数
   queryQuota: '/oauth2/wechat/thirdparty/queryQuota',
   // 提交审核
   submitAudit: '/oauth2/wechat/thirdparty/submitAudit',
   // 更新审核状态
   updateVersion: '/hotel/hotel-cfg/updateVersion',
   // 撤回审核
   recallAudit: '/oauth2/wechat/thirdparty/recallAudit',
   // 回退版本
   rollbackRelease: '/oauth2/wechat/thirdparty/rollbackRelease',
   // 上传审核素材
   uploadMediaAudit: '/oauth2/wechat/thirdparty/uploadMediaAudit',
   // 初始化域名
   setAppletDomain: '/oauth2/wechat/thirdparty/setAppletDomain',
   // 发布小程序
   updateReleaseTime: '/oauth2/wechat/thirdparty/release',
   // 更新酒店第三方平台应用版本发布时间
   hotelupdateReleaseTime: '/hotel/hotel-cfg/updateReleaseTime',
   // 上传代码
   commitCode: '/oauth2/wechat/thirdparty/commitCode',
   // 获取小程序最新模板
   getTemplateLast: '/oauth2/wechat/thirdparty/getTemplateDraftList',
   // 获取模板id
   getminiTemplateList: '/oauth2/wechat/thirdparty/getTemplateList',
   // 将草稿添加到模板库
   addTemplate: '/oauth2/wechat/thirdparty/addTemplate',
   // 小程序插件配置
   appletPlugin: '/oauth2/wechat/thirdparty/appletPlugin',
   // 删除指定模板
   deleteTemplate: '/oauth2/wechat/thirdparty/deleteTemplate',
   // 储值卡余额
   getMemberBalance:"/member/depositCardPay/getMemberBalance",
   
   authURL: '/oauth2/wechat/enterprise/getAuthURL',
   permanentCode: 'oauth2/wechat/enterprise/getPermanentCode',


   /* 创作中心 */
   // 创建素材
   createMaterial: '/event/writingCenter/create',
   // 编辑素材
   editMaterial: '/event/writingCenter/update',
   // 获取素材列表
   materialPage: '/event/writingCenter/getPage',
   // 删除素材
   delMaterial: '/event/writingCenter/delete',
   // 上传图片
   uploadMaterialImg: '/event/material/uploadImg',
   
   /* 仪表盘 */
   // 酒店客房复购率
   rePurchaseRate: '/hadoop/getStatisticsRePurchaseRate',
   // 统计周活跃老客户
   weekActiveUser: '/hadoop/getStatisticsWeekActiveUser',
   // 统计周沉睡客户唤醒数
   weekSleepUser: '/hadoop/getStatisticsWeekSleepUser',

   /* 订单管理 */
   // 获取订单列表
   getOrder: '/order/getPage',
   // 获取订单列表
   getOrderList: '/order/getList',
   // 获取酒店列表
   getHotels: '/hotel/selectHotelList',
   // 获取酒店列表
   getHotelslist: '/hotel/getList ',
   // 获取公司列表
   getAllCompany: '/system/company/getAllCompany',
   // 修改订单状态
   updateOrderStatus: '/order/updateOrderStatus',
   // 取消酒店预订单
   cancelHotelOrder: '/order/hotel/cancelOrder',
   // 完成酒店订单
   finishHotelOrder: '/order/profitsharing',
   // 导出订单数据
   orderHotelsExcel: '/order/hotel/getOrderHotelsExcel',

   /* 标签群组 */
   // 获取群组列表
   tagGroup: '/tagGroupMember/getPage',
   // 添加群组
   addTagGroup: '/member/addTagGroup',
   // 编辑群组
   editTagGroup: '/member/editTagGroup',
   // 删除标签群组
   delTagGroup: '/member/deleteTagGroup',
   // 预览群组
   previewTagGroup: '/member/previewTagGroup',
   // 获取个人画像
   memberStatistics: '/hadoop/getMemberStatisticsPage',
   // 获取群组会员
   shiftAddMemberIdList: '/member/tagGroupMember/getShiftAddMemberIdList',
   // 推送相关数据列表
   eventWechat:'/event/eventWechat/getList',
   // 个人标签
   // 获取个人标签列表
   membersTags: '/member/detail/getPage',
   // 会员绑定标签
   memberBindTags: '/member/memberTag/save',
   // 会员解绑标签
   memberUnbindTag: '/member/memberTag/remove',
   // 获取标签库列表
   tagLibrary: '/member/tagLibrary/select',
   // 添加/编辑标签库
   saveTagLibrary: '/member/tagLibrary/save',
   // 删除标签库
   delTagLibrary: '/member/tagLibrary/delete',
   // 保存自动化标签
   saveAutoTag: '/member/tagLibrary/save',

   // 获取秒杀预售券列表
   seckillCouponPage: '/goods/seckillProduct/getTicketPage',
   // 新增秒杀商品
   addSeckillProduct: '/goods/seckillProduct/save',
   // 修改秒杀商品
   editSeckillProduct: '/goods/seckillProduct/update',
   // 删除秒杀商品
   delSeckillProduct: '/goods/seckillProduct/delete',
   // 获取秒杀时间段列表
   seckillTimePage: '/goods/seckillTime/getPage',
   // 获取秒杀时间段列表
   seckillTime: '/goods/seckillTime/getList',
   // 新增秒杀时段
   addSeckillTime: '/goods/seckillTime/save',
   // 编辑秒杀时段
   editSeckillTime: '/goods/seckillTime/update',
   // 删除秒杀时段
   delSeckillTime: '/goods/seckillTime/delete',
   /* 数据看板 */
   // 地图数据
   hotelLocation: '/hadoop/hotelData/queryHotelLocation',
   // 查询销售统计
   mapSalesCount: '/hadoop/order/getSale',
   // 新增用户统计
   mapMemberCount: '/hadoop/hotelData/queryHotelAndMember',
   // 酒店总数
   hotelCont: '/hadoop/hotelData/queryHotelCountYear',
   // 会员总数
   memberCount: '/hadoop/member/queryMemberCountYear',
   // 销售数
   salesYear: '/hadoop/order/querySalesYear',
}

/* 业务中台 */
export const business = {
   /* 预售券 */
   // 获取预售券列表
   preSalePage: '/event/couponPresell/getPage',
   // 获取预售券列表
   preSaleList: '/event/couponPresell/getList',
   // 保存预售券
   savePreSale: '/event/couponPresell/save',
   //删除预售券
   deletecouponPresell: '/event/couponPresell/delete/',
   // 下载海报
   downloadBatch: '/event/couponPresell/downloadBatch',
   //预售券审核
   examinecouponPresell: '/event/couponPresell/updateStatus',
   // 海报查询
   posterPage: '/event/couponPresell/getDistributorPage',
   // 导出预售券统计报表
   exportCouponMyselfList: "/event/couponPresell/exportCouponMyselfList",
   // 导出预售券汇总报表
   getCouponPresellSalesExcel: "/event/couponPresell/getCouponPresellSalesExcel",
   // 获取预售券汇总报表
   getCouponPresellSalesInfo: "/event/couponPresell/getCouponPresellSalesInfo",
   // 获取整体概况 预售券数量
   getCouponPresellSellDetail: "/event/couponPresell/getCouponPresellSellDetail",
   // 获取整体概况 优惠券数量
   couponStatistics: "/event/couponmember/couponStatistics",
   // 分销海报统计数
   statisticsCouponPresell: '/event/couponPresell/getStatisticsCouponPresell',
   // 获取预售券购买列表
   couponMyselfPage: '/event/couponPresell/getCouponMyselfPage',
   // 预售劵购买列表核销接口
   useCouponMyself: '/event/couponMyself/useCouponMyself',

   /* 分销商 */
   // 添加分销商
   saveDistributor: '/event/distributor/save',
   // 获取分销商列表
   distributorPage: '/event/distributor/getPage',
   // 获取分销商列表
   distributor: '/event/distributor/getList',
   // '渠道分配'
   bindChannel: '/event/couponPresell/bind',
   // 已分配
   getBindList: '/event/distributor/getBindList',
   // 删除分销商
   delDistributor: '/event/distributor/delete',
   // 删除已分配渠道
   deleteChannel: '/event/couponPresell/unbind',
   // 渠道商业绩列表
   distributorSalesList: '/event/distributor/getDistributorSalesList',
   // 渠道商业绩详情
   couponPresell: '/event/couponPresell/getCoupon',

}
